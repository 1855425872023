@mixin full_field-wrapper__title {
  background: linear-gradient(to top, white 0%, white 51%, transparent 51%, transparent 100%);
  font-size: 1.2rem;
  max-width: fit-content;
  padding: 0 0.4rem;
  top: -0.7rem;
}

@mixin field-wrapper__title {
  color: rgba(85, 85, 85, 0.8);
  position: absolute;
  pointer-events: none;
  left: 1.6rem;
  margin: 0 !important;
  line-height: 1;
}

.express-tab-content {
  .sameDayDelivery {
    padding-left: 3.2rem;
    font-size: 1rem;
    color: $emperor;
    width: 80%;
    margin-top: 0.8rem;
  }

  .additionalText {
    font-size: 1.2rem;
    padding-left: 1.6rem;
    line-height: 1.5;
    color: $boulder;
  }

  .common__icon--arrow-find {
    background-size: auto;
    width: 2.13rem;
    height: 2.13rem;
    z-index: 1;
  }

  .input-wrapper {
    background: $white;
    border: 0.1rem solid #dadada;
    border-radius: 0.5rem;
    position: relative;
    width: 100%;

    .field-wrapper {
      &__title {
        background-color: white;
        font-size: 1.4rem;
        padding: 0;
        right: 1.6rem;
        top: 1.2rem;
        transition: all 0.2s ease;
        @include field-wrapper__title;

        &.error {
          color: $red-berry-2;
        }

        &.full {
          @include full_field-wrapper__title;
        }
      }
    }

    #expressSearchBox {
      background: #fff;
      border: none;
      box-sizing: content-box;
      color: rgba(85, 85, 85, 0.8);
      font-size: 1.4rem;
      height: 4.8rem;
      margin-left: 1rem;
      max-width: 25ch;
      overflow: hidden;
      padding: 0 3.5rem 0 1.6rem !important;
      text-overflow: ellipsis;

      &:focus {
        outline: none;

        ~ .field-wrapper__title {
          @include full_field-wrapper__title;

          @screen mdr {
            background-color: $white;
          }
        }
      }
    }

    .expressSearchBox.active {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .avaliable-address {
    position: absolute;
  }
  .input-wrapper.validation-error {
    border-color: $red-berry;
  }

  .deliv-hours {
    .field-wrapper__title.full {
      @include field-wrapper__title;
      @include full_field-wrapper__title;
    }
  }

  .deliv-hours-wrapper {
    margin-top: 2rem;
  }

  .option-description {
    font-size: 1.2rem;
    margin: 0.4rem 0 0 1.6rem !important;
  }
}
