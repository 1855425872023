.error-template {
  padding: 99px 0 178px;

  .title--error {
    font-size: 2.6rem;
    line-height: normal;
    font-weight: 100;
    text-align: center;
    padding-bottom: 4px;
  }

  .text_regular--error {
    font-size: 1.2rem;
    line-height: normal;
    text-align: center;
    padding-bottom: 27px;
  }

  .wrapper-btns {
    display: flex;
    justify-content: center;

    .btn {
      margin: 0 10px;
    }
  }
  @media only screen and (max-width: 768px) {
    .wrapper-btns {
      flex-direction: column;
      align-items: center;

      .btn {
        margin: 10px;
      }
    }
  }
}
