@import '../mixins/mixins';

.SGH_WO_US {
  #account-modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
    background-color: $concrete;
  }

  #account-modal {
    position: absolute;
    top: 43px;
    left: 50%;
    width: 746px;
    margin: 0 0 0 -373px;
    padding: 7px;
    z-index: 10002;

    input[type='text'],
    input[type='password'] {
      width: 371px;
      height: 26px;
      color: $mine-shaft-2;
      background-color: $white;
      text-transform: uppercase;
      text-align: left;
    }

    .logo {
      margin: 100px 0;
      text-align: center;
    }

    .select-wrapper {
      select {
        padding-left: 10px;
      }
    }

    .required {
      text-align: left;
    }

    h1 {
      @include primaryFont();
      font-size: 2rem;
      font-weight: bold;
      margin: 0 0 45px;
    }

    h3 {
      @include primaryFont();
      font-size: 1.4rem;
      font-weight: bold;
      margin: 40px 0 20px;
      color: $mine-shaft-2;
    }

    a:not(.red-button):not(.forgot) {
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    .forgot {
      @include primaryFont();
      font-size: 1.4rem;
      font-style: italic;
      display: block;
      margin: 0 0 30px;
      color: $dusty-gray-4;
      clear: both;
    }

    .registration {
      width: 390px;
      margin: 0 auto;
      text-align: center;

      .custom-check {
        text-align: left;
      }
    }

    .is-signin {
      .column {
        float: left;
        width: 372px;
        border-right: 1px solid #d7d7d7;
        min-height: 350px;

        &.second {
          width: 272px;
          padding: 0 0 0 100px;
          border: none;
        }

        h3 {
          margin-top: 0;
        }

        .espot {
          margin: 0 0 52px;
        }

        input[type='text'],
        input[type='password'] {
          width: 247px;
        }

        p {
          margin: 0 0 30px;
          font: {
            size: 11px;
          }
        }

        label {
          color: $mine-shaft-2;
          font: {
            size: 13px;
          }
        }
      }
    }
  }

  #footer {
    #columns {
      .column {
        width: 25%;
      }
    }
  }
}
