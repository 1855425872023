/* stylelint-disable */

.sgh-checkout {
  &__prescription {
    &--messages {
      font-size: 1.2rem;
      padding-bottom: 10px;
      @screen xl {
        padding-bottom: 0;
      }

      &-advise {
        display: none;
        margin: 8px 0 0;
        color: #555;

        &-italic {
          font-style: italic;
        }

        &-show {
          display: block;
        }
      }

      &-alert {
        display: none;
        margin: 7px 0 12px;
        color: #900;

        &-show {
          display: block;
        }
      }
    }

    &--upload-section {
      border-bottom: solid 1px #dadada;

      &-dialog {
        cursor: pointer;
        align-content: center;
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 17px 11px;
        @screen xl {
          padding: 15px 11px;
        }

        &:after {
          content: '\e901';
          font: 800 16px 'sgh';
          margin-top: 3px;
        }

        &-active {
          &:after {
            content: '\e907';
          }
        }
      }

      &.alreadyUpload {
        .uploaded {
          display: block;
        }

        .notUploaded {
          display: none;
        }
      }

      .uploaded {
        display: none;
      }

      .notUploaded {
        display: block;
      }
    }
  }
}

.order-display-bottom {
  .sgh-checkout__prescription--messages,
  .sgh-checkout__prescription--upload-section {
    display: none;
  }
}

.sgh-prescription {
  &-input {
    display: none;
    margin-top: 14px;

    &-change {
      display: none;
      justify-content: space-between;
      align-items: center;
      padding: 11px 10px 10px;
      border: 1px solid #e5e5e5;

      &-button {
        color: #555;
        text-decoration: underline;
      }

      &-error {
        &-message {
          display: none;
          color: #900;
          font-size: 1rem;
        }
      }

      &-filename {
        color: #222;
        font-size: 1.2rem;
      }

      p {
        margin: 0;
      }
    }

    &-picker {
      position: relative;
      cursor: pointer;
      margin: 7px 5px 10px 10px;
      padding: 10px 103px 9px 104px;
      border-radius: 17.5px;
      border: solid 1px #4a4a4a;

      .sgh-icon-uploadPrescription {
        position: absolute;
        left: 85px;
        width: 14px;
        height: 14px;
        background: url('../assets/images/icons/upload-prescription.svg');
        background-repeat: no-repeat;
      }
    }

    &-show {
      display: block;
      text-align: center;
    }

    &-success {
      display: none;
      color: #555;
      font-size: 1rem;
    }

    &-text {
      margin: 10px 4px 16px 11px;
      color: #555;
      font-size: 1rem;
      text-align: center;
    }
  }
}

.sgh-typ {
  padding: 1.5rem 0 3rem;

  .sgh-prescription {
    margin-top: 18px;

    &--button {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      margin-top: 18px;
    }

    &-input {
      display: flex;
      flex-wrap: wrap;
      max-width: 40%;

      &-wrapper {
        position: relative;
        display: flex;
        align-items: center;
      }

      &-picker {
        min-width: auto;
        height: 24px;
        margin: 0 6px 0 0;
        padding: 4px 20px 4px 40px;
        border-radius: 12px;
        text-align: center;
        font-size: 1.2rem;
        font-weight: normal;
      }

      &-text {
        width: 100px;
        height: 18px;
        margin: 0;
        margin-left: 6px;
        font-size: 0.8rem;
        text-align: left;
      }

      &-change {
        min-width: auto;
        margin: 0 1.5rem 1.5rem 0;

        &-filename {
          margin-right: 1.5rem;
        }
      }

      &-success {
        width: 100%;
      }
      @screen lgr {
        max-width: 100%;
      }
    }
  }

  .sgh-icon-uploadPrescription {
    position: absolute;
    top: 5px;
    left: 20px;
    width: 14px;
    height: 14px;
    background: url('../assets/images/icons/upload-prescription.svg');
    background-repeat: no-repeat;
  }
}

.thanks {
  .sgh-checkout__prescription--upload-section {
    border-bottom: 1px solid $silver;
    font-weight: 500;
    text-transform: none;
  }
}
