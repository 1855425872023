@import 'mixins';

.cms-banner-vertical-board {
  @include banner-picture;

  .picture {
    padding-top: calc(466 / 466) * 100%;
  }

  .swiper-wrapper {
    flex-direction: column;
  }

  .swiper-slide {
    flex-basis: 100%;
    height: auto;
  }

  .swiper-pagination {
    display: none;
  }

  .cms-text-module__text {
    font-size: theme('fontSize.base');

    @screen sm {
      font-size: theme('fontSize.base');
    }
  }

  @screen sm {
    .banners {
      justify-content: space-between;
    }

    .banner-slide-container {
      height: 100%;
      max-width: calc((1 / 3 * 100%) - 1.5rem);
    }
  }
}

.cms-banner-vertical-board--has-carousel {
  .swiper-wrapper {
    flex-direction: row;
  }

  .swiper-pagination {
    display: block;
  }
}
