sgh-category-module {
  height: 42.5rem;
  display: block;

  @screen lgr {
    height: 63.5rem;
  }
  &[text-align='left'] {
    @screen lgr {
      .skeleton {
        align-items: flex-start;
        ul {
          align-items: flex-start;
          justify-content: flex-start;
          margin-bottom: 3.2rem;
        }
      }
    }
  }
  .skeleton {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding: 0 4rem;
    @screen lgr {
      flex-direction: column;
      padding: 4rem 1.6rem;
      justify-content: flex-start;
    }

    &-images {
      display: flex;
      gap: 0.8rem;
      @screen lgr {
        row-gap: 1.6rem;
        column-gap: 0.9rem;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
    &-images-carousel {
      display: flex;
      gap: 0.8rem;
      @screen lgr {
        row-gap: 1.6rem;
        column-gap: 0.9rem;
        justify-content: center;
        flex-direction: row;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        margin: 0.8rem 0;
        height: 1.6rem;
        @include skeleton-animation;

        &.pre-title {
          width: 25rem;
          height: 1.3rem;
          @screen lgr {
            width: 15rem;
          }
        }
        &.title {
          width: 28rem;
          @screen lgr {
            width: 18rem;
          }
        }
        &.text {
          width: 38rem;
          @screen lgr {
            width: 28rem;
          }
        }
        &.cta {
          width: 10rem;
          @screen lgr {
            width: 5rem;
          }
        }
      }
    }
    .category-item {
      .image {
        width: 24.29rem;
        height: 24.22rem;
        background-color: $alabaster;
        overflow: hidden;
        @include skeleton-animation;
        @screen lgr {
          width: 16.7rem;
          height: 16.7rem;
        }
      }

      .cta {
        margin: 1.2rem auto 0;
        height: 1.5rem;
        width: 10rem;
        @include skeleton-animation;
      }
    }
  }
}
