@import 'mixins';

.cms-fw-banner,
.cms-landscape-banner {
  @include banner-all-medias;
  position: relative;

  &--s .picture,
  &--s .video {
    padding-top: calc(236 / 375) * 100%;
  }

  &--m .picture,
  &--m .video {
    padding-top: calc(420 / 375) * 100%;
  }

  &--l .picture,
  &--l .video {
    padding-top: calc(480 / 375) * 100%;
  }

  .cms-text-module {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.cms-fw-banner {
  width: 100%;
  margin: 0 auto;

  &--top-left .cms-text-module {
    top: 0;
    left: 0;
    transform: none;
  }

  &--top-center .cms-text-module {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &--top-right .cms-text-module {
    left: auto;
    top: 0;
    right: 0;
    transform: none;
  }

  &--center-left .cms-text-module {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &--center-center .cms-text-module {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &--center-right .cms-text-module {
    right: 0;
    top: 50%;
    left: auto;
    transform: translateY(-50%);
  }

  &--bottom-left .cms-text-module {
    left: 0;
    bottom: 0;
    top: auto;
    transform: none;
  }

  &--bottom-center .cms-text-module {
    left: 50%;
    bottom: 0;
    top: auto;
    transform: translateX(-50%);
  }

  &--bottom-right .cms-text-module {
    right: 0;
    left: auto;
    bottom: 0;
    top: auto;
    transform: none;
  }

  .cms-text-module {
    padding-bottom: 2.4rem;

    @media (min-width: 769px) {
      padding-bottom: 4rem;
    }

    &__cta.btn.cta-short {
      @media (min-width: 769px) {
        margin: 0 1.5rem;
        height: 4rem;
        line-height: 2rem;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  &--s {
    .cms-text-module {
      padding-bottom: 2.4rem;
    }
  }
}

.cms-landscape-banner {
  max-width: 1155px;
  margin: 0 1.5rem;
  box-sizing: content-box;

  .picture,
  .video {
    padding-top: calc(527 / 750) * 100%;
  }
}

@media (min-width: 769px) {
  .cms-landscape-banner {
    padding: 0 30px;
    margin: 0 auto;

    .picture,
    .video {
      padding-top: calc(404 / 1155) * 100%;
    }
  }

  .cms-fw-banner {
    &--s .picture,
    &--s .video {
      aspect-ratio: 9/2;
      padding-top: initial;
    }

    &--m .picture,
    &--m .video {
      aspect-ratio: 72/23;
      padding-top: initial;
    }

    &--l .picture,
    &--l .video {
      aspect-ratio: 144/59;
      padding-top: initial;
    }
  }
}
