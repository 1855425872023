/* stylelint-disable */

@import '../mixins/mixins';
#logon-form {
  span.required {
    display: inline-block;
    font-size: 1rem !important;
    text-align: left;
  }
  span#challengeQuestionError {
    display: inline;
  }
  .centerMessage {
    span.required {
      display: block;
      width: 100%;
      text-align: center;
      clear: both;
    }
    .forgotPassStepTextBig {
      display: block;
      width: 367px;
      margin: 0 auto 10px;
      font-size: 1.3rem;
      text-align: left;
    }
  }
  p.logoff {
    font-size: 1rem;
  }
  .redRequired {
    color: $alizarin-crimson;
  }
  overflow: hidden;
  background: $concrete;
  text-align: center;
  .second {
    display: none;
  }
  #logonErrorMessage,
  .logonErrorMessage {
    margin: 0 auto 20px;
    clear: both;
  }
  &.logout {
    .first {
      display: none;
    }
    .second {
      display: block;
    }
  }
  h2 {
    @include primaryFont();
    font-style: normal;
    font-weight: 400;
    font-size: 3.6rem;
    display: block;
    padding: 66px 0 33px;
  }
  h3 {
    @include primaryFont();
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    margin: 0 0 20px;
    &.no-margin {
      margin: 0 0 5px;
    }
  }
  h3.wellcomeMessage {
    width: 423px;
    margin: 0 auto 25px;
    line-height: 3rem;
    text-transform: none;
    @include primaryFont();
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
  }
  p {
    margin: 0 0 20px;
    font-size: 1.4rem;
  }
  p.forgotPassStepText {
    font-size: 1.1rem;
    line-height: 2rem;
  }
  #challengeQuestion {
    width: 367px;
    margin: 0 auto;
    font-size: 1.3rem;
    text-align: left;
    p {
      margin: 0 0 10px;
      font-size: 1.3rem;
      line-height: 2rem;
    }
  }
  .green-button {
    padding: 12px 30px;
  }
  input[type='text'],
  input[type='password'] {
    width: 317px;
    padding: 20px 25px;
    color: $mine-shaft-2;
    background-color: $white;
    &::-webkit-input-placeholder {
      color: $mine-shaft-2;
      text-transform: uppercase;
    }
    &:-moz-placeholder {
      color: $mine-shaft-2;
      text-transform: uppercase;
    }
    &::-moz-placeholder {
      color: $mine-shaft-2;
      text-transform: uppercase;
    }
    &:-ms-input-placeholder {
      color: $mine-shaft-2;
      text-transform: uppercase;
    }
  }
  .forgot {
    color: $dusty-gray-4;
    font: {
      style: italic;
    }
  }
  .red-button {
    margin: 0 0 30px;
  }
  .requiredMessage {
    font-size: 1rem;
  }
  #create-wrapper {
    padding: 0;
    background: url('../assets/images/my-account/SingUpBanner.jpg') center top no-repeat;
    text-align: left;
    input[type='text'],
    input[type='password'] {
      width: 296px;
      padding: 15px 25px;
    }
    .w369 {
      width: 346px;
      margin-bottom: 30px;
    }
    .middleCont {
      position: relative;
      width: 720px;
      margin: 0 auto;
    }
    h2 {
      font-size: 3.2rem;
      padding-bottom: 20px;
    }
    ul {
      margin: 0 0 30px 12px;
      line-height: 1.8rem;
      text-transform: none;
      font: {
        size: 11px;
      }
    }
    label {
      display: block;
      width: 450px;
      margin: 0 0 5px;
      font: {
        size: 13px;
      }
    }
    #div_emailOptin label {
      width: 661px;
    }
    .select-wrapper {
      &.date {
        width: 123px;
        &.required {
          border: 1px solid $alizarin-crimson !important;
        }
      }
    }
    .note {
      position: absolute;
      top: 73px;
      right: 0;
      font-size: 1rem;
      a {
        text-decoration: underline;
      }
    }
    .requiredMessage {
      margin: 39px 0 0;
    }
    #WC_PersonalInfoExtension_DOB_div_6,
    #WC_PersonalInfoExtension_DOB_div_5,
    #WC_PersonalInfoExtension_DOB_div_4 {
      float: left;
      width: 108px;
      margin-right: 10px;
      select {
        width: 124%;
        background-position: 67% 50%;
      }
    }
  }
  .MyAccountBanner {
    background: url('../assets/images/my-account/MyAccountBanner.jpg') center top no-repeat;
    min-height: 190px;
    background-size: cover;
    h2 {
      padding: 40px 0 15px;
      color: $white;
    }
    p {
      width: 480px;
      margin: 0 auto 5px;
      color: $white;
      font-size: 1.5rem;
      line-height: 3rem;
      text-transform: none;
    }
    a {
      color: $white;
      line-height: 3rem;
      text-decoration: underline;
      @include primaryFont();
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
    }
  }
  .middleCont {
    width: 745px;
    margin: 0 auto;
  }
  .halfWidthBox {
    overflow: hidden;
    width: 500px;
    margin: 0 auto;
    padding: 30px 0;
    border-top: 1px solid #e1e1e1;
  }
  .w369 {
    width: 369px;
    text-align: left;
  }
  .wellcomeSection {
    overflow: hidden;
    height: 530px;
    background: url('../assets/images/my-account/wellcomeBG.jpg') center top no-repeat;
  }
  .redUnderLine {
    color: $alizarin-crimson;
    text-decoration: underline;
    @include primaryFont();
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
  }
}

#change-password {
  text-align: center;
  padding-bottom: 50px;
  background: $concrete;
  .w369 {
    float: none;
    width: 369px;
    margin: 0 auto;
    text-align: left;
  }
  .red-button {
    margin-top: 10px;
    padding: 12px 39px;
  }
  label {
    @include primaryFont();
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    span {
      font-size: 1.1rem;
    }
  }
  h2 {
    @include primaryFont();
    font-style: normal;
    font-weight: 400;
    font-size: 3.6rem;
    display: block;
    padding: 55px 0 35px;
  }
  input[type='text'],
  input[type='password'] {
    width: 319px;
    margin: 10px 0 20px;
    padding: 20px 25px;
    color: $mine-shaft-2;
    background-color: $white;
    &::-webkit-input-placeholder {
      color: $dark-gray;
      text-transform: uppercase;
    }
    &:-moz-placeholder {
      color: $dark-gray;
      text-transform: uppercase;
    }
    &::-moz-placeholder {
      color: $dark-gray;
      text-transform: uppercase;
    }
    &:-ms-input-placeholder {
      color: $dark-gray;
      text-transform: uppercase;
    }
  }
}

#WC_UserRegistrationAddForm_div_54 {
  + span.required {
    position: relative;
    top: -20px;
  }
}

#forgot-password,
#password_reset_success {
  text-align: center;
  padding-bottom: 50px;
}

.fleft {
  float: left;
  clear: none;
  width: auto;
}

.fright {
  float: right;
  clear: none;
  width: auto;
}

.clear {
  clear: both;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
  opacity: 0;
}

input:focus:-moz-placeholder {
  color: transparent;
  opacity: 0;
}

/* FF 4-18 */

input:focus::-moz-placeholder {
  color: transparent;
  opacity: 0;
}

/* FF 19+ */

input:focus:-ms-input-placeholder {
  color: transparent;
  opacity: 0;
}

/* IE 10+ */

#WC_UserRegistrationAddForm_div_51 {
  .select-wrapper {
    width: 347px;
    select {
      width: 110%;
    }
  }
}

select::-ms-expand {
  display: none;
}
