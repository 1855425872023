/* This one should be removed once that from here
 * https://www.sunglasshut.com/wcsstore/MadisonsStorefrontAssetStore/static-cm/css/cm-redesign-eighteen.css
 * the rule.
 * .hidden {
 *   display: none !important;
 * }
 * will be removed
 */

.hidden-mobile {
  display: none;
}
.sgh-filters-name {
  word-break: break-word;
}
