html {
  &.cm-home {
    background: $white;
  }
}

.no-touch {
  .dl-menuwrapper {
    li {
      a {
        &:hover {
          background: rgba(255, 248, 213, 0.1);
        }
      }
    }
  }
}
