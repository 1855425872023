@import './icons/svg-icons';
@import './icons/svg-filter-icons';

.common__icon {
  display: inline-block;
  text-indent: -9999px;
  width: 24px;
  height: 24px;
  border: none;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  vertical-align: middle;

  &--icon-white {
    filter: brightness(0.1);
  }

  &--little {
    height: 12px;
    width: 12px;
  }

  &--small {
    height: 16px;
    width: 16px;
  }

  &--medium {
    height: 18px;
    width: 18px;
  }

  &--medium-xl {
    height: 20px;
    width: 20px;
  }

  &--large {
    height: 36px;
    width: 36px;
  }

  &--big {
    height: 48px;
    width: 48px;
  }

  /*ICONS EXCEPTIONS*/

  &--arrow-right {
    @extend %icon-arrow-left;
    transform: rotate(180deg);
  }

  &--arrow-down {
    @extend %icon-arrow-left;
    transform: rotate(270deg);
  }

  &--arrow-up {
    @extend %icon-arrow-left;
    transform: rotate(90deg);
  }

  &--camera {
    background-image: url('./images/icons/camera.svg');
  }
  &--find-in-store {
    background-image: url('./images/icons/find-in-store.svg');
  }
  &--gps-icon {
    background-image: url('./images/icons/gps-icon.svg');
  }

  &--chevron-up {
    @extend %icon-chevron;
    transform: rotate(180deg);
  }

  &--grid-2.inactive {
    opacity: 0.5;
  }

  &--grid-4.inactive {
    opacity: 0.5;
  }

  &--members-only {
    position: absolute;
    left: -16px;
    height: 14px;
    width: 14px;
  }

  &--pickup {
    height: 33px;
    width: 45px;
  }

  &--list {
    background-image: url('./images/icons/list.svg');
  }

  &--map {
    background-image: url('./images/icons/map.svg');
  }

  &--stella-lens {
    background-image: url('./images/icons/icon-stella-wayfarer.svg');
  }
}

.val-checked {
  @include icon-mixin-flag-valid;
}

.tcl-form-control,
.field .wrap-field,
.gift-card-balance .wrap-field {
  .cancel-input {
    @include icon-mixin-flag-cancel;
  }

  .error::after {
    @include icon-mixin-flag-error;
  }

  .valid::after {
    @include icon-mixin-flag-valid;
  }
}

#join-input-birthdate,
#update-profile-birthdate {
  .field__input.error ~ div {
    .subfield__label {
      color: $red-berry;
    }
  }

  &.desktop::-webkit-calendar-picker-indicator,
  &.empty::after,
  &.full::after {
    @include icon-mixin-datepicker;
  }
}

input[type='checkbox']:checked + .ma-checkboxIcon,
input[type='checkbox']:checked + .ea-checkboxIcon,
.marketingChecked.ma-checkboxIcon {
  &:before {
    @include icon-mixin-ma-checkbox-tick;
  }
}

.sgh-frame-genius #framegenius-ui {
  .fa__control {
    &-playlist:before {
      @extend %icon-fa-style-playlist;
    }
  
    &-temple-thickness:before {
      @extend %icon-fa-temple-thickness;
    }
  }
}

.sgh-icon__flag--eg {
  background-image: url(/sghstatichtml/assets/images/country-flags/EG.svg);
}

.sgh-icon__flag--ma {
  background-image: url(/sghstatichtml/assets/images/country-flags/MA.svg);
}

.sgh-icon__flag--za {
  background-image: url(/sghstatichtml/assets/images/country-flags/ZA.svg);
}

.sgh-icon__flag--br {
  background-image: url(/sghstatichtml/assets/images/country-flags/BR.svg);
}

.sgh-icon__flag--ca {
  background-image: url(/sghstatichtml/assets/images/country-flags/CA.svg);
}

.sgh-icon__flag--cl {
  background-image: url(/sghstatichtml/assets/images/country-flags/CL.svg);
}

.sgh-icon__flag--co {
  background-image: url(/sghstatichtml/assets/images/country-flags/CO.svg);
}

.sgh-icon__flag--mx {
  background-image: url(/sghstatichtml/assets/images/country-flags/MX.svg);
}

.sgh-icon__flag--pe {
  background-image: url(/sghstatichtml/assets/images/country-flags/PE.svg);
}

.sgh-icon__flag--us {
  background-image: url(/sghstatichtml/assets/images/country-flags/US.svg);
}

.sgh-icon__flag--hk {
  background-image: url(/sghstatichtml/assets/images/country-flags/HK.svg);
}

.sgh-icon__flag--in {
  background-image: url(/sghstatichtml/assets/images/country-flags/IN.svg);
}

.sgh-icon__flag--id {
  background-image: url(/sghstatichtml/assets/images/country-flags/ID.svg);
}

.sgh-icon__flag--ml {
  background-image: url(/sghstatichtml/assets/images/country-flags/ML.svg);
}

.sgh-icon__flag--sg {
  background-image: url(/sghstatichtml/assets/images/country-flags/SG.svg);
}

.sgh-icon__flag--th {
  background-image: url(/sghstatichtml/assets/images/country-flags/TH.svg);
}

.sgh-icon__flag--fr {
  background-image: url(/sghstatichtml/assets/images/country-flags/FR.svg);
}

.sgh-icon__flag--de {
  background-image: url(/sghstatichtml/assets/images/country-flags/DE.svg);
}

.sgh-icon__flag--nl {
  background-image: url(/sghstatichtml/assets/images/country-flags/NL.svg);
}

.sgh-icon__flag--pt {
  background-image: url(/sghstatichtml/assets/images/country-flags/PT.svg);
}

.sgh-icon__flag--es {
  background-image: url(/sghstatichtml/assets/images/country-flags/ES.svg);
}

.sgh-icon__flag--gb {
  background-image: url(/sghstatichtml/assets/images/country-flags/GB.svg);
}

.sgh-icon__flag--ae {
  background-image: url(/sghstatichtml/assets/images/country-flags/AE.svg);
}

.sgh-icon__flag--tr {
  background-image: url(/sghstatichtml/assets/images/country-flags/TR.svg);
}

.sgh-icon__flag--bh {
  background-image: url(/sghstatichtml/assets/images/country-flags/BH.svg);
}

.sgh-icon__flag--jo {
  background-image: url(/sghstatichtml/assets/images/country-flags/JO.svg);
}

.sgh-icon__flag--kw {
  background-image: url(/sghstatichtml/assets/images/country-flags/KW.svg);
}

.sgh-icon__flag--lb {
  background-image: url(/sghstatichtml/assets/images/country-flags/LB.svg);
}

.sgh-icon__flag--om {
  background-image: url(/sghstatichtml/assets/images/country-flags/OM.svg);
}

.sgh-icon__flag--qa {
  background-image: url(/sghstatichtml/assets/images/country-flags/QA.svg);
}

.sgh-icon__flag--sa {
  background-image: url(/sghstatichtml/assets/images/country-flags/SA.svg);
}

.sgh-icon__flag--au {
  background-image: url(/sghstatichtml/assets/images/country-flags/AU.svg);
}

.sgh-icon__flag--nz {
  background-image: url(/sghstatichtml/assets/images/country-flags/NZ.svg);
}

.sgh-icon__virtual-mirror {
  background-image: url('./images/virtual-mirror-icon.svg');
  background-repeat: no-repeat;
}

.video-icon-mute {
  background-image: url(/sghstatichtml/assets/images/audio_on.png);
}

.video-icon-unmute {
  background-image: url(/sghstatichtml/assets/images/audio_off.png);
}

.video-icon-play {
  background-image: url(/sghstatichtml/assets/images/play.png);
}

.video-icon-pause {
  background-image: url(/sghstatichtml/assets/images/pause.png);
}
