/* stylelint-disable */
@import '../mixins/mixins';

#right-nav {
  @include transition(right, 0.5s);
  position: absolute;
  top: 88px;
  right: 0;
  height: 100%;
  z-index: 9002;

  &.open {
    @include animation(itemAdded, 0.5s);
    position: fixed;

    .close {
      display: block;
      @include animation(showClose, 0.5s);
    }
  }

  &.hto-open {
    @include animation(itemAdded, 0.5s);
  }

  ul {
    float: left;
    width: 50px;
    list-style-type: none;
  }

  li {
    position: relative;
    color: $white;
    text-align: center;

    &.hover {
      a {
        background-color: $dark-gray;

        .first {
          display: none;
        }

        .second {
          display: block;
        }
      }

      .slider {
        @include animation(rightFlyout, 0.5s);
      }
    }

    .second,
    .third {
      display: none;
    }

    .slider {
      @include primaryFont();
      @include transition(right, 0.5s);
      position: absolute;
      top: 0;
      right: -60px;
      width: 110px;
      height: 50px;
      color: $silver-chalice;
      line-height: 5rem;
      z-index: 1;
      background-color: $dark-gray;
      font-size: 1rem;

      .second {
        display: none;
      }
    }

    a {
      position: relative;
      display: block;
      height: 50px;
      margin: 0 auto;
      color: $white;
      z-index: 2;
      background-color: $concrete;

      &.redesignIcons-hto-bag {
        line-height: 0;
        text-indent: -9999px;
      }

      .icon {
        position: relative;
        margin: 0 auto;
      }
    }
  }

  .cart {
    &:hover {
      a {
        background-color: $alizarin-crimson;
      }
    }

    a {
      background-color: $alizarin-crimson;

      .icon {
        top: 12px;
      }

      .cartcount {
        @include primaryFont();
        display: inline-block;
        font-size: 1rem;
        font-weight: bold;
        padding: 7px 0 0;
      }
    }

    .slider {
      color: $white;
      text-align: right;
      background-color: $alizarin-crimson;
    }
  }

  .search,
  .account,
  .hto {
    .icon {
      top: 15px;
    }

    a {
      &.active {
        background-color: $dark-gray;

        .first,
        .second {
          display: none !important;
        }

        .third {
          top: 18px;
          display: block;
        }

        + .slider {
          right: 50px;
          -moz-animation: none;
          -webkit-animation: none;
          animation: none;

          .first {
            display: none;
          }

          .second {
            display: block;
          }
        }
      }
    }
  }

  .hto {
    .icon {
      top: 0;
    }
  }

  .account {
    .icon {
      top: 0;
    }

    a {
      &.active {
        .third {
          top: 3px;
        }
      }
    }

    a.siginAccountDetails {
      .icon {
        background-position: 0 -1899px;
      }

      .redesignIcons-account-white {
        background-position: -18px -1899px;
      }
    }
  }

  .account,
  .favorites {
    a {
      text-indent: -9999px;
    }
  }

  .already-added {
    @include primaryFont();
    width: 200px;
    margin: 0 auto;
    padding: 10px 0 0;
    color: $alizarin-crimson;
    font-size: 1rem;
    font-style: italic;
    font-weight: bold;
  }

  .item-display {
    position: absolute;
    left: 50px;
    float: right;
    width: 256px;
    height: 102%;
    z-index: 9000;
    background-color: $concrete;
    margin-top: -50px;
    text-align: center;

    &.top {
      z-index: 9001;
    }

    &#hto-dropdown {
      .redesignIcons-hto-logo {
        display: inline-block;
        margin: 0 0 20px;
      }

      h2 {
        margin: 0 0 15px;
        font: {
          size: 18px;
        }
      }

      #hto-error-container {
        display: none;

        &.required {
          display: block;
          margin: 0 0 10px;
        }
      }

      p {
        &.required {
          @include primaryFont();
          color: $alizarin-crimson;
          font-size: 1rem;
          font-style: italic;
          font-weight: bold;
        }

        &.link {
          margin: 20px 0 -20px;
        }

        strong {
          @include primaryFont();
          display: inline-block;
          font-size: 1.1rem;
          margin: 20px 0 0;
        }
      }

      .continueshopping {
        @include primaryFont();
        font-size: 1rem;

        &:before {
          content: '\2039';
          margin: 0 3px 0 0;
        }
      }

      .holding-area {
        width: 194px;
        height: 64px;
        margin: 0 auto 20px;

        .redesignIcons-hold {
          margin: 0 0 20px;
        }

        .item {
          width: 100%;

          &:hover {
            .removeLink {
              display: block !important;
            }

            .frontView_Image,
            .details {
              opacity: 0.3;
            }
          }

          .frontView_Image {
            float: left;
            width: 118px;
          }

          .details {
            float: left;
            width: 80px;
            margin: 8px 0 0 -5px;
            text-align: left;

            .brand {
              @include primaryFont();
              font-size: 1.1rem;
              font-weight: bold;
              margin: 0;
            }

            .style {
              @include primaryFont();
              font-size: 1.1rem;
            }
          }

          .removeLink {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100px;
            height: 40px;
            margin: -20px 0 0 -50px;
            line-height: 4rem;
            background-color: $dark-gray;

            a {
              @include primaryFont();
              color: $white;
              font-size: 1rem;
              font-weight: bold;
              line-height: 0;

              .redesignIcons-delete {
                display: inline-block;
                background: none;

                &:before {
                  content: 'x';
                  margin: 0 5px 0 0;
                }
              }
            }
          }

          .badge,
          .catalog-product-rating,
          .catalog-colors,
          .price {
            display: none;
          }
        }
      }
    }

    .close {
      @include transition(opacity, 0.5s);
      position: absolute;
      left: -100px;
      display: none;
      width: 100px;
      height: 50px;
      color: $silver-chalice;
      line-height: 5rem;
      opacity: 0;
      background-color: $dark-gray;
      text-align: center;
      font: {
        size: 10px;
      }

      span {
        position: relative;
        top: 3px;
        display: inline-block;
        margin: 0 0 0 8px;
      }
    }

    h2 {
      @include primaryFont();
      font-size: 2.2rem;
      font-weight: bold;
      margin: 50px 0 5px;
    }

    .item-added {
      margin: 0 0 50px;
      color: $alizarin-crimson;
      font: {
        size: 14px;
        style: italic;
      }

      span {
        @include primaryFont();
        font-size: 1.4rem;
        font-weight: bold;
      }

      + p,
      .added {
        @include primaryFont();
        font-size: 1rem;
        font-weight: bold;
        margin: 0 0 15px;
      }
    }

    .brandStyle {
      width: 70%;
      margin: 0 auto 50px;

      .brand,
      .price {
        @include primaryFont();
        font-weight: bold;
      }

      .cart-widget-eGiftCard {
        text-transform: none;
      }
    }

    .subtotal {
      margin: 0 0 20px;
      font-style: italic;

      span {
        @include primaryFont();
        font-size: 1.4rem;
        font-weight: bold;
        margin: 0 0 0 5px;
      }
    }

    .espot {
      margin: 40px 0 0;

      p {
        font: {
          size: 11px;
        }
      }
    }
  }
}

/* Animations */

@keyframes rightFlyout {
  0% {
    right: -55px;
  }

  100% {
    right: 50px;
  }
}

@-moz-keyframes rightFlyout {
  0% {
    right: -55px;
  }

  100% {
    right: 50px;
  }
}

@-webkit-keyframes rightFlyout {
  0% {
    right: -55px;
  }

  100% {
    right: 50px;
  }
}

@keyframes itemAdded {
  0% {
    right: 0;
  }

  100% {
    right: 256px;
  }
}

@-moz-keyframes itemAdded {
  0% {
    right: 0;
  }

  100% {
    right: 256px;
  }
}

@-webkit-keyframes itemAdded {
  0% {
    right: 0;
  }

  100% {
    right: 256px;
  }
}

@keyframes showClose {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes showClose {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes showClose {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

body.sgh #sgh_header_bottom #right-nav .item-display {
  width: 256px !important;
}

body.sgh .sgh-nw #sgh_header_bottom #right-nav.open {
  top: -51px !important;
  right: 256px !important;
  transform: none !important;
  -webkit-animation: rightmove 0.5s 1 !important; /* Safari 4.0 - 8.0 */
  -webkit-animation-direction: alternate !important; /* Safari 4.0 - 8.0 */
  animation: rightmove 0.5s 1 !important;
  animation-direction: alternate !important;
}

@-webkit-keyframes rightmove {
  0% {
    right: 0;
  }

  25% {
    right: 75px;
  }

  50% {
    right: 150px;
  }

  75% {
    right: 200px;
  }

  100% {
    right: 256px;
  }
}

@keyframes rightmove {
  0% {
    right: 0;
  }

  25% {
    right: 75px;
  }

  50% {
    right: 150px;
  }

  75% {
    right: 200px;
  }

  100% {
    right: 256px;
  }
}

body.sgh .sgh-nw #sgh_header_bottom #right-nav.closing {
  top: -51px !important;
  right: 0 !important;
  transform: none !important;
  -webkit-animation: leftmove 0.5s 1 !important; /* Safari 4.0 - 8.0 */
  -webkit-animation-direction: alternate !important; /* Safari 4.0 - 8.0 */
  animation: leftmove 0.5s 1 !important;
  animation-direction: alternate !important;
}

@-webkit-keyframes leftmove {
  0% {
    right: 256px;
  }

  25% {
    right: 200px;
  }

  50% {
    right: 150px;
  }

  75% {
    right: 75px;
  }

  100% {
    right: 0;
  }
}

@keyframes leftmove {
  0% {
    right: 256px;
  }

  25% {
    right: 200px;
  }

  50% {
    right: 150px;
  }

  75% {
    right: 75px;
  }

  100% {
    right: 0;
  }
}
