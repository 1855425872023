/* stylelint-disable */
@import '../mixins/mixins';

#styles {
  .banner-img {
    margin: 0 !important;
  }

  #top {
    bottom: 70px !important;
  }

  #selector {
    background-color: $mine-shaft;
    float: left;
    width: 100%;
    padding: 0 0 90px;
    min-height: 108px;

    .container {
      position: relative;
      width: 575px;
      margin: 0 auto;

      .owl-carousel {
        .owl-wrapper-outer {
          min-height: 62px;
        }
      }

      > p {
        @include primaryFont();
        font-size: 1.3rem;
        font-weight: bold;
        margin: 0 0 30px;
        color: $white;
        text-align: center;
      }

      .select {
        position: relative;
        float: left;
        width: 25%;
        text-align: center;

        .icon {
          margin: 0 auto 20px;
        }

        p {
          @include primaryFont();
          font-size: 1.3rem;
          position: absolute;
          top: 50px;
          width: 100%;
          color: $white;
          text-align: center;
        }
      }

      .owl-buttons {
        div {
          position: absolute;
          top: -10px;
          left: -92px;
          width: 50px;
          height: 50px;
          background: transparent url('../assets/images/redesign-icons/frame-prev.png') no-repeat;
          text-indent: -9999px;

          &.owl-next {
            right: -92px;
            left: auto;
            background-image: url('../assets/images/redesign-icons/frame-next.png');
          }
        }
      }
    }
  }

  #styles-landing {
    .product-info {
      position: relative;
      float: left;
      width: 50%;
      height: 455px;
      padding: 90px 0 0;
      background-color: $concrete;
      text-align: center;

      .highlight-arrow {
        position: absolute;
        top: 50%;
        right: -15px;
        width: 0;
        height: 0;
        margin: -30px 0 0;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-left: 30px solid $concrete;
      }

      .icon {
        margin: 0 auto 20px;
      }

      h2 {
        @include primaryFont();
        font-size: 2.4rem;
        font-weight: bold;
        margin: 0 0 50px;
      }

      p {
        @include primaryFont();
        font-size: 1.2rem;
        font-weight: bold;
        width: 290px;
        margin: 0 auto 20px;
        line-height: 2rem;
      }

      .copy {
        p {
          display: inline-block;
          width: auto;
        }

        .tooltip {
          position: static;
          display: inline-block;
          margin: 0 0 0 5px;

          .icon {
            margin: 0;
          }

          .tooltipContent {
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0;

            ul {
              float: left;
              width: 60%;
              padding: 110px 20%;
              list-style-type: none;

              li {
                @include primaryFont();
                font-size: 1.4rem;
                float: left;
                width: 50%;
                margin: 0 0 50px;
                color: $white;
                text-align: center;

                .icon {
                  display: block;
                  margin: 0 auto 20px;
                }

                &.disabled {
                  opacity: 0.15;

                  .icon {
                    opacity: 0.15;
                  }
                }
              }
            }
          }

          .arrow {
            display: none;
          }
        }
      }

      .black-button {
        margin: 30px 0 0;
      }
    }

    .carousel-container {
      position: relative;
      float: right;
      width: 50%;
      height: 545px;
      text-align: center;

      .item {
        width: 100%;
        height: 475px;

        .catalog-colors {
          display: none !important;
        }
      }

      .owl-buttons {
        div {
          position: absolute;
          top: 50%;
          left: 10%;
          width: 50px;
          height: 50px;
          margin: -30px 0 0;
          background: transparent url('../assets/images/redesign-icons/frame-black-prev.png')
            no-repeat;
          text-indent: -9999px;

          &.owl-next {
            right: 10%;
            left: auto;
            background-image: url('../assets/images/redesign-icons/frame-black-next.png');
          }
        }
      }

      .owl-pagination {
        position: relative;
        top: -71px;
        text-align: center;

        .owl-page {
          @include rounded(10px);
          display: inline-block;
          width: 8px;
          height: 8px;
          margin: 0 5px 0 0;
          border: 1px solid $dusty-gray-3;

          &.active {
            width: 10px;
            height: 10px;
            border: none;
            background-color: $dark-gray;
          }
        }
      }
    }

    .row {
      float: left;
      width: 100%;
      clear: both;
    }

    .even {
      .product-info {
        float: right;

        .highlight-arrow {
          right: auto;
          left: -15px;
          border-top: 30px solid transparent;
          border-bottom: 30px solid transparent;
          border-right: 30px solid $concrete;
          border-left: none;
        }
      }

      .carousel-container {
        float: left;
      }
    }
  }
}
