.sgh-accordion {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @screen mdr {
    flex-wrap: wrap;
    margin-bottom: 16px;
  }
  &-column {
    width: calc(50% - 15px);
    @screen mdr {
      width: 100%;
    }
  }
  &-item-opener {
    display: flex;
    align-items: center;
    width: 100%;
    height: 72px;
    padding: 25px 25px 25px 0;
    border: 0;
    outline: 0;
    background: transparent;
    text-align: left;
    color: $mine-shaft;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 32px;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      border-style: solid;
      border-width: 0 1px 1px 0;
      @screen mdr {
        padding: 4px;
      }
    }
    &.open {
      &:after {
        transform: rotate(45deg) scale(-1);
      }
    }
  }
  &-item-content {
    max-height: 0;
    overflow: hidden;
    color: $emperor;
    transition: max-height 0.3s ease-out;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    p {
      margin-bottom: 25px;
    }
  }
}

.sgh-cms__faq {
  padding: 56px 0;
  background-color: $white;
  @screen mdr {
    padding: 56px 0 30px;
  }
  .title {
    margin-bottom: 20px;
  }
  .sgh-pdp__btn-container {
    margin: 0;
    p {
      margin: 0;
    }
  }
  .container-custom {
    max-width: 920px;
    padding: 0 15px;
    margin: 0 auto;
  }
}
