@import 'mixins';

.cms-mini-slider-box-all-fields {
  .swiper-pagination {
    margin-top: 2.4rem;
  }

  .banners {
    padding: 0 15%;
  }

  .right-col {
    @include banner-picture;

    .picture {
      padding-top: calc(537 / 826) * 100%;
    }
  }

  @screen sm {
    .swiper-pagination {
      display: block;
    }
  }

  .sgh-cms-product-tile--box {
    .brand,
    .price,
    .colors {
      font-size: 1.2rem;
    }

    .brand {
      font-weight: 500;
      text-transform: uppercase;
      margin: 2.4rem 0 0.8rem 0;
    }

    .price {
      margin-bottom: 0.5rem;
    }
  }
}

@media screen and (min-width: 768px) {
  .cms-mini-slider-box-all-fields {
    &.carousel-banner {
      .left-col {
        grid-column: 1 / span 5;
        grid-row: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .right-col {
        grid-column: 6 / span 7;
        grid-row: 1;
      }
    }

    &.banner-carousel {
      .left-col {
        grid-column: 8 / span 5;
        grid-row: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .right-col {
        grid-column: 1 / span 7;
        grid-row: 1;
      }
    }
  }
}
