/* stylelint-disable */
@import '../mixins/mixins';
@import '../icons/svg-icons';

.sgh-header.header--neath {
  z-index: 1;
}

.find-in-store-wrapper {
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &--visible {
    position: fixed;
    display: flex;
    z-index: 10001;
  }
}

.btn-wrapper {
  width: 100%;
  text-align: right;

  &__confirm {
    width: 100%;
    max-width: 134px;
    @screen mdr {
      max-width: initial;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.4);
}

.find-in-store-modal {
  .disabledCheckoutBtn {
    pointer-events: none;
    color: $dusty-gray-3 !important;
  }

  .showHideText {
    visibility: hidden;
  }
}

.find-in-store-modal,
.click-collect-wrapper {
  img {
    max-width: 100%;
  }

  a:focus {
    color: inherit;
  }

  &__wrapper {
    @screen mdr {
      width: 100%;
      max-width: 375px;
      height: 100%;
      margin: 0;
      padding: 0 3px;
      overflow-x: hidden;
    }
  }
  @screen mdr {
    display: flex;
    justify-content: center;
  }

  &__headline {
    position: relative;
  }

  .headline {
    @screen mdr {
      margin-bottom: 24px;
    }

    &__title {
      @include primaryFont();
      font-size: 2.4rem;
      font-weight: 300;
      margin-bottom: 27px;
    }
  }

  .available-stores {
    @include primaryFont();
    font-size: 1.8rem;
    font-weight: 500;
    color: $mine-shaft;
    line-height: normal;
    padding-top: 0;
    margin-top: 48px;
    margin-bottom: 10px;
    @screen lg {
      font-size: 1.6rem;
      font-weight: 300;
      padding-top: 11px;
      margin-top: 0;
      margin-bottom: 0;
      border-top: solid 1px $alto;
    }
  }

  .location {
    padding-left: 0;
    @screen lg {
      padding-left: 7px;
    }

    &__stores {
      max-height: 300px;
      @screen mdr {
        max-height: 100%;
      }
      @screen lg {
        margin-right: -20px;
      }

      .cncModal-opener {
        padding-top: 2.2rem;
      }
    }

    .validation-error {
      input {
        border-color: $monza;
      }
    }
  }

  .field-wrapper__item--input {
    width: 100%;
    max-width: 100%;

    & ~ .input-info__message.error-message {
      position: absolute;
      top: 44px;
      left: 0;
      margin: 0;
      padding: 0;
      margin-bottom: 0;
      line-height: 1.14;
      font-style: normal;
      @screen lg {
        top: 43px;
        font-style: italic;
      }
    }
  }

  .mCSB_inside > .mCSB_container {
    margin-right: 0;
  }

  .mCSB_scrollTools {
    width: 8px;

    .mCSB_draggerContainer > .mCSB_dragger {
      .mCSB_dragger_bar {
        width: 100%;
        background-color: $dusty-gray;
      }
    }

    .mCSB_draggerRail {
      width: 100%;
      border-radius: 4px;
      background-color: rgba(244, 244, 244, 0.5);
    }
  }

  .store-result {
    position: absolute;
    width: 100%;
    list-style: none;

    &__item {
      padding-left: 10px;
    }

    &__link {
      font-size: 1.4rem;
    }
  }

  .none {
    display: none;
  }

  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__add-btn {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      @screen lg {
        max-width: none;
        margin: 0;
      }
    }

    &__item {
      position: relative;
      width: 50%;
      @screen mdr {
        width: 100%;

        &--mobile {
          @include transform(translateX(100%));
          position: absolute;
          top: 0;
          max-width: 375px;
          height: 100%;
          z-index: 2;
          padding-top: 55px;
          transition-duration: 0.2s;
          background-color: $white;
        }

        &--selected {
          @include transform(translateX(0));

          & ~ .content-wrapper__add-btn {
            position: fixed;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 16px;
            z-index: 1000;
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), $white 33%);
          }

          .store-info__title {
            position: absolute;
            top: 0;
            width: 100%;
          }
        }
      }
      @screen lg {
        padding-right: 35px;
      }

      & + .content-wrapper__item {
        @screen lg {
          padding-right: 0;
          padding-left: 35px;
        }
      }
    }
  }

  .button-wrapper {
    text-align: right;

    .btn {
      transition: all 0s;
    }
    @screen mdr {
      flex-grow: 1;
      min-height: initial;
      text-align: center;
      padding-top: 0;

      a {
        display: block;
      }
    }
  }

  .propagation-animation {
    transition: none;
  }

  .store-list {
    max-width: 422px;
    list-style: none;
    margin-right: 20px;
    @screen mdr {
      margin-right: 0;
    }

    &__item {
      &--selected {
        .store-list__checkbox {
          background: url('../assets/images/redesign-icons/radio-btn-check.svg') center no-repeat;
          border-color: transparent;
        }
      }
    }

    &__link {
      display: flex;
      align-items: center;
      min-height: 50px;
      padding: 22px 0;
      border-bottom: solid 1px $alto;
      @screen lg {
        padding: 3px 0 3px 14px;
      }

      .info {
        @screen lg {
          margin: 0 11px;
        }

        &--additional {
          margin-left: auto;
          flex-shrink: 0;
          @screen mdr {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
          }
        }

        &__title {
          display: block;
          font-weight: 400;
          @screen mdr {
            font-size: 1.8rem;
            line-height: 1.2;
          }
        }

        &__distance {
          font-size: 1.2rem;
          @screen mdr {
            font-size: 1.4rem;
          }
        }

        &__collect-icon {
          display: none;
          font-size: 2.7rem;
          line-height: 2rem;
          text-align: right;
          margin-right: -5px;
        }
      }
    }

    &__checkbox {
      display: block;
      width: 16px;
      height: 16px;
      border: 1px solid $alto;
      flex-shrink: 0;
      border-radius: 50%;
      @screen mdr {
        display: none;
      }
    }
  }

  .selected-store {
    .results {
      display: flex;
      flex-direction: column;
      font-size: 1.4rem;
      font-weight: 400;
      @screen mdr {
        font-size: 1.6rem;
        font-weight: 400;
      }

      .store-info {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @screen mdr {
          margin-top: 0;
          padding-right: 0;
        }

        &__address-wrapper {
          display: flex;
          flex-wrap: wrap;
          flex-grow: 1;
          width: 50%;
          @screen mdr {
            width: 100%;
          }
        }

        &__title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 4px;

          &--text {
            color: $mine-shaft;
            font-size: 1.6rem;
            font-weight: 400;
          }
          @screen mdr {
            order: -3;
            margin: 0 0 20px;
            line-height: 1;

            &--text {
              display: block;
              overflow: hidden;
              font-size: 2.4rem;
              line-height: 1.2;
              font-weight: 300;
              white-space: nowrap;
              text-overflow: ellipsis;
              padding-right: 5px;
            }
          }
        }

        &__address {
          width: 100%;
          margin-bottom: 14px;
        }

        &__open-hours {
          @screen lg {
            padding: 16px 3px 0;
          }

          .store-time {
            &__item {
              display: table;
              margin-bottom: 8px;
              font-size: 1.4rem;
              line-height: normal;
              @screen lg {
                margin-bottom: 5px;
                font-size: 1.2rem;
                line-height: 1.67;
              }

              &:last-child {
                margin-bottom: 0;
              }

              &--current-day {
                margin-left: -2px;
                padding: 0 3px;
                background-color: $mine-shaft;
                color: $white;
              }
            }
          }
        }
      }

      &__cnc-description {
        @include primaryFont();
        font-size: 1.2rem;
        order: inherit;
        color: $black;
        line-height: 1;
        letter-spacing: 0.6px;
        padding-top: 0;
        @screen mdr {
          @include primaryFont();
          font-size: 1.6rem;
          order: 2;
          margin: 0;
          padding-top: 20px;
        }

        .icon-cart1,
        .icon-cart-arrow-right {
          position: relative;
          top: 5px;
          font-size: 2.3rem;
          margin-left: -7px;
          margin-right: 5px;
        }

        .method-name {
          text-transform: uppercase;
        }

        p {
          margin: 5px 0;
        }
      }

      &__map {
        order: -2;
        margin-bottom: 25px;
        @screen lg {
          margin-bottom: 15px;
        }
      }

      &-location {
        display: flex;
        width: 100%;

        &__distance {
          display: none;
          margin-right: auto;
          @screen mdr {
            display: block;
          }
        }

        &__direction {
          color: $emperor;
          font-size: 1.6rem;
          text-decoration: underline;
          @screen lg {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  .arrow {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      display: none;
      width: 7px;
      height: 7px;
      border-top: 1px solid $emperor;
      border-left: 1px solid $emperor;
      @screen mdr {
        display: block;
      }
    }
    @screen mdr {
      &--right {
        padding-right: 15px;

        &:before {
          right: 4px;
          @include transform(rotate(135deg));
        }
      }

      &--left {
        padding-left: 15px;

        &:before {
          left: 2px;
          @include transform(rotate(-45deg));
        }
      }
    }
  }
  .sgh-tip-notification {
    position: relative;
    overflow: visible !important;
    margin-right: 1.5rem;
    &__element {
      position: absolute;
      display: block;
      top: 50%;
      left: 0;
      z-index: 100;
      width: auto;
      padding: 1rem;
      text-indent: 0;
      font-size: 1rem;
      white-space: nowrap;
      overflow: visible;
      transform: translate(-100%, -50%);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
      color: $emperor;
      box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.1);
      background: $white;

      &::after {
        display: block;
        position: absolute;
        top: 50%;
        right: -0.7rem;
        content: '';
        transform: translate(0, -50%);
        z-index: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.7rem 0 0.7rem 0.7rem;
        border-color: transparent transparent transparent $white;
      }
      &.is-visible {
        opacity: 1;
        visibility: visible;
        left: -1rem;
      }
      + .sgh-tip-notification__element {
        display: none;
      }
    }
  }

  .input-info {
    &__title {
      @include primaryFont();
      font-size: 1.2rem;
      font-weight: 400;
      margin-bottom: 4px;
      display: block;
    }

    &__field {
      position: relative;
      @screen mdr {
        margin-bottom: 35px;
      }
    }

    &__message {
      margin: 21px 0 19px;
      @include primaryFont();
      font-size: 1.4rem;
      font-weight: 500;
    }

    &__disclaimer {
      line-height: 1.4rem;
      letter-spacing: 0;
      text-align: left;
      @include primaryFont();
      font-size: 1.2rem;
      font-weight: 300;
    }
  }
}

@screen mdr {
  .find-in-store-modal__wrapper {
    .content-wrapper__add-btn {
      display: none;
    }

    &.store-list-wrapper--mobile-hidden {
      .find-in-store-modal__location,
      .find-in-store-modal__headline,
      .content-wrapper__item:not(.content-wrapper__item--selected) {
        display: none;
      }

      .content-wrapper__add-btn {
        display: block !important;
      }

      .content-wrapper__item.content-wrapper__item--selected {
        position: static;
        padding-bottom: 170px;
      }
    }

    .content-wrapper__item--selected {
      display: flex;
      flex-direction: column;

      .selected-store {
        display: flex;
        flex-direction: column;
      }

      .selected-store__info {
        flex-grow: 1;
      }

      .btn-wrapper {
        margin-top: auto;
        @screen mdr {
          margin-top: 0;
        }
      }
    }
  }
}

.express-tab-content {
  .btn-wrapper__confirm {
    display: none;
  }
}

@screen mdr {
  .express-tab-content--mobile {
    .field-wrapper__item.zip-code-wrapper {
      order: 1;
    }

    .field-wrapper__item.deliv-hours-wrapper {
      order: 3;
    }

    .field-wrapper__item.button-wrapper {
      order: 2;
    }

    .btn-wrapper__confirm {
      @include btn-black();
      display: block;
      width: 100%;
      max-width: 100%;
      margin-bottom: 25px;
    }
  }
}

.field-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  &__find-btn {
    @include transform(translate(-50%, -50%));
    position: absolute;
    top: 50%;
    right: 0;
    width: 15px;
    height: 15px;
    border: none;
    background: transparent url('../assets/images/click-collect/geolocation.png') center no-repeat;
    background-size: 15px 15px;
  }

  &__item {
    @screen mdr {
      display: flex;
      width: 100%;
    }

    &--input {
      position: relative;
      flex-grow: 1;
      @screen mdr {
        max-width: 100%;
      }

      input[type='search'] {
        width: 100%;
        height: 35px;
        padding: 11px 28px 10px 11px;
        border: solid 1px $alto;
        font-size: 1.2rem;
        border-radius: 1px;
        background-color: $white;

        &:focus {
          @include placeholder {
            color: $dusty-gray-2;
            opacity: 1;
          }
        }
        @include placeholder {
          color: $dusty-gray-2;
        }
        @screen mdr {
          height: 40px;
          font-size: 1.6rem;
        }
      }
    }
  }
}

.content-wrapper__heading {
  display: none;
  @screen lg {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
    @include primaryFont();
    font-size: 1.8rem;
    font-weight: 300;
  }

  .selected-store & {
    color: $mine-shaft;
  }
}

.selected-store__distance {
  flex-shrink: 0;
  @include primaryFont();
  font-size: 1.6rem;
  font-weight: 400;
  @screen lg {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .store-info__title & {
    @screen lg {
      display: none;
    }
  }
}

.product-preview {
  display: flex;
  padding: 16px 9px;
  border-radius: 1px;
  border: solid 1px $alto;
  margin-bottom: 25px;
  @include primaryFont();
  font-size: 1.4rem;
  font-weight: 300;
  @screen lg {
    padding: 16px;
    font-size: 1.2rem;
  }

  .product-is-not-avaliable & {
    opacity: 0.4;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 70px;
    align-self: center;
    background-color: $wild-sand;
    @screen lg {
      width: 120px;
      height: 80px;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .product-name {
    margin-bottom: 0;
    @include primaryFont();
    font-size: 1.6rem;
    line-height: normal;
    text-transform: uppercase;
    @screen lg {
      font-size: 1.2rem;
    }
  }

  .product-upc {
    margin-bottom: 5px;
    font-weight: 400;
  }

  .product-attributes {
    margin-top: 8px;
    text-transform: uppercase;
    @include primaryFont();
    font-size: 1.2rem;
    font-weight: 400;
    @screen lg {
      font-size: 1rem;
    }
  }

  &__info {
    padding-left: 16px;

    p {
      margin-bottom: 2px;
    }
  }
}

.store-schedule {
  &__title {
    @include primaryFont();
    font-size: 1.6rem;
    font-weight: 400;
    color: $mine-shaft;
    line-height: normal;
    @screen lg {
      font-size: 1.4rem;
      font-weight: 300;
    }
  }

  &__subtitle {
    display: none;
    @screen lg {
      display: block;
      margin-bottom: 0;
      @include primaryFont();
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.2;
    }
  }

  .sgh-panel__title {
    @screen lg {
      display: none;
    }
  }

  .sgh-panel__toggler {
    display: none;
    @screen lg {
      display: inline-block;
    }
  }

  .sgh-icon-button--arrow {
    padding: 2.5px;
  }
}

.store-info {
  &.store-schedule {
    display: block !important;
    order: 2;
    @screen lg {
      order: 0;
    }
    @screen mdr {
      padding-bottom: 30px;
    }
  }

  &__address-wrapper {
    @screen lg {
      position: relative;
    }
  }

  &__availability {
    margin-bottom: 10px;
    @screen lg {
      position: absolute;
      top: 0;
      right: 0;
      margin-bottom: 0;
      text-align: right;
    }
  }

  &__availability-status {
    color: $red-berry;
    @screen lg {
      margin-bottom: 4px;
    }

    .store-available & {
      color: $camarone;
    }
  }

  &__availability-text {
    @screen lg {
      font-size: 1rem;
    }
  }

  &__phone-wrap {
    @screen lg {
      padding-left: 2.5rem;
      //@include icon-mixin-telephone;
      background-size: 2.4rem;
      background-repeat: no-repeat;
      background-position: 0;
    }
  }

  &__phone {
    color: $emperor;
    text-decoration: underline;
    @screen lg {
      color: $fade-gray;
      font-size: 1.4rem;
      pointer-events: none;
      text-decoration: none;
    }
  }

  &__phone-tip {
    margin-bottom: 8px;
    color: $mine-shaft;
    @screen lg {
      display: none;
    }

    & ~ [data-store-phone-toggler] {
      display: none;
      @screen lg {
        display: block;
      }

      & ~ .store-info__phone {
        @screen lg {
          display: none;
        }
      }
    }
  }
}

.find-in-store-content {
  @include primaryFont();
  font-size: 1.6rem;
  color: $emperor;

  .location__input {
    .input-info {
      display: none;
    }
  }

  &.find-in-store-content--initial-state {
    .location__input {
      position: relative;
    }

    .location__input > .input-info__message {
      position: absolute;
      bottom: 44px;
      left: 0;
      @screen lg {
        bottom: 34px;
      }
    }
  }

  &.product-is-not-avaliable {
    .location__input > .input-info__message {
      bottom: -50px;
      @screen lg {
        bottom: -28px;
      }
    }

    .reduce-padding {
      padding: 0;
    }
  }

  .field-wrapper__item--input {
    margin-bottom: 40px;
    @screen lg {
      margin-bottom: 22px;
    }

    & ~ .field-wrapper__item {
      @screen lg {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }

  .content-wrapper {
    &__item {
      display: flex;
      flex-direction: column;
      padding-bottom: 60px;
      @screen lg {
        display: block;
        padding-bottom: 0;
      }
    }
  }

  .find-in-store-modal__wrapper {
    @screen mdr {
      position: relative;
    }
  }

  .headline__title {
    @screen xl {
      font-size: 3.2rem;
    }
  }

  .input-info__title {
    color: $fade-gray;
    font-size: 1.6rem;
    line-height: 1;
    margin-bottom: 10px;
    @screen lg {
      font-size: 1.2rem;
    }
  }

  .input-info__field.field-wrapper {
    @screen lg {
      margin-top: auto;
      margin-bottom: 0;
    }
  }

  .input-info__message {
    &.error-message {
      margin: 0 0 10px;
      color: $monza;
      font-size: 1.4rem;
      font-weight: 400;
      font-style: italic;
      padding-top: 4px;
      @screen lg {
        font-size: 1rem;
      }
    }
  }

  .info__title {
    color: $emperor;
    @screen lg {
      line-height: 1;
    }
  }

  .store-info {
    margin-bottom: 24px;
    @screen lg {
      margin-bottom: 16px;
    }

    & + .store-info {
      padding-top: 24px;
      border-top: 1px solid $alto;
      @screen lg {
        padding-top: 16px;
      }

      &:last-child {
        @screen lg {
          padding-bottom: 16px;
          margin-bottom: 0;
          border-bottom: 1px solid $alto;
        }
      }
    }

    &.store-schedule + .store-info:last-child {
      @screen lg {
        padding-bottom: 0;
        margin-bottom: 16px;
        border-bottom: 1px solid transparent;
      }
    }
  }

  .store-info__availability {
    @mixin forDesktopUp {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
    }
  }

  .location {
    padding-left: 0;

    .btn.showAvailable {
      width: 100% !important;
      padding: 10px 25px !important;
      font-size: 1.6rem !important;
      line-height: 1.3 !important;
      @screen lg {
        width: auto !important;
        min-width: 138px !important;
        font-size: 1.2rem !important;
      }
    }
  }

  .selected-store .results-location__distance {
    display: none !important;
  }
}

.find-in-store-content--initial-state {
  .available-stores {
    @screen lg {
      border-top: 0;
      padding-top: 0;
      margin-top: 0;
    }
  }

  .headline__title {
    @screen lg {
      margin-bottom: 33px;
    }
  }

  .content-wrapper__item > .content-wrapper__heading {
    @screen lg {
      margin-top: -23px;
    }
  }

  &.find-in-store-content--card-page {
    .content-wrapper__item:first-child {
      @screen lg {
        align-self: center;
      }
    }
  }
}

.location-preview {
  display: none;
  height: 100%;
  @screen lg {
    display: block;
  }

  .location-preview__media {
    width: 100%;
    height: 100%;
  }
}

.location__result {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  @include primaryFont();
  font-size: 1.8rem;
  @screen lg {
    padding: 0;
    font-size: 1.4rem;
  }

  &.hide {
    & ~ .location__input .input-info {
      display: block;
    }
  }

  .button-link {
    @screen lg {
      font-size: 1.2rem;
      line-height: 1.4;
    }
  }
}

.location__selected {
  font-size: 1.8rem;
  font-weight: 400;
  padding-right: 20px;
  @screen lg {
    padding-right: 15px;
    font-size: 1.4rem;
  }
}

#current-location-map {
  height: 272px;
  pointer-events: none;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  img {
    opacity: 0;
  }
}

[data-cnc-info-panel='visible'] {
  visibility: visible;
}

[data-cnc-info-panel='hidden'] {
  visibility: hidden;
}

.cnc-description {
  display: none;
  padding-bottom: 100px;
  color: $black;
  font-size: 1.6rem;
  line-height: 1.38;
  font-weight: 500;
  @screen lg {
    display: none !important;
  }

  p {
    margin-bottom: 0;
  }

  .method-name {
    @screen mdr {
      text-transform: uppercase;
    }
  }

  .icon-cart1 {
    @screen mdr {
      position: relative;
      display: inline-block;
      width: 100%;
      font-size: 1.6rem;
      line-height: 1;
      margin-bottom: 6px;
    }
  }

  .icon-cart-arrow-right {
    @screen mdr {
      position: relative;
      top: 8px;
      font-size: 3.5rem;
      line-height: 1;
      margin-left: -10px;
    }
  }
}

.content-wrapper-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  min-height: 60px;
  padding: 40px 16px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), $white 33%);
  color: $black;
  z-index: 100;
  @screen lg {
    position: absolute;
    display: block;
    padding: 30px 50px;
  }

  .store-list-wrapper--mobile-hidden & {
    display: block;
  }

  .btn {
    width: 100%;
    font-size: 1.6rem;
    @screen lg {
      width: auto;
      font-size: 1.2rem;
    }
  }
}

.cnc-content {
  display: none;
  @screen lg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 44px;
    background: $wild-sand;
  }

  &--disabled {
    @screen lg {
      background: transparent;
    }
  }

  .store-list-wrapper--mobile-hidden & {
    @screen lg {
      display: flex;
    }
  }

  .results__cnc-description {
    display: none;
    flex-shrink: 0;
    @screen lg {
      display: block;
      padding-left: 25px;
    }

    p {
      margin-bottom: 0;
      font-size: 1.2rem;
    }
  }

  .method-name {
    @screen lg {
      font-size: 1.4rem;
      line-height: 0.86;
      font-weight: 500;
      letter-spacing: 0.7px;
      text-transform: uppercase;
    }
  }

  .icon-cart1 {
    @screen lg {
      font-size: 1.3rem;
      margin-left: -25px;
    }
  }

  .content-wrapper__add-btn .button-wrapper .btn {
    @screen lg {
      min-width: 137px !important;
      padding: 10px 25px !important;
      font-size: 1.2rem !important;
    }
  }
}

.button-link {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;

  color: $fade-gray;
  text-decoration: underline;
  @include primaryFont();
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
  flex-shrink: 0;
  @screen lg {
    font-size: 1.4rem;
  }
}

.sgh-panel__toggler {
  position: relative;
  width: 100%;
  padding: 0;
  border: 0;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  text-align: left;

  &:after {
    content: '';
    position: absolute;
    top: 5px;
    right: 2px;
    display: inline-block;
    padding: 2.5px;
    border-style: solid;
    border-width: 0 1px 1px 0;
    border-color: $tundora;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    @media screen and (min-width: 1600px) {
      padding: 4px;
    }

    .sgh-panel-opened & {
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
  }
}

.sgh-panel__box {
  @screen lg {
    overflow: hidden;
    max-height: 0;
    pointer-events: none;
    will-change: max-height;
  }

  .sgh-panel-opened & {
    @screen lg {
      max-height: 300px;
      pointer-events: all;
    }
  }
}

.results__map {
  max-height: 500px;
  will-change: max-height;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  img {
    opacity: 0;
  }

  .sgh-panel-opened & {
    max-height: 83px;
  }
}

.product-availability {
  color: $mine-shaft;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.5px;
  white-space: nowrap;
  text-transform: uppercase;
  @screen lg {
    font-size: 1rem;
  }
}

#clickAndCollectTab {
  .field-wrapper__item--input {
    @screen lg {
      max-width: 340px;
      margin-right: 7px;
    }
  }

  .btn {
    @screen lg {
      min-width: 145px;
    }
  }
}

.pac-container {
  z-index: 10010;
  @screen mdr {
    position: fixed !important;
  }
}

.selected-store {
  .book-appointment {
    display: none;
  }
}

.sgh-panel-opened {
  .book-appointment {
    margin-top: 8px;
    display: block;

    &__link {
      display: block;
    }

    &__desc {
      @extend .store-schedule__subtitle;
      margin-top: 5px;
      @screen mdr {
        display: block;
      }
    }
  }
}

.prediction {
  &-list {
    position: absolute !important;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.3);
    font-family: Arial, sans-serif;
    z-index: 10001;
    background-color: $white;
    border-radius: 0.2rem;
    border-top: 0.1rem solid $alto-5;

    &-express {
      border: 0.1rem solid $alto-5 !important;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      width: 85% !important;
      display: none;
      @screen md {
        width: 35% !important;
      }
      @screen lg {
        width: 25% !important;
      }
    }
  }

  &-item {
    overflow: hidden;
    padding: 0 4px;
    color: $emperor-2;
    font-size: 1.1rem;
    line-height: 3rem;
    white-space: nowrap;
    cursor: default;
    text-overflow: ellipsis;
    text-align: left;

    &-express {
      padding: 1.2rem 0 !important;
      color: $emperor !important;
      font-size: 1.4rem !important;
      line-height: 1.9rem;
      margin: 0 1.6rem;
      height: 4.2rem;

      &:not(:first-child) {
        border-top: 1px solid $alto;
      }

      &:hover {
        background-color: $white !important;
      }
    }

    &:hover {
      background-color: $alabaster-solid;
    }

    &:focus {
      background-color: $hawkes-blue;
      outline: none;
    }
  }

  &-icon {
    vertical-align: top;
    display: inline-block;
    width: 15px;
    height: 20px;
    margin-right: 7px;
    margin-top: 6px;
    background-image: url('https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png');
    background-size: 34px;

    &-express {
      width: 18px !important;
      height: 18px !important;
      margin-left: 0 !important;
      margin-top: 0 !important;
      @extend %icon-pin-locations;
      background-position: 50% 50% !important;
      background-size: 1.8rem !important;
    }

    &-marker {
      background-position: -1px -161px;
    }
  }

  &-main {
    color: $black;
    font-size: 1.3rem;
    padding-right: 3px;
  }
}
