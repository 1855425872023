.reset-tw-styles {
  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }

  *,
  *::before,
  *::after {
    border-width: 0;
    border-style: solid;
  }
}

pre {
  font-family: inherit;
  margin: 0;
  white-space: pre-wrap;
}