@import 'mixins';
@import '../../fonts';

.cms-banner-asymmetric-box {
  @include banner-picture;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;

  &--left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .picture,
    .sgh-tile .ratio-a {
      padding-top: calc(537 / 826) * 100%;
    }

    &__main-text {
      @include secondaryFont();
      width: 100%;
      font-size: 2rem;
      line-height: 2.9rem;
      padding: 0 3rem 7rem 3rem;

      p {
        margin-bottom: 0;
      }
    }

    &__subtitle {
      @include primaryFont();
      width: 100%;
      text-transform: uppercase;
      font-size: 1.2rem;
      padding: 0 3rem 7rem 3rem;
      margin-bottom: 0;
    }

    &__bigger-picture {
      width: 100%;

      &-image {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          background: rgb(0, 0, 0);
          height: 100%;
          width: 100%;
          opacity: 0;
          transition: opacity 0.5s ease-in;
        }
      }

      &:hover &-image::after {
        opacity: 0.4;
      }

      &-image.cms-tap-hold:hover .picture__image {
        transform: translateY(-50%);
      }
    }
  }

  &--right {
    width: calc(4 / 12) * 100%;
    display: none;
    justify-content: flex-end;

    .picture,
    .sgh-tile .ratio-a {
      padding-top: calc(523 / 496) * 100%;
    }
  }

  &--reversed {
    flex-direction: row-reverse;
  }

  &--reversed & {
    &--left {
      &__main-text {
        text-align: right;
      }

      &__subtitle {
        right: 0;
        text-align: right;
      }
    }

    &--right {
      justify-content: flex-start;
    }
  }

  @screen md {
    &--left {
      width: calc(7 / 12) * 100%;

      &__main-text {
        padding-left: 8rem;
        padding-right: 0;
        font-size: 4.2rem;
        line-height: 5rem;
      }

      &__subtitle {
        padding-left: 8rem;
        padding-right: 0;
      }
    }

    &--right {
      display: flex;
    }

    &__product {
      flex: 1 1 0%;
    }
  }
}
