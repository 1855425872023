.circles-banner {
  height: 26rem;
  @screen smr {
    height: 25rem;
  }
  &--swiper {
    & > div:first-child:nth-last-child(2) {
      margin: 0 3.6rem;
    }

    div:first-child:nth-last-child(6),
    div:first-child:nth-last-child(5) {
      margin: 0 1.2rem;
    }

    div:first-child:nth-last-child(4),
    div:first-child:nth-last-child(3) {
      margin: 0 2.4rem;
    }

    div:first-child:nth-last-child(6) ~ div,
    div:first-child:nth-last-child(5) ~ div {
      margin: 0 1.2rem;
    }

    div:first-child:nth-last-child(4) ~ div,
    div:first-child:nth-last-child(3) ~ div {
      margin: 0 2.4rem;
    }

    & > div:first-child:nth-last-child(2) ~ div {
      margin: 0 3.6rem;
    }

    @screen lgr {
      gap: 0;
    }
    @screen smr {
      width: 100%;
      gap: 0;
    }
  }
  &--slide {
    width: 8rem;
    height: 8rem;
    &-text {
      line-height: 1.14;
      margin-top: 0.8rem;
      max-width: 12rem;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  #sgh-slider .navigation-sgh-slide {
    margin-top: 4rem;
    .nav-hybrid {
      width: 0.72rem;
      height: 0.72rem;
      &.active-slide {
        width: 2.8rem;
        border-radius: 8rem;
      }
    }

    @screen smr {
      margin-top: 2.5rem;

      .nav-hybrid {
        height: 0.72rem;
        width: 0.72rem;
        margin: 1.5rem 0.5rem;
        background: black;
        opacity: 0.2;
        &.active-slide {
          opacity: 1;
          width: 2.8rem;
        }
      }
    }
  }
}
