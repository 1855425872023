.responsive-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -1.2rem;
  @screen lgr {
    margin: 0;
  }
  &__item {
    flex: 1;
    min-width: 33%;
    max-width: 66%;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    padding: 1.2rem;
    font-size: theme('fontSize.tiny');
    color: $emperor;
    @screen lgr {
      min-width: auto;
      max-width: 100%;
      padding: 0;
    }

    .media-holder {
      overflow: hidden;
      position: relative;
      padding-top: 75%;
      width: 100%;
      height: 0;
      @screen lgr {
        padding-top: 56.25%;
      }

      img,
      video {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    p {
      line-height: 1.8rem;
    }

    &:nth-child(3):nth-last-child(2) {
      flex-basis: 50%;
      max-width: 50%;
    }

    &:nth-child(4):nth-last-child(2) .media-holder,
    &:nth-child(5):last-child .media-holder {
      padding-top: 56.25%;
    }
  }
}
