.bright-promotion-banner {
  height: 36rem;
  padding-left: 5rem;
  padding-right: 4.4rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &__double {
    height: auto;
  }
  @screen xlr {
    height: auto;
  }
  @screen lgr {
    padding: 0;
  }
  &--container {
    @screen lgr {
      flex-direction: column;
      padding-bottom: 2.5rem;
    }
  }
  &--text {
    text-align: left;
    height: fit-content;
    @screen lgr {
      margin-top: 2.4rem;
      text-align: center;
    }
    &__description {
      margin-bottom: 1.8rem;
    }
    &__title {
      margin-bottom: 0.4rem;
      line-height: 1.13;
    }
    &__button {
      padding: 1.1rem 1.2rem;
      border: none;
    }
    &__subheader {
      margin-bottom: 0.4rem;
    }
    button{
      padding-left:1.2rem;
      padding-right:1.2rem;
    }
  }
  &--tiles {
    @screen xlr {
      flex-wrap: wrap;
      max-width: 655px;
      &-product {
        margin-bottom: 1.5rem;
        @screen lgr {
          margin-bottom: 0.8rem;
        }
      }
    }
    @screen lgr {
      justify-content: center;
      max-width: 50rem;
    }
    &-product {
      width: 23.2rem;
      height: 26.2rem;
      padding-bottom: 1.8rem;
      padding-left: 2.3rem;
      padding-right: 2.3rem;
      @screen lgr {
        padding-bottom: 1.4rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
      &-image {
        width: 100%;
        position: relative;
        padding-top: calc(104 / 185) * 100%;
        @screen lgr {
          padding-top: calc(82 / 147) * 100%;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
      &__big {
        height: 35rem;
        width: 31rem;
        padding-left: 1.1rem;
        padding-right: 1.1rem;
        &-image {
          width: 100%;
          position: relative;
          padding-top: calc(162 / 288) * 100%;
          @screen lgr {
            padding-top: calc(82 / 147) * 100%;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }
        }
      }
      background-color: $alabaster;
      img {
        margin-top: 6rem;
      }
      @screen lgr {
        margin-right: 0;
        width: 16.7rem;
        height: 18.2rem;
        img {
          width: 100%;
          margin-top: 3rem;
        }
      }
      &__discount {
        background-color: $medium-red-violet;
        -webkit-box-shadow: 0.4rem 0 0 0 $mulberry, 0.9rem 0 0 0 $carissma;
        box-shadow: 0.4rem 0 0 0 $mulberry, 0.9rem 0 0 0 $carissma;
        line-height: 1.83;
        color: $white;
      }
    }
  }
}
