/* stylelint-disable */

@import './reset';
@tailwind base;
@import './variables';
@import './fonts';
@import './icons';
@import './mixins/mixins';
@import 'utils/utils';
@import './main/main.bundle';
@import './pages/pages.bundle';
@import './checkout/checkout.bundle';
@import './helpers/blocks';
@import './cms/popup-newsletter';
@import './page-wrapper/page-wrapper';
@import './animations';
@import './header/header';
// get support
@import './get-support/get-support';
@import './cms/cms-modules/cms-modules';

@tailwind utilities;

@import './utils/ratio';
@tailwind components;


@responsive {
  .justify-between-unset {
    justify-content: unset;
  }
}

[data-app='vue'] {
  @extend .reset-tw-styles;
}
