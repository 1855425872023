#ieModal,
#error-modal,
#success-modal,
#MessageArea,
.message-area {
  display: none;
}

#ieModal {
  display: none;
  width: 406px !important;
  background-color: #fff;
  padding: 20px;
  h2 {
    font-size: 1.6rem;
    font-weight: bold !important;
    text-align: center;
    margin: 0;
  }
  li {
    float: left;
    margin: 0 40px 0 -3px;
    width: 74px;
  }
  p {
    font-size: 1.3rem;
    color: #000;
    margin: 0 0 10px 10px;
  }
  span {
    display: block;
    font-size: 0.9rem;
    left: -7px;
    position: relative;
    text-align: center;
    top: 10px;
    margin-left: 4px;
    width: 80px;
    font-style: italic;
  }
  ul {
    float: left;
    padding-left: 59px;
    list-style-type: none;
  }
}
