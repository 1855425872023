$mine-shaft: #222222;
$emperor-grey: #555555;
$mercury-white: #e6e6e6;
$alabaster-white: #f7f7f7;
$blaze-orange: #ff6c00;
$pumpkin-orange: #ff7714;
$apricot-orange: #ef8670;
$apricot-yellow: #ffd4a1;
$tulip-tree-yellow: #f2b140;
$double-spanish-white: #e8dbbd;
$hippie-blue: #539bb9;
$razzmatazz-pink: #d60b51;
$buttercup-yellow: #f6ae10;
$paris-daisy-yellow: #fff46e;
$apricot-pink: #ef8670;
$ligth-gray: #fcfcfc;
$red: #ff0000;
$white-smoke: #f5f5f5;
$white: #ffffff;
// CTA VERSIONS //

.btn.cms-cta,
.cms-cta {
  &-primary-mine-shaft {
    background: $mine-shaft;
  }

  &-primary-emperor-grey {
    background: rgba($mine-shaft, 0.6);
  }

  &-primary-mine-shaft,
  &-primary-emperor-grey {
    color: white;

    &:hover {
      background: $emperor-grey;
    }
  }

  &-secondary-emperor-grey {
    border-color: $emperor-grey;
    color: $emperor-grey;
  }

  &-secondary-blaze-orange {
    border-color: $blaze-orange;
    color: $blaze-orange;
  }

  &-secondary-emperor-grey,
  &-secondary-blaze-orange {
    background: transparent;

    &:hover {
      background: $mine-shaft;
      border-color: $mine-shaft;
      color: white;
    }
  }

  &-tertiary-emperor-grey,
  &-tertiary-blaze-orange {
    text-decoration: underline;
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    border: none;
  }

  &-tertiary-emperor-grey {
    color: $emperor-grey;

    &:hover {
      color: $emperor-grey;
      border: none;
      background: transparent;
    }
  }

  &-tertiary-blaze-orange {
    color: $blaze-orange;

    &:hover {
      color: $blaze-orange;
      border: none;
      background: transparent;
    }
  }
}

// BG COLORS //

.cms-custom-color-white-smoke {
  background-color: $white-smoke;
}

.cms-custom-color-black {
  background-color: black;
}
.cms-custom-color-mine-shaft {
  background-color: $mine-shaft;
}

.cms-custom-color-ligth-gray {
  background-color: $ligth-gray;
}

.cms-custom-color-emperor-grey {
  background-color: $emperor-grey;
}

.cms-custom-color-alabaster-white {
  background-color: $alabaster-white;
}

.cms-custom-color-pumpkin-orange {
  background-color: $pumpkin-orange;
}

.cms-custom-color-tulip-tree-yellow {
  background-color: $tulip-tree-yellow;
}

.cms-custom-color-double-spanish-white {
  background-color: $double-spanish-white;
}

.cms-custom-color-mercury-white {
  background-color: $mercury-white;
}

.cms-custom-color-apricot-orange {
  background-color: $apricot-orange;
}

.cms-custom-color-hippie-blue {
  background-color: $hippie-blue;
}

.cms-custom-color-razzmatazz-pink {
  background-color: $razzmatazz-pink;
}

.cms-custom-color-buttercup-yellow {
  background-color: $buttercup-yellow;
}

.cms-custom-color-paris-daisy-yellow {
  background-color: $paris-daisy-yellow;
}

.cms-custom-color-apricot-pink {
  background-color: $apricot-pink;
}

.cms-custom-color-white {
  background-color: $white;
}
.cms-custom-color-apricot-yellow {
  background-color: $apricot-yellow;
}

.cms-custom-color-red {
  background-color: $red;
}

// TEXT COLORS //

.cms-custom-text-color-black {
  color: black;
}
.cms-custom-text-color-mine-shaft {
  color: $mine-shaft;
}

.cms-custom-text-color-emperor-grey {
  color: $emperor-grey;
}

.cms-custom-text-color-alabaster-white {
  color: $alabaster-white;
}

.cms-custom-text-color-pumpkin-orange {
  color: $pumpkin-orange;
}

.cms-custom-text-color-tulip-tree-yellow {
  color: $tulip-tree-yellow;
}

.cms-custom-text-color-double-spanish-white {
  color: $double-spanish-white;
}

.cms-custom-text-color-mercury-white {
  color: $mercury-white;
}

.cms-custom-text-color-apricot-orange {
  color: $apricot-orange;
}

.cms-custom-text-color-hippie-blue {
  color: $hippie-blue;
}

.cms-custom-text-color-white {
  color: $white;
  &:hover {
    color: $white;
  }
}
