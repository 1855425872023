/* stylelint-disable */
@import '../mixins/mixins';

#hto {
  float: left;
  width: 100%;

  &.hto {
    margin: -50px 0 30px;
    border-bottom: 1px solid $concrete;
    padding-bottom: 50px;
  }

  #hto-header {
    position: relative;

    &.single {
      &.cdp {
        margin: 0 0 80px;
        color: $white;
        letter-spacing: 7px;

        h1 {
          @include primaryFont();
          font-size: 40px;
          font-weight: bold;
        }

        .back {
          top: 94px;
          left: 50px;
        }
      }

      img {
        position: relative;
        display: block;
        width: 100%;
        z-index: 0;
      }

      .ctas {
        top: 0;
        width: 400px;
        margin-left: -200px;

        h1 {
          margin: 50px 0 15px;
          line-height: 3.4rem;
          font: {
            size: 30px;
          }

          span {
            @include primaryFont();
            font-size: 1.4rem;
            display: block;
            margin: 10px 0 0;
          }
        }

        p {
          @include primaryFont();
          width: auto;
          line-height: 1.8rem;
        }
      }
    }

    #hto-sgh-logo {
      position: absolute;
      top: 50px;
      left: 50px;
      z-index: 5;

      img {
        width: auto;
      }
    }

    .back {
      @include primaryFont();
      font-weight: bold;
      position: absolute;
      top: 54px;
      left: 0;
      display: inline-block;
      width: 160px;
      letter-spacing: 0;
      z-index: 5;
      text-align: left;

      &:before {
        content: '\2039';
        margin: 0 3px 0 0;
      }
    }

    .redesignHTO-tag {
      position: absolute;
      bottom: -36px;
      left: 50%;
      margin: 0 0 0 -15px;
    }

    .images {
      position: relative;
      z-index: 0;

      img {
        float: left;
        display: block;
        width: 50%;
      }
    }

    .ctas {
      position: absolute;
      top: 60px;
      left: 50%;
      width: 310px;
      margin: 0 0 0 -155px;
      z-index: 1;
      text-align: center;

      .icon {
        display: inline-block;

        &.redesignHTO-hto-badge {
          margin: 0 0 40px;
        }
      }

      h1 {
        @include primaryFont();
        font-size: 1.4rem;
        font-weight: bold;
        margin: 0 0 30px;
        line-height: 2.4rem;
        letter-spacing: 7px;
      }

      a {
        @include primaryFont();
        font-size: 1.1rem;
        font-weight: bold;
        padding: 5px 25px;
        border-width: 3px;
      }

      p {
        @include primaryFont();
        font-size: 1rem;
        font-weight: bold;
        margin: 15px 0;
      }
    }
  }

  #how {
    text-align: center;
    clear: both;

    h2 {
      @include primaryFont();
      font-size: 2rem;
      font-weight: bold;
      margin: 80px 0 60px;
      letter-spacing: 7px;
    }

    h4 {
      @include primaryFont();
      font-size: 2rem;
      font-weight: bold;
      margin: 0 0 5px;
      letter-spacing: 7px;
    }

    .subheading {
      @include primaryFont();
      font-weight: bold;
      margin: 0 0 20px;
    }

    .video {
      margin: 0 0 60px;

      img {
        cursor: pointer;
      }
    }

    .redesignIcons-hto-logo-large {
      display: inline-block;
      margin: 0 0 20px;
    }

    .caption {
      @include primaryFont();
      font-size: 1.1rem;
      color: $dusty-gray-3;
    }

    .steps {
      float: left;
      width: 80%;
      padding: 0 10%;
      border: none;

      .step {
        float: left;
        width: 18%;
        margin: 0 0 0 4%;
        padding: 0 0 0 3%;

        &:first-child {
          margin: 0;
        }

        &.two {
          p {
            padding: 0 0 14% !important;
          }
        }

        &.three {
          p {
            padding: 0 0 14% !important;
          }
        }

        &.four {
          p {
            padding: 0 0 5% !important;
          }
        }

        .icon.redesignHTO-check {
          margin: 0 0 -7px;
        }

        p {
          @include primaryFont();
          font-size: 1.2rem;
          line-height: 1.8rem;
          text-transform: none;
        }

        .circle {
          @include rounded(100%);
          display: inline-block;
          width: 100%;
          height: 0;
          margin: 0 0 30px;
          padding: 0 0 100%;
          color: $white;
          background-color: $dark-gray;

          &.green {
            background-color: $ocean-green;
          }

          strong {
            @include primaryFont();
            font-size: 1.6rem;
            font-weight: bold;
            display: block;
            padding: 18% 0 8%;
            letter-spacing: 1px;
          }

          p {
            @include primaryFont();
            font-size: 1.6rem;
            padding: 0 0 11%;
            line-height: 2.2rem;
            letter-spacing: 2px;
            text-transform: uppercase;
          }

          .icon {
            display: inline-block;
          }
        }
      }
    }
  }

  #hto-button-bottom {
    margin-bottom: 80px;
  }

  #hto-footer {
    position: relative;
    clear: both;

    &.single {
      .col {
        width: 100%;

        .box {
          width: 50%;
          margin: 0 0 0 -25%;
          padding: 0 0 50px;

          h3 {
            @include primaryFont();
            font-size: 2.6rem;
            font-weight: bold;
            padding: 8% 0 15px;
            letter-spacing: 0.4em;
          }

          p {
            @include primaryFont();
            font-weight: bold;
            width: auto;
            line-height: 2rem;
            text-transform: uppercase;
          }
        }
      }

      .redesignHTO-hto-badge-small {
        position: absolute;
        bottom: 0;
        left: 50%;
        margin: 0 0 0 -49px;
        z-index: 2;
      }
    }

    .redesignHTO-tag {
      position: absolute;
      top: 0;
      left: 50%;
      margin: 0 0 0 -15px;
      z-index: 2;
    }

    .redesignHTO-hto-badge {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin: 0 0 0 -74px;
      z-index: 2;
    }

    .col {
      position: relative;
      float: left;
      width: 50%;

      img {
        position: relative;
        display: block;
        width: 100%;
        z-index: 0;
      }

      .box {
        position: absolute;
        top: 15%;
        left: 50%;
        width: 384px;
        margin: 0 0 0 -192px;
        padding: 0 0 12%;
        z-index: 1;
        background-color: $white;
        text-align: center;

        h3 {
          padding: 20% 0 5%;
          font-size: 2.2rem;
          line-height: 3rem;
        }

        p {
          width: 190px;
          margin: 0 auto;
          padding: 0 0 3%;
          font-size: 1.2rem;
          text-transform: none;
        }

        a {
          border-width: 3px;
        }
      }
    }
  }

  &.cdp {
    text-align: center;

    .heading {
      @include primaryFont();
      font-weight: bold;
    }

    .product-container {
      position: relative;
      float: left;
      width: 90%;
      margin: 80px 0;
      padding: 0 0 0 10%;
      clear: both;

      &.even {
        .product {
          float: right;
        }
      }

      &.third {
        position: relative;
        margin: -50px 0 0;
      }

      &.showingRotator {
        .p3dzoom-image {
          display: block;
        }

        .product-image {
          display: none;
        }

        .instructionText {
          position: absolute;
          bottom: 0;
          left: 50%;
          display: block !important;
          margin: 0 0 0 -84px;
          opacity: 1 !important;
          z-index: 5000;
        }
      }

      .product {
        position: relative;
        float: left;
        width: 59%;
        margin: 80px 0 0;

        img {
          width: 100%;
        }
      }

      .box {
        float: left;
        width: 21%;
        height: 509px;
        padding: 0 5%;
        background-color: $concrete;

        .arrow {
          position: relative;
          width: 100%;
          margin: -25px auto 30px;
          background-color: $ocean-green;

          &:before,
          &:after {
            content: '';
            position: absolute;
            top: -18px;
            width: 50%;
            height: 18px;
          }

          &:before {
            left: 1px;
            background: linear-gradient(to right bottom, transparent 50%, $ocean-green 50%);
          }

          &:after {
            right: 0;
            background: linear-gradient(to left bottom, transparent 50%, $ocean-green 50%);
          }

          p {
            @include primaryFont();
            font-weight: bold;
            padding: 10px 5% 20px;
            color: $white;
            line-height: 1.6rem;
          }
        }

        p,
        span {
          display: block;
          margin: 0 0 10px;
          letter-spacing: 3px;
          text-decoration: none;
        }

        .brand {
          @include primaryFont();
          font-size: 1.6rem;
          font-weight: bold;
        }

        .style {
          @include primaryFont();
          font-weight: bold;
        }

        .sale-price {
          @include primaryFont();
          font-size: 1rem;
          font-weight: bold;

          &#offerPrice {
            display: none;
          }
        }

        .description {
          @include primaryFont();
          padding: 10% 0;
          line-height: 2rem;
          letter-spacing: 0;
          text-transform: none;
        }

        .reviews {
          width: 150px;
          margin: 0 auto;

          .catalog-product-rating {
            float: left;
          }
        }
      }
    }

    .buttons {
      a {
        position: relative;
        display: inline-block;

        &.first {
          margin: 0 10px 0 0;
        }

        &.active {
          span {
            &.copy {
              display: inline-block;
            }
          }
        }

        span {
          &.copy {
            @include primaryFont();
            font-size: 1rem;
            font-weight: bold;
            position: absolute;
            bottom: -20px;
            left: 15px;
            display: none;
            color: $dusty-gray-3;
          }
        }
      }
    }

    .p3dzoom-image,
    #zoomSlider {
      display: none;
    }

    .p3dzoom-image {
      width: 100% !important;
      height: auto !important;
    }

    .fit-overlay {
      position: absolute;
      top: -30px;
      left: 0;
      display: none;
      width: 100%;
      height: 100%;
      z-index: 5000;
      background-color: rgba(255, 255, 255, 0.7);

      .close {
        @include transition(opacity, 0.5s);
        position: absolute;
        top: -16px;
        right: 0;
        display: block;
        width: 100px;
        height: 50px;
        color: $silver-chalice;
        line-height: 5rem;
        opacity: 0;
        z-index: 9999;
        background-color: $dark-gray;
        text-align: center;
        font: {
          size: 10px;
        }

        span {
          position: relative;
          top: 3px;
          display: inline-block;
          margin: 0 0 0 8px;
        }
      }

      &.open {
        .close {
          @include animation(showClose, 0.5s);
        }
      }

      .instructions {
        @include primaryFont();
        font-size: 1.1rem;
        font-weight: bold;
        position: absolute;
        bottom: -30px;
        left: 50%;
        margin: 0 0 40px -3%;
        color: $dark-gray;
        text-indent: 0;
      }

      img {
        width: 100%;
      }

      .measurements {
        position: absolute;
        top: 68px;
        right: 0;
        width: 75%;

        div {
          position: relative;
          float: left;
          z-index: 1;

          &.bridge {
            width: 10%;
          }

          &.eye {
            width: 50%;

            p {
              width: 15%;
            }

            .bullet {
              float: right;

              &.first {
                float: left;
              }
            }
          }

          &.temple {
            width: 30%;

            p {
              width: 20%;
            }

            .bullet {
              float: right;
            }
          }

          &.line {
            position: absolute;
            top: 33px;
            left: 4px;
            width: 88%;
            height: 1px;
            z-index: 0;
            background-color: $dusty-gray-3;
          }

          &.bullet {
            float: none;
            display: inline-block;
            font: {
              size: 34px;
            }

            &:before {
              content: '\2022';
            }
          }

          h4 {
            margin: 0 0 10px;
            color: $dusty-gray-3;
            font: {
              size: 20px;
            }
          }

          p {
            display: inline-block;
            width: 66%;
            margin: 0 auto;
            background-color: $white;
            font: {
              size: 18px;
            }
          }
        }
      }
    }

    .info {
      display: none;
      padding: 10% 0;
      line-height: 2rem;

      li {
        @include primaryFont();
        color: $dark-gray;
        list-style-type: none;
        text-transform: none;

        &.heading {
          @include primaryFont();
          font-weight: bold;
          color: $dark-gray;
          text-transform: uppercase;
        }

        span {
          display: inline !important;
          color: $dove-gray;
          letter-spacing: 0 !important;
        }

        a {
          font: {
            weight: bold;
          }

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }

    .review-overlay {
      position: absolute;
      top: -44px;
      left: 0;
      display: none;
      overflow: scroll;
      width: 100%;
      height: 553px;
      z-index: 9002;
      text-align: center;
      background-color: $concrete;

      > a {
        position: absolute;
        top: 15px;
        right: 15px;
        color: $dark-gray;
        z-index: 1;
        font: {
          size: 10px;
        }

        span {
          position: relative;
          top: 1px;
          float: right;
          margin: 0 0 0 5px !important;
        }
      }

      #BVRRContainer {
        span {
          @include primaryFont();
          font-size: 1rem;
          display: inline-block;
          margin: 0;
          color: $dark-gray;
          letter-spacing: 0;
          text-transform: uppercase;

          &.BVRRNickname {
            @include primaryFont();
            font-size: 1rem;
            font-weight: bold;
          }

          &.BVRRLabel {
            margin: 0 5px 0 0;
          }

          &.BVRRValue {
            @include primaryFont();
            font-size: 1rem;
            font-weight: bold;
          }

          &.BVDIValue {
            @include primaryFont();
            font-size: 1rem;
            font-weight: bold;
            margin: 0 3px 0 0;
          }
        }

        .BVDI_FVVote {
          span,
          a {
            @include primaryFont();
            font-size: 1rem;
            font-weight: bold;
            margin: 0 3px 0 0;
            text-decoration: underline;

            &:hover,
            &:focus {
              text-decoration: none;
            }
          }
        }

        .BVDIPrefix {
          margin: 0 2px 0 0;
        }

        .BVRRPagerArrows,
        .BVDI_CO {
          display: none;
        }

        .BVRRDisplayContentFooter {
          width: 165px;
          margin: 0 0 0 521px;
          border: none;
        }

        .BVRRNextPage,
        .BVRRPreviousPage {
          a {
            @include primaryFont();
            font-size: 1rem;
            font-weight: bold;
            background: none;
            color: $dark-gray;
            font-style: italic;
            text-decoration: underline;

            &:hover,
            &:focus {
              text-decoration: none;
            }
          }
        }

        .BVDILinkSpan {
          @include primaryFont();
          font-size: 1rem;
          font-weight: bold;
          margin: 0 3px 0 0;
          text-decoration: underline;

          &:hover,
          &:focus {
            text-decoration: none;
          }
        }

        .BVDI_FVReportLinkInappropriate {
          left: 195px;
        }

        .BVRRPager {
          background: none;
        }

        .BVRRReviewTextParagraph {
          span {
            @include primaryFont();
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.8rem;
            text-transform: uppercase;
          }
        }

        .BVRRReviewTextContainer {
          width: 560px;
        }

        .BVRRReviewDisplayStyle3Summary {
          text-align: left;
        }

        .BVRRQuickTakeTitle {
          float: none;
          width: 260px;
          margin: 0 auto 20px !important;
          text-align: center;
        }

        .BVRRTrustMarkInner {
          display: none;
        }

        .BVRRSummaryLeft {
          width: 100%;
          margin: 0 0 20px;
        }

        .BVRRSummaryRight {
          float: none;
          width: 360px;
          margin: 0 auto;
          clear: both;
        }

        .BVRRQuickTakeSection {
          overflow: hidden;
          margin: 0 0 -40px;
          border: none;
        }

        .BVRRDisplayContent {
          margin: -80px 0 0;
          border: none;

          &.BVRRNoContent {
            margin: 0;
          }
        }

        #BVRRDisplayContentSortPrefixID,
        .BVRRQuickTakeSubHeader,
        .BVRRUserNicknameSuffix,
        .BVRRQuickTakeProductName,
        .BVRRReviewTitleContainer {
          display: none;
        }

        .BVRRRatingSummaryStyle2 {
          width: 460px;
          margin: 0 auto;
          text-align: center;
        }

        #BVRRRatingSummaryLinkWriteID {
          margin: 0 0 0 40px;

          a {
            position: inherit;
            margin: 10px 0 0;
            padding: 14px 22px;
            border: 2px solid $dark-gray;
            background: none;
            color: $dark-gray;
            font-size: 1.1rem;
          }
        }

        .BVRRContextDataContainer {
          margin: 0;
        }

        .BVRRSortSelectWidget {
          overflow: hidden;
          width: 195px;

          select {
            width: 217px;
            height: 50px;
            padding: 15px 25px;
            background: $white url('../assets/images/redesign-icons/arrow-inactive.png') 85% 50%
              no-repeat;
            color: $mine-shaft-2;
            letter-spacing: 0;
            outline: none;
            text-transform: uppercase;
          }
        }

        .BVRRReviewDisplayStyle3Content {
          text-align: left;
        }

        .BVRRReviewDisplayStyle3Main {
          width: 450px;
        }

        .BVRRReviewDisplayStyle3 {
          width: 710px;
          margin: 0 auto;
        }
      }
    }

    #how-overlay {
      position: absolute;
      top: -44px;
      left: 0;
      display: none;
      width: 100%;
      z-index: 9002;
      text-align: center;
      background-color: $dark-gray;

      a {
        position: absolute;
        top: 15px;
        right: 15px;
        color: $silver-chalice;
        z-index: 1;
        font: {
          size: 10px;
        }

        span {
          position: relative;
          top: 1px;
          float: right;
          margin: 0 0 0 20px;
        }
      }

      img {
        height: 100%;
      }
    }
  }

  &.clp {
    #collection-controls {
      text-align: center;
      background-color: $concrete;
      line-height: 13rem;

      a {
        @include primaryFont();
        font-size: 1.8rem;
        font-weight: bold;
        margin: 0 0 0 50px;
        color: $dusty-gray-3;

        &:first-child {
          margin: 0;
        }

        &.selected,
        &:hover,
        &:focus {
          color: $dark-gray;
          border-bottom: 6px solid $dark-gray;
        }
      }
    }

    .container {
      clear: both;
      float: left;
      width: 100%;

      &.even {
        .product {
          float: right;

          span {
            right: auto;
            left: 0;
            border-left: 15px solid $white;
            border-right: none;
          }
        }
      }

      .product {
        position: relative;
        float: left;
        width: 50%;

        img {
          display: block;
          width: 100%;
          height: auto;
        }

        span {
          position: absolute;
          top: 50%;
          right: 0;
          left: auto;
          width: 0;
          height: 0;
          margin: -7px 0 0;
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          border-right: 15px solid $white;
        }
      }

      .images {
        position: relative;
        float: left;
        width: 34%;
        padding: 7% 8% 0;
        text-align: center;

        .arrow-badge {
          position: absolute;
          top: 0;
          left: 50%;
          width: 65px;
          margin: 0 0 0 -33px;
        }

        h2 {
          @include primaryFont();
          font-size: 1.6rem;
          font-weight: bold;
          margin: 0 0 10px;

          + p {
            @include primaryFont();
            font-size: 1.1rem;
            font-weight: bold;
            width: 220px;
            margin: 0 auto -15px;
            line-height: 2rem;
          }
        }

        .item {
          width: 50%;
          min-height: 165px;

          p {
            margin: 0 0 0 5px;

            &.brand {
              margin: -15px 0 5px;
              font: {
                size: 12px;
              }
            }

            &.style {
              margin: 0 0 5px;
              @include primaryFont();
            }

            &.price {
              margin: 0 0 5px;
              text-decoration: none;
              font: {
                size: 12px;
              }

              &.sale-price {
                display: none;
              }
            }
          }

          .badge,
          .catalog-product-rating,
          .gray {
            display: none;
          }
        }

        .cart-button {
          clear: both;
        }

        .addToCart {
          margin: 50px auto 15px;
        }

        .view {
          @include primaryFont();
          font-size: 1.1rem;
          font-weight: bold;
          display: inline-block;
          text-decoration: underline;

          &:focus,
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
