@import '../../mixins/mixins';
@import '../../variables';
/* stylelint-disable */

sgh-hero-banner,
.skeleton-hero-banner {
  position: relative;
  &.l,
  &[banner-size='l'] {
    .skeleton {
      background-color: $white-smoke;
      @screen md {
        aspect-ratio: 144/59;
      }
      @screen mdr {
        height: 56rem;
      }
    }
    @screen mdr {
      &.text-outside,
      &[\:\text-outside='true'] {
        .skeleton {
          height: auto;

          &::before {
            height: 40rem;
            content: '';
            display: block;
          }

          &--text-brandname {
            top: 36.7rem;
          }
        }
      }
    }
  }
  &.m,
  &[banner-size='m'] {
    .skeleton {
      background-color: $white-smoke;
      @screen md {
        aspect-ratio: 72/23;
      }
    }
    @screen mdr {
      &.text-outside,
      &[\:\text-outside='true'] {
        .skeleton {
          height: auto;

          &::before {
            height: 32rem;
            content: '';
            display: block;
          }

          &--text-brandname {
            top: 28.7rem;
          }
        }
      }
    }
  }
  &.s,
  &[banner-size='s'] {
    .skeleton {
      background-color: $white-smoke;
      .skeleton--text-module {
        padding: 2.4rem 2rem;
      }
      @screen md {
        aspect-ratio: 9/2;
      }
      @screen mdr {
        height: 24rem;
      }
    }
    @screen mdr {
      &.text-outside,
      &[\:\text-outside='true'] {
        .skeleton {
          height: auto;

          &::before {
            height: 24rem;
            content: '';
            display: block;
          }

          .skeleton--text-module {
            padding: 1.6rem;
          }

          &--text-brandname {
            top: 20.7rem;
          }
        }
      }
    }
  }
  &.top-left,
  &[text-position='top-left'] {
    .skeleton {
      .skeleton--text-module {
        top: 0;
        left: 0;
        align-items: flex-start;
      }
    }
  }
  &.top-right,
  &[text-position='top-right'] {
    .skeleton {
      .skeleton--text-module {
        width: 100%;
        top: 0;
        align-items: flex-end;
      }
    }
  }
  &.center-left,
  &[text-position='center-left'] {
    .skeleton {
      .skeleton--text-module {
        top: 0;
        left: 0;
        height: 100%;
        align-items: flex-start;
        justify-content: center;
      }
    }
  }
  &.center-center,
  &[text-position='center-center'] {
    .skeleton {
      .skeleton--text-module {
        width: 100%;
        height: 100%;
        top: 0;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &.center-right,
  &[text-position='center-right'] {
    .skeleton {
      .skeleton--text-module {
        width: 100%;
        height: 100%;
        top: 0;
        align-items: flex-end;
        justify-content: center;
      }
    }
  }
  &.bottom-left,
  &[text-position='bottom-left'] {
    .skeleton {
      .skeleton--text-module {
        top: 0;
        left: 0;
        height: 100%;
        align-items: flex-start;
        justify-content: flex-end;
      }
    }
  }
  &.bottom-center,
  &[text-position='bottom-center'] {
    .skeleton {
      .skeleton--text-module {
        width: 100%;
        height: 100%;
        top: 0;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
  &.bottom-right,
  &[text-position='bottom-right'] {
    .skeleton {
      .skeleton--text-module {
        width: 100%;
        height: 100%;
        top: 0;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }
  &.top-center,
  &[text-position='top-center'] {
    .skeleton {
      .skeleton--text-module {
        width: 100%;
        top: 0;
        align-items: center;
      }
    }
  }
  .skeleton {
    list-style: none;
    .skeleton--buttons-video {
      display: flex;
      gap: 1.6rem;
      position: absolute;
      left: 4rem;
      bottom: 2.6rem;
      .skeleton--button-video {
        width: 3.6rem;
        height: 3.6rem;
        margin-top: 2.5rem;
        border-radius: 100%;
        @include skeleton-animation($dark: true);
      }
      @media (min-width: 1800px) {
        left: 14rem;
      }
    }
    .skeleton--text-brandname {
      width: 6.4rem;
      height: 0.87rem;
      @include skeleton-animation($dark: true);
      position: absolute;
      right: 4.2rem;
      bottom: 3rem;
      @media (min-width: 1800px) {
        right: 15rem;
      }
    }
  }
  .skeleton--text-module {
    width: fit-content;
    padding: 9rem 4rem;
    position: absolute;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    top: 0;
    @media (min-width: 1800px) {
      padding: 9rem 14.2rem 9rem 15rem;
    }
    @screen mdr {
      padding: 9rem 2rem;
      width: 100%;
    }
    .skeleton--text {
      margin-bottom: 0.4rem;
      width: 48rem;
      margin: 0.8rem 0;
      height: 1rem;
      @include skeleton-animation($dark: true);
      @screen mdr {
        width: 100%;
      }
    }
    .skeleton--h2 {
      margin-bottom: 0.4rem;
      width: 48rem;
      margin: 0.8rem 0;
      height: 3.5rem;
      @include skeleton-animation($dark: true);
      @screen mdr {
        width: 100%;
      }
    }
    .skeleton--buttons {
      display: flex;
      gap: 1.6rem;
      @screen mdr {
        flex-wrap: wrap;
      }
    }
    .skeleton--button {
      width: 14.4rem;
      margin: 0.8rem 0;
      height: 4rem;
      border-radius: 10rem;
      margin-top: 1.6rem;
      @include skeleton-animation($dark: true);
      @screen mdr {
        width: 11rem;
      }
    }
  }

  @screen mdr {
    &.text-outside,
    &[\:\text-outside='true'] {
      .skeleton {
        .skeleton--text-module {
          position: relative;
          top: auto;
          bottom: 0;
          background-color: $white;
          height: min-content;
          padding: 1.6rem 2rem 2.4rem;
          gap: 0.8rem;

          .skeleton {
            &--h2,
            &--text,
            &--button {
              @include skeleton-animation;
            }

            &--h2 {
              margin: 0;
              height: 3.2rem;
            }

            &--text {
              margin: 0;
              height: 1.6rem;
              margin: 0.2rem 0;

              &:first-of-type {
                height: 1.2rem;
                margin: 0.3rem 0;
              }
            }

            &--buttons {
              margin: 1.3rem 0 0.4rem;
              gap: 1.2rem;
              justify-content: center;
              flex-grow: 1;
              flex-wrap: wrap;
              column-gap: 7%;
            }

            &--button {
              margin: 0;

              &:not(.tertiary) {
                flex-basis: 46.5%;
              }

              &.tertiary {
                height: 1.5rem;
              }
            }
          }
        }

        &--text-brandname {
          right: 2.4rem;
          bottom: auto;
          padding-bottom: 1.6rem;
        }
      }
    }
  }
}
