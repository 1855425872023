/* stylelint-disable */
@import '../mixins/mixins';

.cart-container {
  width: 71%;
  margin: 0 0 0 15%;
}

.continue {
  @include primaryFont();
  font-size: 1rem;
  font-weight: bold;

  &:before {
    content: '\2039';
    margin: 0 3px 0 0;
  }
}

.steps {
  position: relative;
  width: 100%;
  margin: 0 0 30px;
  padding: 18px 0 30px;
  clear: both;
  border-bottom: 1px solid $concrete;

  .steps-container {
    width: 270px;
    margin: 0 auto;
  }

  .border {
    position: absolute;
    top: 32px;
    left: 50%;
    width: 165px;
    height: 2px;
    margin: 0 0 0 -82px;
    z-index: 0;
    background-color: $dark-gray;
  }

  a {
    @include primaryFont();
    position: relative;
    display: inline-block;
    width: 83px;
    z-index: 1;
    text-align: center;
    font-size: 1rem;

    &[href^="https://"]
    {
      .circle {
        height: 15px;
        padding: 7px 0 0;
      }

      .number {
        display: none;
      }

      .icon {
        display: block;
      }
    }

    .icon {
      display: none;
      margin: 0 auto;
    }

    .circle {
      @include rounded(24px);
      float: left;
      display: block;
      width: 22px;
      height: 18px;
      margin: 0 auto 4px 40px;
      padding: 4px 0 0;
      border: 2px solid $dark-gray;
      background-color: $white;
    }

    &.enabled {
      span {
        color: $white;
        background-color: $dark-gray;

        &.copy {
          @include primaryFont();
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }

    span {
      &.copy {
        position: absolute;
        top: 28px;
        left: 50%;
        display: block;
        width: 100%;
        margin: 0 0 0 -30px;
        background: none;
        color: $dark-gray;
        line-height: 1.1rem;
        text-align: center;
      }
    }
  }
}

#header {
  &.cart {
    + #page {
      #main_content_wrapper {
        margin-left: 15%;
      }
    }

    .cart-container {
      height: 87px;

      #header_logo {
        float: left;
        padding: 30px 0 0;
      }

      .continue {
        float: right;
        margin: 38px 46px 0 0;
      }

      h1 {
        @include primaryFont();
        position: absolute;
        left: 50%;
        width: 170px;
        margin: 0 0 0 -60px;
        color: $dark-gray;
        line-height: 88px;
        text-align: center;
        font-size: 1.6rem;
        font-weight: bold;
      }
    }

    #free-shipping {
      @include primaryFont();
      height: 58px;
      color: $white;
      line-height: 5.8rem;
      letter-spacing: 0.15em;
      background-color: $dark-gray;
      text-align: center;
      font-size: 1.6rem;
      font-weight: bold;

      span {
        font-size: 1rem;
      }
    }

    &.confirm {
      height: 355px;
      margin: 0 0 50px;
      color: $white;
      background-color: $mine-shaft-2;
      text-align: center;

      #free-shipping {
        height: 50px;
        line-height: 5rem;

        font: {
          size: 10px;
        }
      }

      h1 {
        @include primaryFont();
        margin: 0 0 40px;
        padding: 50px 0 0;
        line-height: 3.4rem;
        font-size: 3.4rem;
        font-weight: bold;
      }

      h2 {
        @include primaryFont();
        font-size: 1.4rem;
        font-weight: bold;
        margin: 0 0 40px;
      }

      p {
        @include primaryFont();
        margin: 0 0 10px;
      }
    }
  }
}

#cart-body {
  position: relative;
  overflow: hidden;

  &.has-steps {
    #cart-left {
      #giftCard {
        #gcbalance_display {
          margin: 0 0 20px;
          font-weight: bold;
          clear: both;
        }
      }

      &.logged {
        padding-top: 36px;
      }
    }

    #total-breakdown {
      padding-top: 116px;
    }
  }

  &.review {
    h3 {
      margin: 0 0 10px;
    }
  }

  &.signin {
    h3 {
      margin: 0 0 25px;
      font: {
        size: 13px;
      }
    }

    #cart-left {
      position: relative;
      width: 48%;
      padding-right: 4%;

      p {
        @include primaryFont();
        font-size: 1.4rem;
        margin: 0 0 10px;
        line-height: 1.8rem;
        text-transform: none;
      }

      li {
        @include primaryFont();
        font-size: 1.1rem;
        margin: 0 0 0 14px;
        line-height: 1.8rem;
        list-style-type: disc;
        text-transform: none;
      }

      .red-button {
        margin: 50px 0 20px;

        &.register {
          margin-top: 0;
        }
      }

      input[type='text'],
      input[type='password'] {
        display: block;
        width: 247px;
        height: 20px;
        margin: 0 0 5px;
        border: none;
        background-color: $white;
      }

      .select-wrapper {
        width: 280px;
        margin: 0 0 10px;

        select {
          width: 323px;
          background-position: 82% 50%;
        }
      }
    }

    #total-breakdown {
      float: left;
      padding: 115px 0 2000px 10%;
      background: none;

      label {
        display: block;
        margin: 0 0 5px;
        font: {
          size: 13px;
        }
      }

      .forgot {
        @include primaryFont();
        margin: 0 0 30px;
        color: $dusty-gray-4;
        clear: both;
        font-size: 1.4rem;
        font-style: italic;
      }

      input[type='text'],
      input[type='password'] {
        display: block;
        width: 247px;
        height: 20px;
        margin: 0 0 5px;
        background-color: $white;
      }

      .red-button {
        margin: 0 0 30px;
      }
    }
  }

  &.thanks {
    min-height: 985px;

    &.hto {
      min-height: 1300px;
    }
  }

  .white-sep {
    position: absolute;
    top: 119px;
    right: 0;
    width: 1px;
    height: 317px;
    background-color: $white;
  }

  h2 {
    span {
      @include primaryFont();
      float: right;
      font-size: 1.1rem;

      &.cartcount {
        float: none;
      }
    }

    &.saved-info {
      margin: 0 0 5px;

      + .section-header {
        margin: 0 0 10px !important;
      }
    }
  }

  #confirm-wrapper {
    padding: 0 50px;
    clear: both;

    .steps {
      width: 100% !important;
      padding: 0 !important;
    }
  }

  .confirm-container {
    position: relative;
    clear: both;

    &.guest {
      border-bottom: 1px solid $concrete;
      padding-bottom: 50px;
      margin: 0 0 30px;

      .right {
        bottom: 50px;
      }
    }

    .right {
      position: absolute;
      right: 30px;
      bottom: 0;

      &.red-button {
        padding: 12px 41px;
      }
    }

    h3 {
      @include primaryFont();
      margin: 0 0 25px;
      font-weight: bold;
      font-size: 1.4rem;
    }

    h4 {
      @include primaryFont();
      margin: 0 0 25px;
      line-height: 1.4rem;
      font-weight: bold;
    }

    li {
      @include primaryFont();
      line-height: 1.8rem;
      list-style-type: none;
      font-size: 1.1rem;
    }
  }

  #cart-left {
    #error-container {
      position: relative;
      top: -65px;
      padding: 20px 0;
    }

    .shipping_method_content {
      .select-wrapper {
        &.gray {
          min-width: 290px;
        }
      }
    }

    p {
      font: {
        size: 10px;
      }
      text-transform: none;

      &.giftcard {
        @include primaryFont();
        font-size: 1.2rem;
        font-weight: bold;

        strong {
          display: inline-block;
          margin: 0 20px;
        }

        .remove {
          @include primaryFont();
          color: $dusty-gray-3;
          font-size: 1rem;

          .icon {
            position: relative;
            top: 3px;
            display: inline-block;
          }
        }
      }

      &.remaining {
        @include primaryFont();
        font-size: 1.2rem;
        font-weight: bold;
      }
    }

    label {
      display: block;
      margin: 0 0 3px;

      &.g-card-label {
        margin: 0 0 40px;
        padding-left: 20px;
      }

      .redesignIcons-gift-card {
        float: left;
        margin-right: 10px;
      }

      .gift-card-text {
        font-size: 1.4rem;
        line-height: 2.4rem;
      }

      &.register {
        @include primaryFont();
        color: $mine-shaft-2;
        font-size: 1rem;
        font-weight: bold;
      }

      span {
        @include primaryFont();
        font-size: 1rem;
      }
    }

    .message {
      p {
        padding: 10px 0 0 20px;
      }
    }

    .BillingMethodTypeDisplay {
      width: 100%;
      margin: 0 0 20px;
      clear: both;

      &.active {
        + .custom-check {
          margin-top: -160px;
        }

        button {
          margin-top: 80px;
        }
      }

      #GiftCardCheckBalance_1 {
        margin: 0 20px 0 0;
      }

      .add-margin {
        margin-top: 5px;
      }
    }

    .section-header-generic-error {
      float: left;
      width: 100%;
      clear: both;
      margin: 0 0 50px;
    }

    .section-header {
      float: left;
      width: 100%;
      clear: both;
      margin: 0 0 50px;

      p {
        float: left;
        color: $mine-shaft-2;
        font: {
          size: 10px;
        }

        &.required-note {
          float: right;

          &.negative {
            margin: -26px 0 0;
          }

          &.star {
            color: $valencia;
          }
        }

        &.second {
          display: none;
        }
      }
    }

    .heading {
      @include primaryFont();
      padding: 30px 0;
      color: $boulder;
      border-bottom: 1px solid $concrete;
      clear: both;
      font-size: 20rem;
      font-weight: bold;

      a {
        float: right;
        color: $boulder;
        font: {
          size: 13px;
        }
      }
    }

    input[type='text'],
    input[type='password'],
    input[type='tel'] {
      width: 92%;
      padding: 15px 4%;
      border: 1px solid $gray;
      background-color: $white;
      border-radius: 0;
    }

    text-area {
      border: 1px solid $dark-gray;
    }

    #hto-item-container {
      position: relative;
      float: left;
      width: 100%;
      padding: 0 5px 5px;
      background-color: $concrete;
      clear: both;

      .redesignIcons-hto-logo-small {
        float: left;
        margin: 0 30px 10px 25px;
      }

      h3 {
        margin: 20px 0 5px;
      }

      .edit {
        @include primaryFont();
        text-decoration: underline;
        font-size: 1.1rem;
        font-weight: bold;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }

      .delete-link {
        @include primaryFont();
        position: absolute;
        top: 23px;
        right: 23px;
        text-align: center;
        font-size: 0.9rem;

        span {
          margin: 0 auto 5px;
        }
      }

      #hto-items {
        float: left;
        width: 96%;
        padding: 0 2%;
        background-color: $white;

        .row {
          width: 96%;
          padding: 20px 4% 0 0;
          min-height: 108px;
        }
      }
    }

    .hto-copy {
      width: 570px;
      margin: 0 0 20px;
      clear: both;

      p {
        @include primaryFont();
        margin: 0 0 10px;
        color: $mine-shaft-2;
        letter-spacing: 0.07em;
        padding-top: 30px;
        font-weight: bold;

        &.standard {
          @include primaryFont();
        }
      }

      label {
        @include primaryFont();
      }

      .custom-check {
        margin: 0 0 5px;
      }
    }

    .cart-form {
      padding: 80px 0 0;
      .row {
        float: left;
        width: 100%;
        clear: both;
        border-bottom: 1px solid $gallery;
        margin: 0 0 10px;
        min-height: 128px;

        &:last-of-type {
          border: none;
        }
      }

      .product {
        float: left;
        width: 57%;

        .ship-image {
          float: left;
          width: 52%;

          img {
            width: 95%;
          }
        }

        .product-details {
          position: relative;
          float: left;
          width: 48%;
          margin-top: 7px;
        }

        .brand {
          @include primaryFont();
          font-size: 1rem;
          font-weight: bold;
          margin: 0 0 5px;
        }

        .style,
        .color {
          font: {
            size: 10px;
          }
        }

        .wrap {
          width: 190px;
          padding: 30px 0 0;

          label {
            @include primaryFont();
            font-size: 1.1rem;
          }
        }
      }

      .price {
        @include primaryFont();
        font-size: 1.4rem;
        float: left;
        width: 10%;

        /* padding:20px 0 0; */
        padding: 20px 20px 0 0;
        color: $mine-shaft-2;
        text-align: right;
      }

      .count {
        position: relative;
        float: left;
        width: 20%;
        padding: 20px 0 0;

        input {
          @include rounded(0);
          @include primaryFont();
          font-size: 1.6rem;
          float: left;
          width: 23px;
          padding: 5px;
          border: 1px solid $dark-gray;
          color: $dark-gray;
          text-align: center;
          background-color: $white;

          &.ccRequired {
            + .delete-link {
              + .update {
                display: block !important;
              }
            }
          }
        }

        .qty-error {
          + .saved {
            display: none;
          }
        }

        a {
          position: absolute;
          bottom: -70px;
          left: -30px;
          float: left;
          margin: -15px 0 0 29px;
          color: $dusty-gray-4;
          font: {
            size: 11px;
          }

          span {
            margin: 0 auto 3px;
          }

          &.update {
            position: absolute;
            bottom: -30px;
            left: -27px;
          }

          &.saved {
            position: absolute;
            top: 60px;
            left: -3px;
            color: $ocean-green;
          }
        }
      }

      .summary {
        @include primaryFont();
        float: left;
        font-size: 1.4rem;
        font-weight: bold;
        width: 9%;
        text-align: right;
        padding: 25px 0 0;
      }

      .itemspecs {
        @include primaryFont();
        position: relative;
        float: right;
        font-size: 1rem;
        font-weight: bold;
        width: 85px;
        color: $alizarin-crimson;
        text-align: right;
      }
    }
   
    #newCheckoutGiftMessageArea {
      position: relative;
      right: -6%;
      float: right;
      width: 98%;
      padding: 30px 4%;
      background-color: $concrete;
      clear: both;

      h3 {
        @include primaryFont();
        font-size: 1.4rem;
        font-weight: bold;
        margin: 0 0 5px;

        span {
          @include primaryFont();
          font-size: 1rem;
        }
      }

      label {
        display: block;
        margin: 0 0 3px;
        font: {
          size: 13px;
        }

        span {
          span {
            @include primaryFont();
            font-size: 1rem;
          }
        }
      }

      input[type='text'] {
        background-color: $white;
      }

      textarea {
        font: {
          size: 14px;
        }
      }

      .apply {
        text-align: center;
      }
    }

    .last-button {
      margin: 0 0 20px;
      padding: 0 0 20px;
      border-bottom: 1px solid $concrete;
    }

    .address,
    .address p {
      @include primaryFont();
      font-size: 1.3rem !important;
      text-transform: uppercase;
      line-height: 2rem;
    }

    .address strong {
      @include primaryFont();
      font-size: 1.3rem !important;
      text-transform: uppercase;
      line-height: 2rem;
      font-weight: bold;
    }

    .edit-link {
      position: absolute;
      top: 0;
      right: 0;
    }

    .address-wrapper {
      position: relative;
      margin: 0 0 30px;
    }

    #sliderContainer {
      position: relative;
      float: left;
      width: 100%;
      margin: 120px 0 0;
      padding: 30px 0 0;
      border-top: 1px solid $concrete;
      clear: both;

      h3 {
        margin: 0 0 17px;
      }

      #slider {
        width: 80% !important;
        margin: 0 auto 20px;

        .products {
          li {
            width: 240px !important;
          }
        }

        .item {
          width: 48%;
          padding-bottom: 20px;
          min-height: 0;

          .compare {
            display: none !important;
          }
        }
      }

      .owl-prev,
      .owl-next {
        position: absolute;
        top: 190px;
        width: 18px;
        height: 18px;
        text-indent: -9999px;
      }

      .owl-prev {
        left: -12%;
        background: transparent url('../assets/images/redesign-icons/prev-btn.png') no-repeat;
      }

      .owl-next {
        right: -10%;
        background: transparent url('../assets/images/redesign-icons/next-btn.png') no-repeat;
      }

      #controls {
        text-align: center;
        clear: both;

        li {
          display: inline-block;
          margin: 0 5px 0 0;

          a {
            @include rounded(10px);
            display: block;
            width: 10px;
            height: 10px;
            border: 1px solid $concrete;
            text-indent: -9999px;
          }

          &.current {
            a {
              background-color: $dark-gray;
              border-color: $dark-gray;
            }
          }
        }
      }
    }
  }

  #total-breakdown {
    width: 312px;

    h3 {
      span {
        color: $alizarin-crimson;

        &.icon {
          float: right;
          cursor: pointer;
        }
      }
    }

    font: {
      size: 11px;
    }

    .breakdown {
      padding: 0 0 30px;
      clear: both;
    }

    .col {
      float: left;
      width: 60%;
      margin: 0 0 10px;
      font: {
        size: 11px;
      }

      &.indent {
        text-indent: 0;
      }

      &.right {
        text-align: right;
        width: 40%;
      }

      &.total {
        @include primaryFont();
        font-size: 1.3rem;
        font-weight: bold;
        margin: 0;
      }

      &.discount {
        color: $alizarin-crimson;
      }

      strong,
      .bold {
        font: {
          size: 11px;
        }
      }
    }

    .black-button {
      padding: 12px 31px;
    }

    #gift {
      padding: 0 0 30px;
      clear: both;

      h3 {
        margin: 0 0 10px;
      }

      div {
        margin: 0 0 3px;
      }

      .giftMessageToText,
      .giftMessageFromText {
        word-wrap: break-word;
      }

      .giftMessageText {
        word-wrap: break-word;
        clear: both;
        font: {
          size: 9px;
        }
      }
    }

    #idme {
      #idme-container {
        .idme-iframe-container {
          height: 65px;
          padding: 15px;
        }
      }
    }

    #promotions {
      float: left;
      width: 100%;
      margin: 0 0 30px;

      input[type='text'] {
        float: left;
        background-color: $white;
        width: 57%;
        border: 1px solid transparent;
      }

      .black-button {
        float: left;
        width: 19%;
        padding: 8px 15px 9px;
        font: {
          size: 10px;
        }
      }

      .order_link {
        @include primaryFont();
        display: block;
        margin: 0 0 5px;
        color: $silver-2;
        text-decoration: underline;
        font-size: 1.1rem;
        font-weight: bold;
      }

      #promo-container {
        padding: 5px 0 0;
      }

      #promoCodeMessage {
        clear: both;
        padding: 5px 0 0;
      }

      #appliedPromotionCodes {
        float: left;
        width: 100%;
        padding: 10px 0 0;
        clear: both;

        .used {
          @include primaryFont();
          font-size: 1rem;
          display: block;
          margin: 0 0 10px;
          color: $ocean-green;

          strong {
            @include primaryFont();
            font-size: 1rem;
            font-weight: bold;
          }
        }

        li {
          @include primaryFont();
          float: left;
          width: 100%;
          margin: 0 0 10px;
          padding: 0 0 10px;
          list-style-type: none;
          clear: both;
          border-bottom: 1px solid $dark-gray;
          font-size: 1.1rem;
          font-weight: bold;

          .icon {
            float: right;
          }
        }
      }
    }

    #total-bottom {
      .green-button,
      .black-button {
        width: 220px;
        margin: 0 0 10px;
        padding: 12px 0;
      }

      .agree {
        @include primaryFont();
        margin: 10px auto 20px;
        padding: 0;
        color: $mine-shaft-2;
        line-height: 1.4rem;
        text-align: center;

        &.required {
          color: $alizarin-crimson;
        }
      }

      div {
        margin: 0 0 10px;

        &.paypalbutton {
          &.AU {
            .icon {
              margin: 0 auto;
            }
          }
        }
      }

      .icon {
        text-indent: -9999px;
      }
    }

    .customer-care {
      text-align: center;
      float: left;
      width: 100%;
      clear: both;

      h3 {
        @include primaryFont();
        font-size: 1.4rem;
        margin: 50px 0 10px;
      }

      strong {
        display: block;
        font: {
          size: 11px;
        }
      }

      p {
        @include primaryFont();
        font-size: 1.1rem;
      }
    }

    #products {
      float: left;
      width: 100%;
      margin: 0 0 20px;
      clear: both;

      #hto-item-container {
        position: relative;
        float: left;
        width: 100%;
        margin: 15px 0 0;
        padding: 0 5px 5px;
        background-color: $alto-2;
        clear: both;

        .redesignIcons-hto-logo-small {
          float: left;
          margin: 0 30px 10px 25px;
        }

        h3 {
          @include primaryFont();
          font-weight: bold;
          margin: 5px 0 5px 10px;
        }

        .delete-link {
          @include primaryFont();
          font-size: 0.9rem;
          position: absolute;
          top: 9px;
          right: 20px;
          text-align: center;

          span {
            position: relative;
            top: 4px;
            display: inline-block;
            margin: 0;
          }
        }

        #hto-items {
          float: left;
          width: 96%;
          padding: 0 2%;
          background-color: $white;

          .row {
            width: 96%;
            padding: 10px 4% 10px 0;
            min-height: auto;
            border-bottom-color: $concrete;

            .delete-link {
              display: none;
            }

            .ship-image {
              width: 110px;
              margin: 0 10px 0 0;
            }

            .brand-container {
              width: 80px;
            }

            .price-remove {
              float: right;
              width: 40%;
              padding: 0;
              clear: none;

              .price,
              .sale-price {
                font: {
                  size: 10px;
                }
              }
            }
          }
        }
      }

      .row {
        float: left;
        width: 100%;
        padding: 20px 0;
        clear: both;
        border-bottom: 1px solid $white;
      }

      .ship-image {
        float: left;
        width: 110px;
        margin: 0 20px 0 0;

        img {
          width: 100%;
        }
      }

      .ecard-details {
        margin-top: 107px;

        p {
          padding: 3px 0;
          text-transform: none;
        }
      }

      .brand-container {
        float: left;
        width: 90px;

        p {
          text-transform: none;
        }

        .brand {
          @include primaryFont();
          font-size: 1rem;
          font-weight: bold;
        }
      }

      .price-remove {
        float: left;
        width: 100%;
        padding: 20px 0 0;
        clear: both;

        .price {
          @include primaryFont();
          display: inline-block;
          font-size: 1.4rem;
          font-weight: bold;
        }

        .delete-link {
          float: right;
          margin: -3px 27px 0 0;
          color: $dusty-gray-4;
        }

        .icon {
          position: relative;
          top: 3px;
          display: inline-block;
        }
      }
    }
  }

  .green-button {
    min-width: 180px;

    + p {
      width: 185px;
      text-align: center;
      padding: 5px 0 0;
      clear: both;
      font-size: 1.2rem !important;
      text-transform: uppercase !important;

      &.billing {
        width: 224px;
      }

      &.plcc {
        width: 192px;
      }
    }
  }
}

.error_shippingcode {
  margin-top: 35px;
}

.egiftcard-checkout {
  .box-item__qty {
    display: none;
  }

  #cart-left {
    padding-top: 14px;
    @media (min-width: 1025px) {
      padding-top: 36px;
    }
  }

  .eGC_form {
    @screen lg {
      padding-top: 20px;
      max-width: 312px;
    }
  }

  .sgh-form__error-field {
    position: static;
    padding-top: 0 !important;
    padding-left: 10px;
    max-width: 290px;
    color: $alizarin-crimson;
    font-size: 1.4rem !important;
    @media (min-width: 961px) {
      position: absolute;
      top: 15px;
      left: 322px;
      width: 172px;
      padding: 0;
    }
  }

  span.required {
    @media (max-width: 960px) {
      width: 100%;
      max-width: 290px;
    }
  }

  .sgh-form__header {
    margin-bottom: 30px;
    @screen mdr {
      justify-content: space-between;
      align-items: center;
    }
  }

  .sgh-form__title {
    @screen mdr {
      max-width: 80%;
      font-size: 1.7rem;
      margin-bottom: 0;
    }
  }

  .sgh-form__note {
    @screen mdr {
      width: auto;
      align-self: flex-end;
    }
  }

  .sgh-form__group-title {
    @screen mdr {
      margin-bottom: 20px;
    }
  }

  .sgh-form__item {
    @screen mdr {
      margin-bottom: 20px;
    }

    input,
    textarea {
      width: 100% !important;
      padding: 7px 12px !important;
      line-height: 1.8rem;
    }

    &.is-invalid {
      label {
        color: $alizarin-crimson !important;
      }

      input,
      textarea {
        border-color: $alizarin-crimson !important;
      }
    }
  }

  #WC_CheckoutSingleShipping_BillingSection {
    display: block;
  }

  .checkout-subtitle {
    border: 0;
  }

  .checkoutWarning {
    @screen lgr {
      float: none;
      margin-top: 5px;
    }
  }

  #stateDiv1 .select-wrapper {
    @screen lgr {
      float: none;
    }
  }

  #WC_billingAddressCreateEditFormDiv_1_country_div_29 .fe-text-field-value {
    padding-left: 13px;
  }
}


