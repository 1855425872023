body {
  &.has-benefitbar {
    padding-top: 14rem;
    &.overflow-hidden.hide-fake-input .sgh-header-top {
      z-index: 0;
    }
  }

  @screen lgr {
    &.has-benefitbar {
      padding-top: 14.8rem;
    }
  }
}
