/* stylelint-disable */
@import './circles-banners';
@import './banner-double-slider-text';

.get-support {
  width: 90%;
  max-width: 99.2rem;

  @screen lgr {
    width: 100%;
  }
  &__menu {
    width: 22.4rem;
    padding-top: 4.6rem;
    padding-bottom: 9.7rem;

    @screen lgr {
      position: absolute;
      background: $white;
      right: -1.1rem;
      max-height: 0;
      width: 100%;
      box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.05);
      transition: max-height 0.8s ease-in-out;
      overflow: hidden;
      z-index: 1;

      &.open {
        max-height: 60rem;
      }
    }
    &-list {
      @screen lgr {
        margin: 2.8rem 0 2.4rem 0;
      }
      &__selected {
        a {
          color: $mine-shaft;
          text-decoration: underline;
          text-underline-offset: 0.4rem;
          text-decoration-thickness: 0.2rem;
          text-decoration-color: $mine-shaft;

          &:hover {
            text-decoration: underline;
            text-decoration-thickness: 0.2rem;
            color: $mine-shaft;
          }
        }
      }
    }
  }

  &__body {
    width: 73.4rem;
    @screen lgr {
      width: 100%;
      overflow-wrap: anywhere;
    }
    .get-support__shortcuts {
      @screen lgr {
        margin-bottom: 2.1rem;
      }
    }

    &-breadcrumb {
      height: 4.6rem;
      margin-bottom: 2.4rem;
      border-bottom: 0.1rem solid $mercury;
      padding-top: 1rem;

      @screen lgr {
        border-top: 0.1rem solid $mercury;
        padding: 0;
      }
      ul {
        z-index: 2;
        li {
          color: $gray-48;
          font-size: 1.2rem;
          a {
            color: $gray-48;
          }

          &:last-child {
            --breadcrumbwidth: 14rem;
            .selected {
              color: $mine-shaft;
              @screen lgr {
                text-decoration-line: underline;
                text-decoration-thickness: 0.2rem;
                text-underline-offset: 0.3rem;
                max-width: calc(100vw - var(--breadcrumbwidth) - 10rem);
                display: inline-block;
                vertical-align: middle;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                .openLayerMenu & {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
      .common {
        &__icon {
          width: 1.6rem;
          height: 1.6rem;
          &--arrow-left {
            margin-right: 0.8rem;
          }

          &--icon_collapse_bottom {
            .openLayerMenu & {
              transform: rotate(180deg);
            }

            &.toggle-menu {
              @screen lg {
                display: none;
              }
            }
          }
        }
      }
    }
    &-container {
      &-table {
        width: 100%;
        td {
          padding: 1.6rem;
          p {
            padding: 0;
            margin: 0;
          }
        }
        &__title {
          font-weight: 500;
          text-transform: uppercase;
          border-bottom: #e6e6e6 1px solid;
        }
      }
      @screen lgr {
        overflow: scroll;
        &-table {
          width: 55rem;
        }
      }
    }
    .rte--list {
      padding-left: 4rem;
      margin: 0;
      margin-bottom: 1.8rem;
    }
    &-page {
      p,
      strong {
        margin: 0;
        font-size: 1.6rem;
      }
      .text-article--content p,
      .text-article--content ol li {
        margin-bottom: 1.8rem;
        .paymentMethods{
          &-row {
            &__name {
            margin-bottom:0;
             
            }
          }
        }
      }

      .text-faq-section--content {
        p,
        .rte--list {
          margin-bottom: 1.2rem;
        }
      }

      .text-article,
      .text-faq-section__wrapper {
        .cm-teasable--embedded {
          .cm-teasable {
            &__picture {
              margin-bottom: 1.2rem;
              img {
                width: auto;
                height: auto;
              }
            }

            &_title,
            &__text {
              display: none;
            }
          }
        }

        a,
        a:hover {
          text-decoration: underline;
        }
      }
    }
    &-title {
      p {
        margin-bottom: 1.8rem;
      }
    }

    .text-small,
    .alert-warning {
      color: $emperor;
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
    .alert-warning {
      border-left: 0.3rem solid $mercury-2;
      margin-left: 0.8rem;
      padding: 1.3rem 1rem;
      background-color: transparent;
      background-image: none;
    }
  }

  .sgh-gp-gridBox {
    &__selected,
    &__selected:hover {
      text-decoration: underline;
    }
    &-topics {
      padding: 0.6rem;
      background-color: #fafafa;
    }
    &__links {
      div {
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  article {
    .align {
      &--left {
        text-align: left;
      }
      &--right {
        text-align: right;
      }
      &--center {
        text-align: center;
      }
      &--justify {
        text-align: justify;
      }
    }

    .text-article--content:has(table) {
      > p {
        margin-bottom: 1.4rem;

        @screen lgr {
          margin-bottom: 2.8rem;
        }
      }
    }
    blockquote strong {
      font-size: inherit;
    }
  }

  table {
    width: 100%;
    table-layout: fixed;

    p {
      padding: 1.6rem !important;
      margin: 0 !important;
    }

    tr {
      &:nth-child(odd) {
        background-color: $ligth-gray;
      }
      &:first-child {
        border-bottom: 0.1rem solid $mercury-2;
        background-color: transparent;

        @screen lgr {
          border: none;
        }
        td {
          @screen lgr {
            white-space: nowrap;
            &:after {
              content: '';
              display: block;
              width: 50vw;
            }
          }
          p {
            font-size: 1.4rem;
            color: $mine-shaft;
            font-weight: 500;

            @screen lgr {
              border-bottom: 0.1rem solid $mercury-2;
            }
          }
        }
      }
      &:not(:first-child) {
        td {
          vertical-align: middle;
        }
        div {
          font-size: 1.4rem;
          line-height: 1.6rem;
          color: $emperor;
        }
        p {
          font-size: 1.4rem;
          strong {
            font-size: 1.4rem;
            font-weight: normal;
            line-height: 1.6rem;
            color: $mine-shaft;
          }
          em {
            font-size: 1.2rem;
            line-height: 1.4rem;
            color: $emperor;
          }
        }
      }
    }

    @screen lgr {
      transform: rotateX(180deg);
      display: block;
      max-width: -moz-fit-content;
      max-width: 90vw;
      margin: 0 auto;
      overflow-x: auto;
      scrollbar-color: $mine-shaft $alabaster;
      scrollbar-width: thin;

      tbody {
        transform: rotateX(180deg);
      }
    }
  }

  @screen lgr {
    .cutoffTableWrapper {
      overflow-x: auto;
      transform: rotateX(180deg);

      .cutoffTable {
        width: 200%;
        transform: rotateX(180deg);
      }
    }

    table,
    .cutoffTableWrapper {
      &::-webkit-scrollbar {
        height: 0.4rem;
        background-color: $alabaster;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: $alabaster;
        border-radius: 0.8rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $mine-shaft;
        border-radius: 0.8rem;
      }

      ::-webkit-scrollbar-button {
        display: none;
      }
    }
  }
  .cms-fw-banner,
  .cms-text-align-center {
    width: auto;
    margin: 0 -2rem;
  }
}

.sgh-gp-info,
.sgh-gp-gridBox {
  border-radius: 0.3rem;
}

.sgh-gp-info {
  .icon img {
    width: 1.4rem;
    height: 1.4rem;
  }
}

@screen lgr {
  .sgh-support-overlay {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    top: 4rem;
    right: -5%;
    left: -5%;
    width: 100%;
    margin: 0 5%;
    transition: opacity 1.6s, visibility 1.6s;

    .openLayerMenu & {
      visibility: visible;
      opacity: 1;
      transition: opacity 1.6s, visibility 1.6s;
      z-index: 1;
    }
  }
}

.text-article,
.text-faq__wrapper {
  &.highlight {
    .text-article__title,
    .text-faq__title {
      background: $yellow;
    }
  }
}

.text-faq,
.text-faq-section {
  &__wrapper {
    .common__icon--arrow-up {
      display: none;
    }

    .common__icon--arrow-down {
      display: block;
    }

    &.open {
      + .text-faq--content,
      .text-faq-section--content,
      .common__icon--arrow-up {
        display: block;
      }

      .common__icon--arrow-down {
        display: none;
      }
    }
  }

  &--content {
    display: none;
  }
}
.text-article__title {
  @include actaHeadlineFontTitle();
  font-size: 2.8rem !important;
}
.sgh-getSupport-info,
.sgh-getSupport-grid {
  width: 100%;
  max-width: 104rem;
  margin: 0 auto;
  &_content {
    div {
      width: 100%;
    }
  }
}

.groupSalesIFrame {
  @screen mdr {
    height: 70rem;
  }
}
