/* stylelint-disable */
body.home {
  &:has(.ghostheader-dark) {
    padding-top: 4rem;
  }
  &:has(.ghostheader-light) {
    padding-top: 4rem;
  }
}

.sgh-header {
  width: 100% !important;
  &.not-scrolled {
    position: relative;
    margin-top: -10rem;
    &.isPdp {
      height: auto !important;
    }
    &:has(#hamburger-icon.open) {
      position: fixed;
      margin-top: 0;
    }
    @screen smr {
      margin-top: -10.8rem;
      height: auto !important;
    }
  }

  &__container {
    z-index: 3;
    position: relative;
    &.no-results ~ .skeleton {
      display: none;
    }
    .container {
      @media (min-width: 1280px) {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &__content {
    @screen smr {
      .sgh-filters-name {
        overflow: hidden;
        max-width: 25rem;
      }
    }
  }

  &__banner-wrapper {
    @screen smr {
      .sgh-col.w-full {
        padding: 0;
      }
    }
  }

  &__skip-content {
    border: 1px solid black;
    bottom: 40px;
    padding: 5px;
    right: 40px;
    z-index: 2;
  }

  &--has-db {
    .sgh-header__banner-wrapper-scrolled {
      margin-top: 75px;
    }
  }
  .sgh-main-menu {
    &__wrapper {
      padding-top: 4rem;
      .ghostheader-fade {
        display: none;
      }
      .benefit-bar {
        p {
          strong {
            font-size: 1.4rem;
          }
        }
      }
      @screen lgr {
        margin-top: 4.8rem;
        padding-top: 0;
      }
    }
    .benefit-bar {
      transition: linear 0.3 all;
    }
    &__down {
      animation: scrollDown 0.3s linear;
      animation-fill-mode: forwards;
      pointer-events: none;
      height: 0;
    }
    &__top {
      animation: scrollTop 0.3s linear;
      animation-fill-mode: forwards;
      pointer-events: all;
      .menu {
        top: 6rem;
      }
    }
    &__down,
    &__top {
      @screen lgr {
        .sgh-search-overlay {
          margin-top: -4.8rem;
        }
      }
    }
  }

  &:has(.sgh-search-overlay.in) {
    .main-menu-center {
      z-index: -1;
    }
  }
}

.home .sgh-header {
  &.ghostheader-dark {
    .sgh-main-menu {
      background: transparent;
      transition: 0.5s linear;
      border-bottom: 0;
      @screen lg {
        .menu-item {
          &--firstLevel {
            & > .menu-item-link {
              color: $white;
              
            }
          }
        }
      }

      .ghostheader-fade {
        display: block;
        background: rgba(0, 0, 0, 0);
        background: linear-gradient(180deg, $fade-black 0%, transparent 94%);
        height: 10rem;
        position: absolute;
        width: 100%;
        left: 0;
        z-index: -1;
      }

      &.active-ghost,
      &.menu-scrolled.active-ghost {
        background: transparent;
        border: 0;
        box-shadow: none;
        .ghostheader-fade {
          background: rgba(0, 0, 0, 0);
          background: linear-gradient(180deg, $fade-black 0%, transparent 94%);
          height: 10rem;
          position: absolute;
          width: 100%;
          left: 0;
          z-index: -1;
        }
        .sgh-logo {
          color: $white;
        }
        #hamburger-icon span {
          background: $white;
        }
        .main-menu-center {
          color: $white;
          .navbar-nav .dropdown {
            .sgh-main-menu {
              &__main-nav {
                &--section {
                  color: $white;
                  &.hilighted {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }

      .sgh-logo {
        color: $white;
      }
      #hamburger-icon span {
        background: $white;
      }

      .main-menu-center {
        color: $white;
        .navbar-nav .dropdown {
          .sgh-main-menu {
            &__main-nav {
              &--section {
                color: $white;
                &.hilighted {
                  color: $white;
                }
              }
            }
          }
        }
      }
      .main-menu-right {
        .common__icon {
          &--search-icon {
            @include icon-mixin-search-icon-white;
          }
          &--wishlist-icon {
            @include icon-mixin-wishlist-icon-white;
          }
          &--account-icon {
            @include icon-mixin-account-icon-white;
          }
          &--cart-icon {
            @include icon-mixin-cart-icon-white;
          }
        }
        .counter {
          background-color: $red-berry-2;
        }
      }

      &.menu-scrolled,
      &:has(#hamburger-icon.open),
      &:has(.search-layout-visible) {
        .ghostheader-fade {
          background: transparent;
        }
        .menu-item {
          &--firstLevel {
            & > .menu-item-link {
              color:$mine-shaft;
            }
          }
        }
        background: $white;
        transition: 0.2s linear;
        height: 6rem;

        #hamburger-icon span {
          background: $black;
        }
        .main-menu-center {
          .navbar-nav .dropdown {
            .sgh-main-menu {
              &__main-nav {
                &--section {
                  color: $black;
                  &:active {
                    color: $gray-3;
                  }
                  &.hilighted {
                    color: $red-berry-2;
                    &:active {
                      color: $red-berry-2;
                    }
                  }
                }
              }
            }
          }
        }
        .sgh-logo {
          color: $black;
        }
        .main-menu-right {
          .counter {
            background-color: $red-berry-2;
          }

          .common__icon {
            &--search-icon {
              @include icon-mixin-search-icon;
            }
            &--wishlist-icon {
              @include icon-mixin-wishlist-icon;
            }
            &--account-icon {
              @include icon-mixin-account-icon;
            }
            &--cart-icon {
              @include icon-mixin-cart-icon;
            }
          }
        }
      }
      &:hover {
        .menu-item {
          &--firstLevel {
            & > .menu-item-link {
              color: $mine-shaft;
            }
          }
        }
      }
      @screen md {
        &:hover {
          background: $white;
          height: 6rem;

          .main-menu-center {
            .navbar-nav .dropdown {
              .sgh-main-menu {
                &__main-nav {
                  &--section {
                    color: $black;
                    &:active {
                      color: $gray-3;
                    }
                    &.hilighted {
                      color: $red-berry-2;
                      &:active {
                        color: $red-berry-2;
                      }
                    }
                  }
                }
              }
            }
          }
          .sgh-logo {
            color: $black;
          }

          .main-menu-right {
            .common__icon {
              &--search-icon {
                @include icon-mixin-search-icon;
              }
              &--wishlist-icon {
                @include icon-mixin-wishlist-icon;
              }
              &--account-icon {
                @include icon-mixin-account-icon;
              }
              &--cart-icon {
                @include icon-mixin-cart-icon;
              }
            }
            .counter {
              background-color: $red-berry-2;
            }
          }
        }
      }
    }
  }
  &.ghostheader-light {
    .sgh-main-menu {
      background: transparent;
      transition: 0.5s linear;
      border-bottom: 0;

      &.active-ghost,
      &.menu-scrolled.active-ghost {
        background: transparent;
        border: 0;
        box-shadow: none;
        .ghostheader-fade {
          background: $white;
          background: linear-gradient(180deg, $fade-light-gray, transparent);
          height: 10rem;
          position: absolute;
          width: 100%;
          left: 0;
          z-index: -1;
        }
      }

      &.menu-scrolled,
      &:has(#hamburger-icon.open) {
        background: $white;
        box-shadow: 0.1rem 0.8rem 1.4rem $gray-shadow;
        border-bottom: 0.1rem solid $white;
        .ghostheader-fade {
          background: transparent;
        }
        .main-menu-center {
          .navbar-nav .dropdown {
            .sgh-main-menu {
              &__main-nav {
                &--section {
                  &:active {
                    color: $gray-3;
                  }
                  &.hilighted {
                    color: $red-berry;
                    &:active {
                      color: $soft-red;
                    }
                  }
                }
              }
            }
          }
        }
        .sgh-logo {
          color: $black;
        }
        .main-menu-right [class^='icon-'] {
          &::before {
            color: $black;
          }
        }
      }
       &:has(.search-layout-visible) {
        .ghostheader-fade {
          background: transparent;
        }
        transition: 0.2s linear;
        height: 6rem;
      }
      @screen md {
        &:hover {
          background: $white;
          box-shadow: 0.1rem 0.8rem 1.4rem $gray-shadow;
          .main-menu-center {
            .navbar-nav .dropdown {
              .sgh-main-menu {
                &__main-nav {
                  &--section {
                    color: $black;
                    &.hilighted {
                      color: $red-berry;
                    }
                  }
                }
              }
            }
          }
          .sgh-logo {
            color: $black;
          }
          .main-menu-right [class^='icon-'] {
            &::before {
              color: $black;
            }
          }
        }
      }
    }
  }
}

.has-benefitbar {
  .sgh-header {
    &.not-scrolled {
      margin-top: -14rem;
      @screen mdr {
        margin-top: -14.8rem;
      }
    }
  }
}

sgh-header-top {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 4rem;
  width: 100%;

  @screen lgr {
    height: 4.8rem;
  }

  .skeleton {
    padding: 0 4rem;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @screen lgr {
      padding: 0 2rem;
    }

    .message {
      width: 48rem;
      margin: 0.8rem 0;
      height: 1.4rem;
      @include skeleton-animation;
    }

    ul {
      display: flex;
      list-style: none;
      gap: 2.5rem;
      margin: 1rem 0;

      @screen lgr {
        display: none;
      }

      li {
        width: 6rem;
        height: 1.4rem;
        @include skeleton-animation;

        &:last-child {
          width: 1.5rem;
        }
      }
    }
  }
}

sgh-header-top-controller {
  display: none;
}

sgh-header-navigation {
  .skeleton {
    height: 6rem;
    margin-top: 4rem;
    padding: 0 2.9rem 0 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @screen lgr {
      padding: 0 1.4rem 0 2rem;
      margin-top: 4.8rem;
    }

    .icon-logo {
      color: #222;
      font-size: 2.1rem;
      width: 12.3rem;
      padding: 0.5rem 0.5rem 0.5rem 0;

      @screen lgr {
        font-size: 2rem;
        width: 11.7rem;
        padding-left: 0.5rem;
      }
    }

    .menu {
      display: flex;

      @screen lgr {
        display: none;
      }

      li {
        margin: 0 3.2rem;
        width: 5rem;
        height: 1.2rem;
        @include skeleton-animation;
      }
    }

    .icons {
      display: flex;
      gap: 0.8rem;

      li {
        width: 4rem;
        height: 4rem;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          width: 2rem;
          height: 2rem;
          border-radius: 10rem;
          @include skeleton-animation;
        }
      }
    }
  }
}

@keyframes scrollDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes scrollTop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
