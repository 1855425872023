sgh-seo-text-plp {
  width: 110rem;
  height: 20rem;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 110rem;
    margin: 0 auto;
    padding-bottom: 1rem;
    li {
      &:first-child {
        height: 4.2rem;
        margin: 0.8rem 0;
        width: 100%;
        @include skeleton-animation;
      }
      &:last-child {
        min-height: 9rem;
        width: 100%;
        @include skeleton-animation;
      }
    }
  }
}
