.banner-double-slider-text {
  width: 100%;
  &__left {
    background: rgba(242, 177, 62, 0.1);
    &-title {
      line-height: 1.21;

      @screen smr {
        padding-right: 33%;
      }
    }
    &-subtitle {
      line-height: 1.13;
    }
    .banners__slide {
      &-text {
        line-height: 1.56;
        font-weight: normal;
      }
    }
    .navigation-sgh-slide {
      @screen smr {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
  }
  &__rigth {
    &-title {
      max-width: 44.8rem;
      @screen smr {
        max-width: 100%;
        width: 100%;
      }
      margin-bottom: 3.2rem;
    }
    &--banners {
      gap: 1.6rem;
      div {
        width: 18rem;
        height: 12.7rem;
      }
    }
    img {
      width: 6rem;
      height: 6rem;
    }
    .banners__slide {
      &--content {
        width: 18rem;
      }
    }
    .navigation-sgh-slide {
      margin-top: 3.2rem;
    }
  }
  #sgh-slider .navigation-sgh-slide {
    @screen smr {
      margin-top: 0.5rem;

      .nav-hybrid {
        height: 0.8rem;
        width: 0.8rem;
        margin: 1.5rem 0.5rem;
        background: black;
        opacity: 0.2;
        &.active-slide {
          opacity: 1;
          width: 2.8rem;
        }
      }
    }
  }
}
