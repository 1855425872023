@import '../icons/svg-icons';

.cms-title__c4-text {
  height: calc(100% - 110px - 2rem);
}

.cms-c4__slider {
  > .swiper-slide {
    &.sm\:w-1\/3 {
      width: 33%;
      height: auto;
    }
  }
}

.sgh-cms__video-container {
  .icon-audio-custom,
  .ico-play-custom,
  .ico-pause-custom,
  .icon-fullscreen {
    height: 4rem;
    width: 4rem;
    position: absolute;
    border-radius: 100%;
    border: none;
    background-color: unset;
    display: inline-block;
    text-indent: -9999px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    vertical-align: middle;

    &::after {
      content: '';
      position: absolute;
    }
  }

  .audio-container {
    z-index: 11;
  }

  .ico-play-custom,
  .ico-pause-custom {
    transform: none;
    opacity: 1;
    bottom: 2.4rem;
    top: unset;
    left: 9.6rem;
    right: unset;
    z-index: 11;

    @screen mdr {
      left: 7.4rem;
      bottom: 1.6rem;
    }
  }

  .ico-play-custom {
    background-image: url(/sghstatichtml/assets/images/play.png);
  }

  .ico-pause-custom {
    background-image: url(/sghstatichtml/assets/images/pause.png);
  }

  .icon-audio-custom {
    left: 4rem;
    bottom: 2.4rem;
    background-image: url(/sghstatichtml/assets/images/audio_on.png);

    @screen mdr {
      left: 1.6rem;
      bottom: 1.6rem;
    }

    &.is-active {
      background-image: url(/sghstatichtml/assets/images/audio_off.png);
    }
  }


  //temporary styles , because we don't have icon bg
  .icon-fullscreen {
    background: $black;
    opacity: 0.3;
    bottom: 2.6rem;
    left: 15.4rem;
    width: 3.6rem;
    height: 3.6rem;
  }
}

.sgh-hero__label {
  position: absolute;
  bottom: 15px;
  left: 50%;
  padding: 6px 10px;
  transform: translate(-50%, 0);
  font-size: 2.2rem;
  line-height: 1;
  font-weight: 300;
  text-transform: uppercase;
  color: #fff;
  background: #f71801;
}

.sgh-cms {
  &__video {
    &--move-play-icon {
      left: 1.6rem !important;
      @screen md {
        left: 4rem !important;
      }
    }
  }
}