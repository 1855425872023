/* stylelint-disable */
:has(.sgh-my-account) {
  #page-wrapper #page {
    max-width: none;
  }
}
#page-wrapper {
  #content_wrapper_box {
    #plp {
      padding: 0;
    }
  }

  &.plp-redesign {
    overflow-x: visible;
    @screen lg {
      overflow: visible;
    }
    @screen md {
      overflow: visible;
    }
  }

  &:not(.checkout-page-wrapper) {
    #plp .item {
      .quick-view {
        padding: 6px;
      }

      &.quick-look-view:hover,
      &.quick-look-view {
        background-color: $concrete !important;

        .hide {
          display: block !important;
        }
      }

      &:hover .details .quick-view span.redesignIcons-plus,
      .details .quick-view span.redesignIcons-plus {
        display: inline-block !important;
      }

      &:hover .details .quick-view span.redesignIcons-minus.hide,
      .details .quick-view span.redesignIcons-minus.hide {
        display: none !important;
      }

      &.quick-look-view:hover .details .quick-view span.redesignIcons-minus.hide,
      &.quick-look-view .details .quick-view span.redesignIcons-minus.hide {
        display: inline-block !important;
      }

      &.quick-look-view:hover .details .quick-view span.redesignIcons-plus,
      &.quick-look-view .details .quick-view span.redesignIcons-plus {
        display: none !important;
      }
    }
  }
}
