@import 'mixins';

.box-4-products {
  @include banner-picture;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__left {
    width: 100%;

    .picture {
      padding-top: calc(537 / 826) * 100%;
    }
  }

  &__right {
    width: 100%;
    padding: 10% auto;
    display: flex;
    flex-direction: column;

    &__banners {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-around;
    }

    &__single-banner {
      padding: 20%;
    }
  }

  .cms-product-tile {
    flex-direction: column;
  }
}

@media screen and (min-width: 769px) {
  .box-4-products,
  .box-4-products-reversed {
    @include banner-picture;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &__left {
      width: 50%;
    }

    &__right {
      width: 50%;

      &__single-banner {
        padding: 0;
        flex: 0 0 45%;
      }
    }
  }

  .box-4-products-reversed {
    flex-direction: row-reverse;
  }
}
