@import 'mixins';

.cms-narrow-banner {
  @include banner-all-medias;
  max-width: 1155px;
  margin: 0 1.5rem;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .picture,
  .video {
    padding-top: calc(345 / 386) * 100%;
  }

  &--left {
    align-items: flex-start;
  }

  &--center {
    align-items: center;
  }

  &--right {
    align-items: flex-end;
  }

  @screen md {
    padding: 0 30px;
    margin: 0 auto;

    .picture,
    .video {
      padding-top: calc(300 / 1155) * 100%;
    }
  }
}
