/* stylelint-disable */
@import '../../mixins/mixins';

#footer {
  border-top: 1px solid $gallery;
  float: left;
  width: 100%;
  padding: 50px 0;
  clear: both;

  h4 {
    @include primaryFont();
    font-size: 1.4rem;
    margin: 0 0 50px;
    text-align: center;
  }

  #columns {
    width: 64%;
    margin: 0 0 50px;
    padding: 0 18%;

    .column {
      float: left;

      &.one,
      &.two {
        width: 44%;
      }

      &.three {
        width: 12%;
      }
    }

    ul {
      list-style-type: none;
    }

    li {
      margin: 0 0 5px;
      line-height: 1.2rem;
      font: {
        size: 10px;
      }

      &.header,
      &.dash {
        @include primaryFont();
        font-size: 1.2rem;
      }
    }
  }

  #email-signup {
    clear: both;
    width: 532px;
    margin: 0 auto;

    h4 {
      margin: 0 0 20px;
    }

    p {
      text-align: center;
      margin: 0 0 20px;
      font: {
        size: 9px;
      }
    }

    input[type='text'] {
      @include rounded;
      width: 430px;
      margin: 0 8px 0 0;
      padding: 16px 10px;
    }

    .fe {
      &.checkbox {
        text-align: center;
      }
    }

    #emailSignupResponse {
      display: none;
    }

    #getOnListResponse {
      @include primaryFont();
      display: none;
      font-size: 0.9rem;
      margin: 0 0 50px;
      text-align: center;
    }
  }

  #legal {
    position: relative;
    float: left;
    width: 80%;
    padding: 0 10%;
    clear: both;

    #country-selector {
      position: absolute;
      bottom: -10px;
      left: 10%;
      width: 215px;
      border: 1px solid transparent;
      z-index: 8990;
      background-color: $white;

      &.expanded {
        border-color: $dark-gray;

        .popup {
          display: block;
        }
      }

      ul {
        list-style-type: none;
      }

      li {
        @include primaryFont();
        cursor: pointer;
        font-size: 1rem;
        position: relative;

        &.launch {
          padding: 10px 30px;
        }

        &:hover {
          color: $white;
          background-color: $dark-gray;

          a {
            color: $white;
            background-color: $dark-gray;
          }
        }

        a {
          display: block;
          padding: 10px 30px;

          &:hover,
          &:focus {
            color: $white;
            background-color: $dark-gray;
          }
        }

        &.selected {
          &:hover {
            background: none;
            color: $dark-gray;
          }
        }

        span {
          position: relative;
          top: 3px;
          float: right;
          margin: 0 12px 0 0;

          &.arrow {
            position: absolute;
            top: 17px;
            right: 20px;
            margin: 0;
          }
        }
      }

      .popup {
        display: none;
      }
    }

    .legal {
      @include primaryFont();
      position: absolute;
      bottom: 0;
      font-size: 1rem;
      left: 50%;
      width: 460px;
      margin: 0 0 0 -210px;
    }

    .social {
      position: relative;
      float: right;
    }
  }
}

@screen mdr {
  html[lang='de'] {
    .sgh-footer .sgh-after-footer .col-tos {
      text-align: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      margin-top: 15px;

      a {
        margin: 8px 0;
        line-height: 0px;

        &:first-child:before {
          content: '';
          position: absolute;
          top: 20px;
          right: 0;
          left: 0;
          display: block;
          width: 40px;
          height: 12px;
          margin: auto;
          background: $white;
        }
      }
    }
  }
}
