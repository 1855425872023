@import 'mixins';
@import '../../utils/utils';
@import '../../variables';

.cms-product-tile {
  @include banner-picture;

  .picture {
    padding-top: calc(312 / 464) * 100%;
  }
}
sgh-swiper-tiles,
swiper-slide {
  display: flex;
  padding-top: 4rem;
  gap: 0.8rem;
  position: relative;
  margin-top: 4rem;
  margin-left: 4.1rem;
  overflow: hidden;
  @screen mdr {
    margin-left: 1.8rem;
  }
  .skeleton {
    &-img {
      display: flex;
      height: 33.9rem;
      width: 32rem;
      background-color: $white-smoke;
      @screen mdr {
        width: 23rem;
        height: 25rem;
      }
    }
    &-text {
      display: flex;
      width: 50%;
      height: 1.6rem;
      margin: 1.6rem 0 0.8rem;
      background-color: $white-smoke;
      &:last-child {
        width: 40%;
        margin: 0;
      }
    }
    &-title {
      margin-bottom: 2rem;
      display: flex;
      width: 20rem;
      height: 1.4rem;
      top: 0;
      position: absolute;
      background-color: $white-smoke;
    }
  }

  .banners__slide {
    display: none;
  }
}
