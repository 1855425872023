@import 'mixins';

.cms-plp-banner-module {
  padding: 0 3.2rem 0 4rem;
  font-size: 1.4rem;
  font-weight: 300;
  color: $mine-shaft;
  line-height: 1.43;
  &-cta {
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
  }
  @screen lg {
    display: flex;
    align-items: center;
    &-img {
      padding-right: 4rem;
    }
  }
}
