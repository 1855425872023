.cart-container {
  width: 95%;
  margin: auto;
}

#header.cart .cart-container {
  height: 50px;
}

#bookapp-container .scheduler-widget .page-content {
  background-color: #fff;
  min-height: calc(74vh - 60px);
}

#bookapp-container .scheduler-widget .header {
  height: auto;
  padding: 0;
  box-shadow: none;
}

#bookapp-container .scheduler-widget .overview-box .overview-bar-mobile {
  background: #f6f6f6 !important;
  color: #202122 !important;
}

.cart .cart-container img {
  width: 123px !important;
  height: 21px !important;
}

#bookapp-container .header .container {
  flex-direction: column;
  align-items: flex-start;
  width: 95%;
  max-width: initial;
}

#bookapp-container .scheduler-widget .title {
  padding: 26px 0;
  color: #1b1b1b;
  font-size: 18px;
  font-weight: 400;
  text-transform: inherit;
}

#bookapp-container .scheduler-widget .progress-bar {
  background-image: none;
  background-repeat: no-repeat;
  background-color: transparent;
  float: none;
  width: auto;
  height: auto;
  margin: auto;
  padding: 29px 0;
  color: #555550;
  box-shadow: none;
}

#bookapp-container .scheduler-widget .label {
  padding: 1px;
  color: inherit;
  font-size: 16px;
  font-weight: 300;
  white-space: normal;
}

#bookapp-container .scheduler-widget .progress-bar .progress-bar-item {
  max-width: 25%;
  min-width: 25%;
}

#bookapp-container .scheduler-widget .progress-bar .separator.active {
  border-color: #d0d0d0;
  border-width: 1px;
}

#bookapp-container .scheduler-widget .progress-bar .icon-check {
  color: #202122;
}

#bookapp-container .scheduler-widget .question-box {
  color: #202122;
  align-items: center;
}

#bookapp-container .scheduler-widget .question-box,
#bookapp-container .scheduler-widget .store-info-box {
  margin-bottom: 20px;
}

#bookapp-container .scheduler-widget .overview-box {
  margin: 0 15px;
  border: solid 1px #dadada;
}

#bookapp-container .scheduler-widget .question-box .option-box {
  min-width: 326px;
  max-width: 326px;
  border-radius: 22px;
  border-color: #4a4a4a;
  font-size: 16px;
  min-height: 40px;
  margin-bottom: 40px;
}

#bookapp-container .scheduler-widget .question-box .option-box .option-box-title {
  font-weight: 400;
}

#bookapp-container .scheduler-widget .question-box .option-box .option-box-subtitle {
  display: none;
}

#bookapp-container .scheduler-widget .link,
#bookapp-container .scheduler-widget .overview-box .overview-box-section .overview-CTA {
  color: #555;
  font-style: normal;
}

#bookapp-container .scheduler-widget .link:after {
  display: none;
}

#bookapp-container .scheduler-widget .overview-box .overview-box-section .overview-CTA {
  font-size: 12px;
}

/* scheduler */
#bookapp-container .react-calendar__viewContainer {
  margin: 0;
}

#bookapp-container .react-calendar__navigation {
  margin-bottom: 14px;
}

#bookapp-container .scheduler-widget .react-calendar__month-view__weekdays__weekday {
  height: auto;
}

#bookapp-container .scheduler-widget .section {
  padding: 0;
}

#bookapp-container .scheduler-widget .section .section-title {
  margin: 20px 0;
  font-weight: 100;
}

#bookapp-container .scheduler-widget .react-calendar__navigation__label {
  flex-grow: inherit;
}

#bookapp-container .scheduler-widget .react-calendar {
  margin-bottom: 0;
}

#bookapp-container .scheduler-widget .icon-arrow {
  font-size: 8px;
}

#bookapp-container .scheduler-widget .react-calendar__navigation__prev-button .icon-arrow {
  right: auto;
}

#bookapp-container .scheduler-widget .react-calendar__navigation__prev-button,
#bookapp-container .scheduler-widget .react-calendar__navigation__next-button {
  border-radius: 0;
}

#bookapp-container .scheduler-widget .react-calendar__navigation__prev-button {
  text-align: inherit;
}

#bookapp-container .scheduler-widget .react-calendar__navigation__next-button .icon-arrow {
  left: auto;
}

#bookapp-container .scheduler-widget .react-calendar__tile {
  margin-bottom: 0;
}

#bookapp-container .booking--section .react-calendar__tile {
  margin-bottom: 4px;
  height: 60px;
}

#bookapp-container .scheduler-widget .react-calendar__tile:disabled {
  background-color: transparent;
  border: 0;
}

#bookapp-container .scheduler-widget .react-calendar__month-view__days__day {
  margin: 0;
}

#bookapp-container .scheduler-widget .react-calendar__tile .tile-content {
  height: 45px;
  margin: 5px;
  background-color: rgba(246, 246, 246, 0.4);
  border: 1px solid #d4d4d4;
  border-radius: 0;
}

#bookapp-container .scheduler-widget .tile--today .tile-content {
  margin: 0px;
  font-size: 10px;
}

#bookapp-container .booking--section .react-calendar__tile .tile-content {
  background-color: rgba(186, 186, 193, 0.4);
}

#bookapp-container .calendar-component .react-calendar__tile--rangeStart .tile-content,
#bookapp-container .calendar-component .react-calendar__tile--rangeEnd .tile-content {
  background-color: transparent;
}

#bookapp-container .scheduler-widget .react-calendar__tile:disabled .tile-content {
  background-color: transparent;
  border: 0;
}

#bookapp-container .booking--section .react-calendar__month-view__days__day {
  background-color: transparent;
  height: auto;
  margin: 8px 8px 0 0;
  border: 0;
}

#bookapp-container
  .scheduler-widget
  .react-calendar__tile.react-calendar__tile--active
  .tile-content {
  background-color: #222;
  border: 1px solid #222;
  text-align: center;
}

#bookapp-container .scheduler-widget .react-calendar__tile.tile--today abbr {
  display: none;
  color: #fff;
  text-decoration: none;
}

#bookapp-container .react-calendar__month-view {
  padding: 0 8px;
}

#bookapp-container .scheduler-widget .react-calendar__tile--rangeStart .tile-content span,
#bookapp-container .scheduler-widget .react-calendar__tile--rangeEnd .tile-content span {
  display: block;
  color: #fff !important;
}

#bookapp-container
  .scheduler-widget
  .react-calendar__month-view__days__day--neighboringMonth
  .tile-content
  span {
  color: #222 !important;
}

#bookapp-container .scheduler-widget .react-calendar__month-view__days__day .tile-content span {
  display: block;
  color: #222;
  height: 45px;
  margin: 5px;
  background-color: rgba(246, 246, 246, 0.4);
  border: 1px solid #d4d4d4;
  border-radius: 0;
}

#bookapp-container .tooltip {
  opacity: 1;
  display: block;
}

#bookapp-container .scheduler-widget .appointment-section .section .section-title {
  font-weight: 100;
  margin-bottom: 23px;
}

#bookapp-container .scheduler-widget .appointment-section .tab-bar .tab-title {
  font-size: 14px;
}

#bookapp-container .scheduler-widget .appointment-section .tab-bar .tab-item.active:after {
  border-bottom: 3px solid #222;
}

#bookapp-container .scheduler-widget .timeslot-tile .timeslot-tile-content {
  border-radius: 0;
  border-color: #d4d4d4;
  color: #555;
  font-size: 12px;
}

#bookapp-container .scheduler-widget .timeslot-tile .timeslot-tile-content.active {
  color: #fff;
}

#bookapp-container .scheduler-widget .container--footer:before,
#bookapp-container .scheduler-widget .container--footer:after {
  display: none;
}

#bookapp-container .scheduler-widget .button {
  text-transform: capitalize;
  background-color: #222;
  border-radius: 22px;
  min-width: 0;
  height: 35px;
  padding: 0 30px;
  color: #fff;
  line-height: 35px;
}

#bookapp-container .booking--section .button {
  background: #fff;
  color: #222;
  width: fit-content;
}

#bookapp-container .scheduler-widget .button-container .button {
  padding: 0;
}

#bookapp-container .booking--section .button--active {
  background: #222;
  color: #fff;
}

#bookapp-container .scheduler-widget .button.button--cta {
  background-color: #222;
  border-color: #222;
}

#bookapp-container .scheduler-widget .store-info-box {
  margin: 0 15px 24px;
}

#bookapp-container .scheduler-widget .calendar-component .react-calendar__year-view__months,
#bookapp-container .scheduler-widget .calendar-component .react-calendar__decade-view__years,
#bookapp-container .scheduler-widget .calendar-component .react-calendar__century-view__decades {
  height: auto;
}

#bookapp-container .scheduler-widget .confirmation-page h1 {
  font-size: 22px;
  font-weight: 300;
}

#bookapp-container .scheduler-widget .booking-form .row > div label {
  width: 100%;
}

#bookapp-container .scheduler-widget .toggle-switch {
  flex: 0 0 auto;
}

#bookapp-container
  .scheduler-widget
  .appointment-section
  .tab-content
  .section-no-content
  .first-line {
  font-size: 14px;
}

#bookapp-container
  .scheduler-widget
  .appointment-section
  .tab-content
  .section-no-content
  .second-line {
  color: #4a4a4a;
  font-size: 12px;
  font-style: normal;
}

/* media queries */

@media (min-width: 769px) {
  #bookapp-container .tooltip {
    display: inline-block;
  }

  #bookapp-container .scheduler-widget .question-box,
  #bookapp-container .scheduler-widget .store-info-box {
    border: 1px solid #ccc;
    border-radius: 0;
    margin-bottom: 0;
  }

  #bookapp-container .scheduler-widget .calendar-component .react-calendar__year-view__months,
  #bookapp-container .scheduler-widget .calendar-component .react-calendar__decade-view__years,
  #bookapp-container .scheduler-widget .calendar-component .react-calendar__century-view__decades {
    height: calc(300px - 40px);
  }

  #bookapp-container .react-calendar__viewContainer {
    margin: 0 38px;
  }

  #bookapp-container .booking--section .react-calendar__viewContainer {
    margin: inherit;
  }

  #bookapp-container .scheduler-widget .progress-bar .separator {
    width: 90px;
    margin: auto;
    border-width: 1px;
  }

  #bookapp-container .scheduler-widget .container.container--main {
    width: 70%;
  }

  #bookapp-container .scheduler-widget .timeslot-tile {
    width: 25%;
  }

  #bookapp-container .scheduler-widget .calendar-section {
    width: 100%;
  }

  #bookapp-container .scheduler-widget .react-calendar__tile.tile--today abbr {
    display: none;
    color: #fff;
    text-decoration: none;
  }

  #bookapp-container .scheduler-widget .col-md-8 {
    width: 59%;
  }

  #bookapp-container .scheduler-widget .col-md-4 {
    width: 34%;
  }

  #bookapp-container .scheduler-widget .react-calendar__month-view__days__day {
    height: auto;
    margin: 14px -1px 0 0;
    background-color: #f6f6f6;
    border: 1px solid #d4d4d4;
  }

  #bookapp-container .calendar-component .react-calendar__month-view__days__day {
    height: auto;
    margin: 3px;
    padding: 0;
  }

  #bookapp-container .scheduler-widget .container--footer {
    width: 70%;
  }

  #bookapp-container .scheduler-widget .store-info-box {
    margin: 0 0 24px;
  }

  #bookapp-container .scheduler-widget .overview-box {
    margin: 0;
  }

  #bookapp-container .scheduler-widget .section {
    border: 1px solid #ccc;
  }

  #bookapp-container .scheduler-widget .date-picker-select {
    padding-right: 0;
    margin-left: 0;
  }

  #bookapp-container .scheduler-widget .date-picker-select .label {
    font-size: 12px;
  }

  #bookapp-container .scheduler-widget .booking-form {
    width: auto;
    margin: 0 40px;
  }

  #bookapp-container .scheduler-widget .privacy-policy-container .privacy-policy-box {
    margin: 0 auto;
  }

  #bookapp-container .scheduler-widget .react-calendar__tile {
    margin-bottom: 5px;
  }

  #bookapp-container .scheduler-widget .react-calendar__tile .tile-content {
    height: 40px;
    background-color: transparent;
    border: 0;
  }

  #bookapp-container .scheduler-widget .react-calendar__tile--rangeStart,
  #bookapp-container .scheduler-widget .react-calendar__tile--rangeEnd {
    background-color: #222;
    border: 1px solid #222;
  }

  #bookapp-container .react-calendar__month-view {
    padding: 0;
  }
}

@media (max-width: 768px) {
  #bookapp-container .scheduler-widget .container--footer {
    margin: 0;
  }

  #bookapp-container .scheduler-widget .container--footer .cta-container {
    margin: 0 1.6rem 0 0;
  }

  #bookapp-container .scheduler-widget .container--footer .button {
    margin: 0 0 0 1.6rem;
  }
}
