@responsive {
  .ratio-a {
    padding-top: 73.33%;
  }
  .ratio-bmb {
    padding-top: 23.45%;
    height: 0;
  }
  .sgh-plp-container {
    width: 100%;
    .ratio-bmb {
      @screen smr {
        padding-top: 0;
        height: 100%;
        .center-center {
          position: relative;
        }
      }

      @screen lg {
        .cms-text-align-left {
          .cms-text-module {
            justify-content: center;
            &__title,
            &__text {
              margin-left: 0;
            }
          }
        }

        .cms-text-align-right {
          .cms-text-module {
            justify-content: center;
            &__title,
            &__text {
              margin-right: 0;
            }
          }
        }
      }
    }

    .sgh-cms-bmb {
      @screen lg {
        .sgh-col {
          height: 100%;
          padding: 0;
          width: 100%;

          .cms-text-align-left {
            .cms-text-module {
              justify-content: center;
              &__title,
              &__text {
                margin-left: 0;
              }
            }
          }
          .cms-text-align-right {
            .cms-text-module {
              justify-content: center;
              &__title,
              &__text {
                margin-right: 0;
              }
            }
          }
        }
      }
      .cms-text-align-center {
        .cms-text-module {
          &__wrapper {
            align-self: center;
            width: 37rem;
            @screen lgr {
              width: 33rem;
            }
          }
        }
      }
    }
  }
}
