.sgh-cms__product-details {
  background-color: $white;
  padding-bottom: 35px;

  .cms-video__slider {
    .swiper-scrollbar {
      height: 4px;
      width: calc(100% - 32px);
      margin: 0 auto;
      background-color: $wild-sand;
    }

    .swiper-scrollbar-drag {
      background-color: $emperor;
    }

    .swiper-slide {
      width: 80vw;
      font-size: theme('fontSize.tiny');
    }
  }
}
