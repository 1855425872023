/* stylelint-disable */
@import '../mixins/mixins';

.left-nav {
  position: absolute;
  width: 256px;
  height: 100%;
  background-color: $concrete;
  margin-left: 65px;
  z-index: 9000;

  #left-logo {
    margin: 50px 0 45px;
    text-align: center;
  }

  h2 {
    @include primaryFont();
    font-size: 1.6rem;
    font-weight: bold;
    margin: -5px 0 40px;
    text-align: center;
  }

  .selected-facets {
    margin: 0 0 25px;
    padding: 0 30px;
    list-style-type: none;

    a {
      display: block;
      margin: 0 0 5px;
    }

    .icon {
      position: relative;
      top: 2px;
      display: inline-block;
      margin: 0 5px 0 0;
    }
  }

  .facet {
    padding: 0 30px;
    clear: both;

    h3 {
      @include primaryFont();
      font-size: 1.4rem;
      font-weight: bold;
      margin: 0 0 20px;
      cursor: pointer;

      span {
        top: 7px !important;
      }

      &:after {
        @include primaryFont();
        font-size: 1.4rem;
        content: '+';
        position: relative !important;
        top: -7px !important;
        margin: 0 0 0 3px;
        color: $boulder;
      }

      &.open {
        margin: 0 0 11px;

        &:after {
          content: '-';
        }

        + .facet-options {
          display: block;
        }
      }
    }

    p {
      @include primaryFont();
      font-size: 1.1rem;
      font-weight: bold;
      color: $tundora;
    }

    .facet-options {
      display: none;

      img {
        position: relative;
        top: 8px;
      }
    }

    .icon {
      position: relative;
      top: 3px;
      display: inline-block;
      margin: 0 3px 0 0;
      text-indent: -9999px;
    }

    a {
      @include primaryFont();
      font-size: 1.1rem;
      text-decoration: none;

      &.tooltip {
        .tooltipContent {
          left: 0;
        }
      }
    }

    ul {
      float: left;
      width: 100%;
      margin: 0 0 40px;
      list-style-type: none;
      clear: both;

      &.collapsed {
        li {
          &.underline {
            margin: 0;
            padding: 0;
            border: none;
          }
        }
      }

      li {
        &.underline {
          margin: 0 0 10px;
          padding: 0 0 10px;
          border-bottom: 1px solid $dusty-gray-3;
        }

        &.disabled {
          a,
          span {
            opacity: 0.6;
            cursor: default;
          }
        }

        &.active {
          position: relative;

          .redesignIcons-radio-checked {
            position: absolute;
            top: 0;
            right: 25px;
          }

          a,
          span:not(.redesignIcons-radio-checked) {
            opacity: 0.4;
            cursor: default;
          }
        }
      }

      &.color {
        li {
          margin: 0 3px 3px 0;
          line-height: 0;
          text-align: center;

          a {
            &.tooltip {
              .tooltipContent {
                bottom: 30px;
                left: -23px;
                width: auto;
              }
            }
          }
        }
      }

      &.face-shape,
      &.style {
        text-align: center;

        li {
          float: left;
          width: 50%;
          margin: 0 auto 20px;

          &.clear {
            float: none;
            width: 100%;
            margin: 0;
          }
        }

        .icon {
          display: block;
          margin: 0 auto 5px;
        }

        a {
          font: {
            size: 11px;
          }
        }
      }

      &.style {
        .active {
          .redesignIcons-radio-checked {
            right: 5px;
          }
        }
      }

      .spacer {
        min-height: 47px;
      }

      a {
        cursor: pointer;
      }
    }

    .invisible-list {
      display: none;
    }

    .view-all {
      @include primaryFont();
      font-size: 1.1rem;
      font-weight: bold;

      /* position:relative; */
      top: -25px;
      margin: 0 0 0 4px;

      .icon {
        top: 0;
        margin: 0 10px 0 0;
      }
    }

    .subnavprice {
      position: relative;
      left: 5px;
      height: 64px;

      #valueHolder {
        position: relative;
        top: 20px;
        left: -5px;
        width: 403px;

        div {
          position: absolute;
          top: 0 !important;
          left: 0 !important;
          width: 50%;
        }

        #sliderMin {
          z-index: 1;
        }

        #sliderMax {
          right: 0 !important;
          z-index: 0;
          text-align: right;
        }
      }
    }
  }
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: $white;
  @include rounded(5px);
}

.ui-slider {
  position: relative;
  text-align: left;
  width: 190px;
}

#priceSlider .ui-widget-content {
  height: 1px;
  border: 0 none;
  background: none repeat scroll 0 0 black;
  color: $mine-shaft;
}

.ui-slider .ui-slider-handle {
  position: absolute;
  top: 5px;
  z-index: 2;
}

.ui-slider-horizontal .ui-slider-range {
  @include rounded(7px);
  position: absolute;
  left: -5px !important;
  display: block;
  width: 205px !important;
  height: 15px;
  z-index: 1;
  background-color: $dusty-gray;
}

#myAccount,
.myAccount {
  #myaccount-leftnav {
    background: $concrete;
    text-align: center;

    .myaccount-welcome {
      position: relative;
      height: 160px;
      text-transform: uppercase;
      background-image: url('../assets/images/my-account/myaccount-welcome-background.jpg');
      background-repeat: no-repeat;

      h1 {
        margin: 0;
        line-height: 2.5rem;
        letter-spacing: 2px;
        padding-top: 36px;
        @include primaryFont();
        font-size: 2.8rem;
        font-weight: bold;
      }

      h2 {
        margin: 0;
        padding: 10px 0;
        line-height: 1.8rem;
        font-weight: lighter;
        letter-spacing: 2px;
        @include primaryFont();
        font-size: 2rem;
      }

      p {
        margin: 0;
        font-size: 1.2rem;
        line-height: 1.3rem;
        font-weight: bold;
        padding-top: 5px;

        &:first-of-type {
          padding: 0;
        }
      }

      .myaccount-signout {
        @include primaryFont();
        font-size: 1.2rem;
        font-weight: bold;
        position: absolute;
        top: 30px;
        right: 45px;
        text-transform: uppercase;
        border-bottom: 1.5px solid black;
      }
    }

    .myaccount-nav {
      height: 80px;
      background-color: $mine-shaft-2;

      ul {
        padding: 32px 0;
      }

      li {
        display: inline-block;
        padding: 0 3%;

        a {
          color: $white;
        }

        &.active {
          position: relative;

          a {
            color: $white;
            font-weight: 500;
          }
        }

        &.active:after {
          content: '';
          position: relative;
          left: 50%;
          display: block;
          width: 20px;
          height: 5px;
          transform: translateX(-50%);
          border-bottom: 2px solid $white;
        }
      }
    }

    h1 {
      @include primaryFont();
      font-size: 1.6rem;
      font-weight: bold;
      margin: 30px 0;
    }

    li {
      @include primaryFont();
      font-size: 1.3rem;
      list-style: none;
      padding: 7px 0;
    }

    li.active {
      @include primaryFont();
      font-size: 1.3rem;
      font-weight: bold;
    }

    .headerLogout {
      margin-top: 10px;
    }

    .store-locator-qa {
      margin-top: 100px;
      padding: 0 30px;

      a {
        text-transform: uppercase;
        text-decoration: underline;
        font: {
          size: 11px;
          weight: normal;
        }

        &:focus,
        &:hover {
          text-decoration: none;
        }
      }

      .qanda {
        margin-top: 40px;

        strong {
          display: block;
          font: {
            size: 11px;
          }
        }
      }

      .title {
        strong: {
          font: {
            size: 11px;
          }
        }

        a {
          text-transform: uppercase;
          font: {
            size: 11px;
            weight: normal;
          }

          &:focus,
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
