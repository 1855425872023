@import '../mixins/mixins';

#userreview {
  width: 90%;
  padding: 30px 5% 0;

  #bv_pdp {
    float: left;
    width: 45%;

    h1 {
      @include primaryFont();
      font-size: 2.8rem;
      font-weight: bold;
      margin: 0 0 20px;
    }

    .price {
      display: block;
      margin: 0 0 5px;
      font: {
        weight: bold;
      }
    }

    #bv_fr_ln_color {
      display: inline-block;
      margin: 5px 0 0 10px;
    }

    #bv_pdp_product_viewer {
      text-align: center;

      img {
        width: 80%;
      }
    }

    .bv_pdp_details {
      float: left;
      width: 100%;
      margin: 0 0 30px;
      clear: both;

      .details {
        float: left;
        width: 50%;

        ul {
          line-height: 1.4rem;
          list-style-type: none;
        }
      }
    }

    .bv_supportlinks {
      float: left;
      width: 100%;
      margin: 0 0 20px;
      clear: both;
      list-style-type: none;

      li {
        float: left;
        width: 50%;
      }
    }
  }

  #BVSubmissionContainer {
    float: right;
    width: 50% !important;
    padding: 0;
  }
}
