/* stylelint-disable */
@import '../mixins/mixins';

/** All Modal **/
/** Signin in Model **/
.signin_black_overlay {
  position: fixed;
  top: 120px;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  -moz-opacity: 0.8;
  opacity: 0.8;
  z-index: 99999;
  background-color: $dark-gray;
  filter: alpha(opacity=80);
}

.signin_white_content {
  position: fixed;
  top: 120px;
  right: 40px;
  display: none;
  width: 350px;
  padding: 16px 0 30px;
  background: $mercury-2 url('../assets/images/account-bg.png') repeat-y;
  z-index: 999999;
  min-height: 380px;
  border-radius: 3px;

  span.required {
    text-align: left;
  }

  #logonErrorMessage,
  .logonErrorMessage,
  #challengeQuestionError,
  #challengeQuestionError_step1,
  #challengeQuestionError_step1_errors,
  #AjaxUserRegistrationErrorMessage {
    margin: 0 0 15px 8px;
    color: $alizarin-crimson;
  }

  /** Start ForgotPasswordModel **/
  .forgotPasswordModel {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;

    h3 {
      font-size: 2rem;
      letter-spacing: 0.6px;
    }

    #challengeQuestion {
      text-align: left;
      margin-bottom: 12px;
    }

    .siginInForm {
      padding-top: 42px;

      p {
        @include primaryFont();
        font-size: 1.4rem;
        padding-left: 9px;

        &.security-question {
          letter-spacing: -0.1px;
          text-transform: none;
          padding-bottom: 0;
        }
      }
    }

    &.forgot-password-step-two {
      span {
        &.required {
          margin-left: 9px;
        }
      }

      label {
        letter-spacing: -0.1px;
        text-transform: uppercase;
        padding-left: 9px;
        text-align: left;
      }

      .input-data {
        padding-top: 4px;

        &.forgotpassword {
          margin-top: 25px;
        }
      }
    }

    .siginInButton {
      padding: 8px 28px;
      letter-spacing: 0.9px;
      background-color: $ocean-green;
    }

    .input-data {
      padding-top: 10px;

      &.forgotpassword {
        margin-top: 30px;
      }
    }

    input[type='text'] {
      letter-spacing: 0.5px;
      margin-left: 0;
    }

    label {
      display: block;
      font-size: 1.4rem;
      letter-spacing: 0.2px;
      padding-bottom: 10px;
      text-transform: none;
      @include primaryFont();
    }

    &.forgot-password-step-one {
      .siginInForm {
        padding-top: 50px;
      }

      input[type='text'] {
        margin-left: 0;
      }

      input[type='email'] {
        margin-left: 0;
      }

      .input-data {
        &.forgotpassword {
          margin-top: 20px;
        }

        span {
          &.required {
            font-size: 1.1rem;
            margin-left: 10px;
            margin-top: -12px;
            text-align: left;
          }
        }
      }

      .siginButtonText {
        font-size: 1.2rem;
      }
    }

    &.forgot-password-step-three {
      .siginInForm {
        margin-top: 52px;

        .security-question {
          letter-spacing: -0.1px;
          padding-left: 12px;
        }
      }

      .input-data {
        &.forgotpassword {
          margin-top: 38px;
        }
      }

      .siginInButton {
        padding: 8px 29px;
        letter-spacing: 0;
      }
    }

    &.forgot-password-step-four {
      span {
        &.required {
          margin-left: 10px;
        }
      }

      .input-data {
        padding-top: 0;

        &.forgotpassword {
          margin-top: 6px;
        }

        span {
          &.required {
            font-size: 1.1rem;
            margin-top: -10px;
            text-align: left;
          }
        }
      }

      %input-email {
        color: $dark-gray;
        font-size: 1.1rem;
        letter-spacing: 0.3px;
        word-spacing: 0.2px;
        margin-left: 0 !important;
        background-color: $white;
      }

      input[type='email'] input[type='text'],
      input[type='password'] {
        @extend %input-email;
      }

      .siginInButton {
        padding: 8px 44px;
        letter-spacing: 0;
        background-color: $alizarin-crimson;
      }

      .siginButtonText {
        font-size: 1.2rem;
      }

      .siginInForm {
        padding-top: 31px;

        p {
          padding-bottom: 11px;
        }
      }
    }

    &.forgot-password-step-five {
      span {
        &.required {
          font-size: 1.1rem;
          margin-left: 13px;
          margin-top: -9px;
          text-align: left;
        }
      }

      input[type='email'],
      input[type='text'],
      input[type='password'] {
        font-size: 1.1rem;
        letter-spacing: 0.3px;
        word-spacing: 0.2px;
        margin-left: 0;
      }

      .siginInButton {
        padding: 8px 45px;
        letter-spacing: 0;
        background-color: $alizarin-crimson;
      }

      .siginButtonText {
        font-size: 1.2rem;
      }

      .input-data {
        padding-top: 0;

        &.forgotpassword {
          margin-top: 21px;
        }
      }

      .siginInForm {
        padding-top: 12px;

        p {
          padding-bottom: 11px;
        }
      }
    }
  }

  /** End ForgotPasswordModel **/

  /** Create account Model step 2 **/
  .create-step-two {
    span.required {
      margin-left: 16px;
    }

    .siginInForm {
      padding-left: 10px;

      p {
        @include primaryFont();
        font-size: 1.2rem;
        letter-spacing: 0.4px;
        padding-top: 2px;

        &.security-answer {
          @include primaryFont();
          font-weight: 700;
          font-size: 1.2rem;
          width: 255px;
          line-height: 1.4rem;
          letter-spacing: 0.7px;
          padding-top: 4px;
          text-transform: none;
          padding-bottom: 9px;
        }
      }
    }

    .select-wrapper {
      &.gray {
        width: 310px;
        margin-bottom: 12px;

        select {
          width: 106%;
          height: 45px;
          letter-spacing: 0.3px;
          background-position: 90% 50%;
        }
      }
    }

    input[type='text'] {
      width: 87%;
    }

    .input-placeholder {
      margin-left: -17px;
    }

    .placeholder {
      left: 28px;
    }

    p {
      &.required {
        margin-right: 7px;
        margin-top: -14px;
      }
    }

    .custom-check {
      label {
        @include primaryFont();
        float: left;
        font-size: 1rem;
        font-weight: normal;
      }
    }

    input[type='checkbox'] {
      & + label {
        margin: 0;
        line-height: 1.4rem;
        letter-spacing: 0.1px;
        padding-top: 7px;
      }
    }

    .fe {
      margin: 0 0 10px;
    }

    .create-account {
      margin-top: 0;
    }
  }

  .createInModel {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;

    .placeholder {
      position: absolute;
      top: 0;
      bottom: 0;
      font-size: 1.1rem;
      left: 21px;
      height: 25px;
      margin: 16px 0 0;
      color: $dark-gray;
      letter-spacing: 0.7px;
      pointer-events: none;
      @include primaryFont();

      span {
        color: $alizarin-crimson;
      }
    }

    &.create-step-one {
      .input-placeholder {
        &.input-half-width {
          .placeholder {
            left: 18px;
          }
        }

        .placeholder {
          left: 15px;
        }
      }

      input[type='text'] {
        margin-left: 6px;
      }

      input[type='password'] {
        margin-left: 6px;
        margin-bottom: 12px;
      }

      .email-box {
        width: 299px;
        margin-left: 0 !important;
      }

      input[type='email'] {
        width: 299px;
        margin-left: 0;
      }
    }

    &.create-step-two {
      .siginInButton {
        padding: 8px 12px;
      }

      .siginButtonText {
        margin: 0;
        font-size: 1.2rem;
        letter-spacing: 0.8px;
      }

      .input-placeholder {
        .placeholder {
          left: 26px;
        }

        label {
          &.error {
            display: block;
            color: $alizarin-crimson;
            font-size: 1.1rem;
            margin-left: 18px;
            margin-top: -11px;
            text-align: left;
          }
        }
      }
    }

    span {
      &.arrow-up {
        top: -19px;
      }
    }

    .signinHeader {
      margin-top: -7px;
    }

    .siginInCloseModel {
      margin-top: 4px;
    }

    a {
      &.back {
        display: inline-block;
        color: $gray;
        font-size: 1rem;
        margin-top: 2px;
      }
    }

    .backicon {
      content: '';
      vertical-align: middle;
      display: inline-block;
      width: 10px;
      height: 10px;
      background: url('../assets/images/siginin-back.png') no-repeat;
      margin-left: 8px;
    }

    .sigininText {
      padding-bottom: 23px;

      h3 {
        font-size: 2rem;
        letter-spacing: 0.3px;
        word-spacing: 3.5px;
      }
    }

    .siginInForm {
      padding-top: 1px;
    }

    .input-placeholder {
      position: relative;

      input {
        &:valid {
          & + .placeholder {
            display: none;
          }
        }
      }

      &.validationError {
        span {
          &.required {
            display: block;
            font-size: 1.1rem;
            margin-left: 7px;
            margin-top: -11px;
            text-align: left;
          }
        }
      }
    }

    .input-half-width {
      float: left;
      display: inline;
      width: 49%;

      & > input {
        display: inline-block;
        width: 81%;
        padding-left: 13px;
      }

      .placeholder {
        left: 19px;
      }

      &.validationError {
        .placeholder {
          left: 20px;

          &.password-placeholder {
            left: 19px;
          }
        }
      }
    }

    .input-second-div {
      input {
        margin-left: 8px;
      }
    }

    p {
      &.requiredText {
        float: right;
        color: $gray;
        font-size: 1.1rem;
        font-style: italic;
        margin-right: 8px;
        margin-top: -10px;
        @include primaryFont();

        span {
          color: $alizarin-crimson;
        }
      }
    }

    .create-account {
      margin-top: 8px;
    }

    .siginInButton {
      margin: 20px 0 -10px;
      padding: 8px 31px;
    }

    #confirmPassword-error {
      margin-bottom: 12px;
    }
  }

  .siginInModel {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;

    span {
      &.required {
        margin-left: 13px;
      }
    }

    .input-data {
      margin-bottom: 12px;

      input[type='text'] {
        margin-bottom: 0;
      }

      span {
        &.required {
          font-size: 1.1rem;
          margin-left: 13px;
          text-align: left;
        }
      }

      &.password-input {
        margin-bottom: 0;
      }
    }

    .text-center {
      text-align: center;
      padding-top: 28px;

      a {
        display: inline-block;
        width: 300px;
        font-size: 1.4rem;
        font-weight: bold;
        @include primaryFont();

        &.new-member {
          color: $dark-gray;
          letter-spacing: -0.5px;
        }

        &.create-account {
          color: $alizarin-crimson;
          letter-spacing: 0.4px;
          padding-top: 8px;
          text-decoration: underline;
        }

        &.create-account-siginin {
          color: $alizarin-crimson;
          letter-spacing: 0.4px;
          padding-top: 8px;
          text-decoration: underline;
        }
      }
    }
  }

  span {
    &.arrow-up {
      content: ' ';
      position: absolute;
      top: -25px;
      left: 276px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $mercury-2;
    }
  }

  &.htoenable span.arrow-up {
    left: 206px;
  }

  .siginInCloseModel {
    content: ' ';
    float: right;
    width: 12px;
    height: 12px;
    background: url('../assets/images/siginin-close.png') no-repeat;
    margin-right: 8px;
    margin-top: -3px;
    cursor: pointer;

    &:hover {
      cursor: pointer;
    }
  }

  .sigininText {
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 28px;
    @include primaryFont();

    p {
      @include primaryFont();
      font-size: 1.2rem;
      padding-bottom: 5px;
      word-spacing: 2.3px;
    }

    h3 {
      @include primaryFont();
      font-weight: 700;
      font-size: 2.3rem;
      word-spacing: 0.5px;
    }
  }

  .siginInForm {
    padding-top: 5px;
    padding-bottom: 10px;
  }

  .input-data {
    text-align: center;
  }

  input[type='text'],
  input[type='email'],
  input[type='password'] {
    width: 89%;
    padding: 14px 9px;
    border: 1px solid $white;
    color: $dark-gray;
    letter-spacing: 0.7px;
    background-color: $white;
    text-transform: none;
    margin-left: 8px;
    margin-bottom: 12px;
    font-size: 1.1rem;
    @include primaryFont();
  }

  input[type='password'] {
    margin-bottom: 0;
  }

  &::-webkit-input-placeholder {
    color: $dark-gray;
    @include primaryFont();
    font-size: 1.1rem;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $dark-gray;
    @include primaryFont();
    font-size: 1.1rem;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $dark-gray;
    @include primaryFont();
    font-size: 1.1rem;
  }

  &:-ms-input-placeholder {
    color: $dark-gray;
    @include primaryFont();
    font-size: 1.1rem;
  }

  .forget-password {
    float: right;
    color: $dusty-gray-3;
    font-size: 1.1rem;
    font-style: italic;
    letter-spacing: -0.1px;
    margin-right: 8px;
    padding-top: 6px;
    padding-bottom: 18px;
    @include primaryFont();
  }

  .siginInButton {
    display: inline-block;
    height: 30px;
    padding: 8px 38px;
    letter-spacing: 0;
    border-radius: 2px;
    background-color: $alizarin-crimson;
  }

  .siginButtonText {
    @include primaryFont();
    color: $white;
    line-height: 3rem;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
  }

  .border-line {
    content: '';
    display: inline-block;
    width: 312px;
    border-bottom: 1px solid $silver-2;
    text-align: center;
    margin-left: 10px;
  }

  /**  Start SiginInDetailModel  **/
  .siginDetailsInModel {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;

    .sigininText {
      padding: 0;

      h3 {
        font-size: 1.8rem;
      }
    }

    .siginInForm {
      padding-top: 15px;
    }

    .sigin-in-content {
      display: block;
      width: 310px;
      height: 42px;
      margin: 0 auto 10px;
      border: 1px solid black;
      background: $white;
      text-align: center;
      border-radius: 2px;

      a {
        line-height: 4.4rem;
        @include primaryFont();
        font-size: 1.2rem;
      }
    }

    .siginInButton {
      padding: 6px 31px;
    }
  }

  /**  End SiginInDetailModel  **/
  .SignInSignUpModel {
    .siginInCloseModel {
      margin-right: 20px;
      margin-top: -1px;
    }

    .arrow-up {
      top: -10px;
    }

    .SignInSignUpModelText {
      text-align: center;

      p {
        @include primaryFont();
        font-size: 1.2rem;
      }
    }

    .SignInSignUpModelForm {
      text-align: center;
      margin-top: 42px;

      p {
        @include primaryFont();
        font-size: 1.4rem;
        font-weight: 700;
      }
    }

    .border-line {
      display: inline-block;
      width: 92%;
      border-bottom: 1px solid $silver-2;
      margin-left: 4%;
      text-align: center;
      margin-top: 30px;
    }

    .login-account-siginin {
      display: inline-block;
      height: 30px;
      padding: 8px 42px;
      letter-spacing: 0;
      -webkit-border-radius: 2px;
      background-color: $alizarin-crimson;
      border-radius: 2px;
      margin-top: 20px;

      .siginButtonText {
        margin: 0;
      }
    }

    .text-center {
      text-align: center;
    }

    .create-account-siginin-stepOne {
      @extend .login-account-siginin;
      padding: 6px 7px;
      border: 2px solid $dark-gray;
      background-color: $white;
      margin-top: 20px;

      .siginButtonText {
        margin: 0;
        color: $dark-gray;
        line-height: 3.4rem;
      }
    }

    .new-member {
      @include primaryFont();
      font-size: 1.4rem;
      font-weight: 700;
      margin-top: 43px;
    }
  }
}

@media only screen and (min-width: 1500px) {
  .signin_white_content {
    right: auto;
    left: 1050px;
  }
}

body.modalOpend {
  overflow: hidden;
  height: 100%;
}

@screen lgr {
  .signin_black_overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    display: none;
    width: 100%;
    height: 100%;
    -moz-opacity: 0.8;
    opacity: 0.8;
    z-index: 99999;
    background-color: black;
    filter: alpha(opacity=80);
  }

  .signin_white_content {
    position: absolute;
    top: 12%;
    left: 11%;
    display: none;
    width: 287px;
    height: 427px;
    padding: 7px 0;
    background: url('../assets/images/account-bg.png');
    z-index: 999999;

    .siginInCloseModel {
      content: ' ';
      float: right;
      display: inline-block;
      width: 15px;
      height: 15px;
      background: url('../assets/images/siginin-close.png') no-repeat;
      margin-right: 8px;
      margin-top: -3px;
      cursor: pointer;

      &:hover {
        cursor: pointer;
      }
    }

    .siginInForm {
      padding-top: 15px;
      padding-bottom: 16px;
    }

    .input-data {
      text-align: center;
    }

    input[type='text'],
    input[type='email'],
    input[type='password'] {
      width: 93%;
      height: 39px;
      padding: 14px 9px;
      border: none;
      color: #000;
      font-family: 'brandon_regular', 'Helvetica Neue', Arial, sans-serif;
      background-color: #fff;
      text-transform: uppercase;
      margin-left: 8px;
      margin-bottom: 11px;
      border-radius: 3px;
    }

    &::-webkit-input-placeholder {
      color: #000;
      font-family: 'brandon_regular', 'Helvetica Neue', Arial, sans-serif;
      font-size: 1.1rem;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: #000;
      font-family: 'brandon_regular', 'Helvetica Neue', Arial, sans-serif;
      font-size: 1.1rem;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #000;
      font-family: 'brandon_regular', 'Helvetica Neue', Arial, sans-serif;
      font-size: 1.1rem;
    }

    &:-ms-input-placeholder {
      color: #000;
      font-family: 'brandon_regular', 'Helvetica Neue', Arial, sans-serif;
      font-size: 1.1rem;
    }

    .forget-password {
      display: block;
      color: #999;
      font-family: 'brandon_regular', 'Helvetica Neue', Arial, sans-serif;
      font-size: 1.1rem;
      font-style: italic;
      letter-spacing: -0.1px;
      text-align: right;
      padding-right: 9px;
      padding-top: 2px;
      padding-bottom: 29px;
    }

    .siginInButton {
      display: inline-block;
      height: 30px;
      padding: 8px 38px;
      letter-spacing: 0;
      border-radius: 2px;
      background-color: #e74c3c;
    }

    .siginButtonText {
      margin: 0 0 0 10px;
      color: #fff;
      font-family: 'brandon_bold', 'Helvetica Neue', Arial, sans-serif;
      font-size: 1.4rem;
      line-height: 3rem;
      font-weight: bold;
      font-style: normal;
    }

    .siginInModel {
      position: relative;
      margin-bottom: 0;
      padding-left: 10px;
      padding-right: 10px;

      .border-line {
        content: '';
        display: inline-block;
        width: 252px;
        border-bottom: 1px solid #c2c2c2;
        text-align: center;
        margin-left: 10px;
      }

      .text-center {
        text-align: center;
        padding-top: 28px;

        a {
          display: inline-block;
          width: 266px;
          font-family: 'brandon_bold', 'Helvetica Neue', Arial, sans-serif;
          font-size: 1.4rem;
          font-weight: bold;

          &.new-member {
            color: #000;
            letter-spacing: -0.5px;
          }

          &.create-account {
            color: #e74c3c;
            letter-spacing: 0.4px;
            padding-top: 8px;
            text-decoration: underline;
          }
        }
      }

      .siginInForm {
        padding-top: 21px;

        input {
          margin-bottom: 17px;
        }
      }
    }

    .siginDetailsInModel {
      position: relative;
      padding-left: 10px;
      padding-right: 10px;

      .sigininText {
        padding: 0;

        h3 {
          padding: 7px;
          font-size: 1.8rem;
        }
      }

      .siginInForm {
        padding-top: 8px;
      }

      .sigin-in-content {
        display: block;
        width: 250px;
        height: 38px;
        margin: 0 auto 14px;
        border: 2px solid black;
        text-align: center;
        border-radius: 2px;

        a {
          font-family: 'brandon_medium', 'Helvetica Neue', Arial, sans-serif;
          font-size: 1.2rem;
          line-height: 4.4rem;
          font-weight: bold;
        }
      }

      .input-data {
        margin-top: 25px;
      }

      .siginInButton {
        padding: 6px 31px;
      }
    }

    .createInModel {
      position: relative;
      padding-left: 10px;
      padding-right: 10px;

      span {
        &.arrow-up {
          top: -19px;
        }
      }

      .signinHeader {
        margin-top: 2px;
      }

      .siginInCloseModel {
        margin-top: 4px;
      }

      a {
        &.back {
          display: inline-block;
          color: #8e8e8e;
          font-size: 1rem;
          margin-top: 2px;
        }
      }

      .backicon {
        content: '';
        vertical-align: middle;
        display: inline-block;
        width: 10px;
        height: 11px;
        background: url('../assets/images/back_arrow.png') no-repeat;
        margin-left: 5px;
      }

      .sigininText {
        padding-bottom: 9px;
        margin-top: -8px;

        h3 {
          font-size: 1.8rem;
          letter-spacing: 0.3px;
          word-spacing: 3.5px;
        }
      }

      .siginInForm {
        padding-top: 4px;
      }

      .input-placeholder {
        position: relative;

        input:valid {
          & + placeholder {
            display: none;
          }
        }
      }

      .placeholder {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 21px;
        height: 18px;
        margin: auto;
        color: #000;
        font-family: 'brandon_regular', 'Helvetica Neue', Arial, sans-serif;
        font-size: 1.1rem;
        font-weight: bold;
        letter-spacing: 0.7px;
        pointer-events: none;

        span {
          color: red;
        }
      }

      .input-half-width {
        display: inline;
        width: 43%;

        & > input {
          display: inline-block;
          width: 44%;
          padding-left: 13px;
        }

        .placeholder {
          left: 21px;
        }

        .input-second-div {
          input {
            margin-left: 8px;
          }
        }

        p {
          &.required {
            float: right;
            color: #888;
            font-family: 'brandon_regular', 'Helvetica Neue', Arial, sans-serif;
            font-size: 1.1rem;
            font-style: italic;
            margin-right: 8px;
            margin-top: -10px;

            span {
              color: red;
            }
          }
        }
      }

      .create-account {
        margin-top: 8px;
      }

      .siginInButton {
        padding: 5px 21px;
      }

      .siginButtonText {
        font-size: 1.2rem;
        letter-spacing: 0.8px;
      }
    }

    .create-step-two {
      .siginInForm {
        padding-left: 5px;

        p {
          margin: 0 0 3px;
          font-family: 'brandon_regular', 'Helvetica Neue', Arial, sans-serif;
          font-size: 1.2rem;
          letter-spacing: 0.4px;
          padding-top: 2px;
        }
      }

      .security-answer {
        width: 255px;
        font-size: 1.3rem;
        line-height: 1.4rem;
        letter-spacing: 0.7px;
        padding-top: 4px;
        text-transform: none;
        padding-bottom: 9px;
      }

      .select-wrapper {
        &.gray {
          width: 253px;
          margin-bottom: 8px;
          padding-top: 5px;

          select {
            width: 100%;
            height: 41px;
            padding: 12px;
            border: none;
            font-size: 1.1rem;
            letter-spacing: 0.3px;
            background-position: 95% 50%;
            background-color: white;
            border-radius: 2px;
          }
        }
      }

      .input-placeholder {
        margin-left: -8px;
        margin-right: -1px;

        .placeholder {
          font-weight: normal;
        }
      }

      p {
        &.required {
          margin-right: 7px;
          margin-top: -14px;
        }
      }

      .custom-check {
        label {
          float: left;
          font-family: 'brandon_regular', 'Helvetica Neue', Arial, sans-serif;
          font-size: 1rem;
          font-weight: normal;
        }
      }

      input[type='checkbox'] {
        & + label {
          margin: 0;
          line-height: 1.4rem;
          letter-spacing: 0.1px;
          padding-top: 1px;
        }
      }

      .sigininText {
        margin-left: 19px;
      }

      .fe {
        margin: 0 0 10px;
      }

      .create-account {
        margin-top: 28px;
      }

      .checkboxContainer {
        margin-top: 18px;

        input {
          & + label {
            vertical-align: middle;
            display: inline-block;
            background: url('../assets/images/radio_off.png') left top no-repeat;
            font-size: 1.1rem;
            line-height: 1.1rem;
            font-weight: normal;
            letter-spacing: 0.4px;
            text-indent: 0;
            min-height: 14px;
            background-size: 17px;
            padding-left: 28px;
          }
        }

        input[type='checkbox'] {
          & + label {
            background-image: url('../assets/images/radio_on.png');
          }
        }
      }

      .radioContainer {
        input {
          & + label {
            vertical-align: middle;
            display: inline-block;
            background: url('../assets/images/radio_off.png') left top no-repeat;
            font-size: 1.1rem;
            line-height: 1.1rem;
            font-weight: normal;
            letter-spacing: 0.4px;
            text-indent: 0;
            min-height: 14px;
            background-size: 17px;
            padding-left: 28px;
          }
        }
      }

      .siginInButton {
        padding: 5px 11px;
        margin-left: -8px;
      }

      .siginButtonText {
        margin: 0;
      }
    }

    .forgotPasswordModel {
      position: relative;
      padding-left: 10px;
      padding-right: 10px;

      h3 {
        font-size: 1.8rem;
        letter-spacing: 0.6px;
        margin-top: 3px;
      }

      label {
        display: block;
        width: 250px;
        font-family: 'brandon_regular', 'Helvetica Neue', Arial, sans-serif;
        font-size: 1.4rem;
        letter-spacing: 0.2px;
        padding-bottom: 11px;
        text-transform: none;
        text-align: left;
        margin-left: 9px;
      }

      .siginInForm {
        padding-top: 53px;

        p {
          font-family: 'brandon_regular', 'Helvetica Neue', Arial, sans-serif;
          font-size: 1.4rem;
          padding-left: 9px;

          &.security-question {
            letter-spacing: -0.1px;
            text-transform: none;
            padding-bottom: 0;
          }
        }
      }

      .siginInButton {
        padding: 8px 28px;
        letter-spacing: 0.9px;
        background-color: #3cb878;
      }

      .input-data {
        padding-top: 10px;

        &.forgotpassword {
          margin-top: 30px;
        }
      }

      input[type='text'] {
        letter-spacing: 0.5px;
        margin-left: 8px;
      }

      &.forgot-password-step-one {
        .siginInForm {
          padding-top: 44px;

          label {
            padding-bottom: 6px;
          }
        }

        .siginButtonText {
          font-size: 1.2rem;
        }

        input[type='text'] {
          margin-left: 9px;
        }

        input[type='email'] {
          margin-left: 9px;
        }

        .input-data {
          &.forgotpassword {
            margin-top: 43px;
          }
        }
      }

      &.forgot-password-step-two {
        label {
          letter-spacing: -0.1px;
          text-transform: uppercase;
          text-align: left;
        }

        .input-data {
          padding-top: 4px;
          margin-top: -14px;

          &.forgotpassword {
            margin-top: 48px;
          }
        }
      }

      &.forgot-password-step-three {
        .siginInForm {
          margin-top: 29px;

          p {
            &.security-question {
              letter-spacing: -0.1px;
              padding-left: 12px;
            }
          }
        }

        .input-data {
          &.forgotpassword {
            margin-top: 42px;
          }
        }

        .siginInButton {
          padding: 8px 22px;
          letter-spacing: 0;
        }
      }

      &.forgot-password-step-four {
        input[type='email'] {
          font-size: 1.1rem;
          letter-spacing: 0.3px;
          word-spacing: 0.2px;
          margin-left: 0;
        }

        input[type='text'] {
          font-size: 1.1rem;
          letter-spacing: 0.3px;
          word-spacing: 0.2px;
          margin-left: 0;
        }

        input[type='password'] {
          font-size: 1.1rem;
          letter-spacing: 0.3px;
          word-spacing: 0.2px;
          margin-left: 0;
        }

        .siginInButton {
          padding: 8px 41px;
          letter-spacing: 0;
          background-color: #e74c3c;
        }

        .siginButtonText {
          font-size: 1.2rem;
        }

        .siginInForm {
          padding-top: 43px;

          input {
            margin-left: 10px;
          }
        }

        .input-data {
          padding-top: 0;

          &.forgotpassword {
            margin-top: 32px;
          }
        }
      }

      &.forgot-password-step-five {
        input[type='email'] {
          font-size: 1.1rem;
          letter-spacing: 0.3px;
          word-spacing: 0.2px;
          margin-left: 0;
        }

        input[type='text'] {
          font-size: 1.1rem;
          letter-spacing: 0.3px;
          word-spacing: 0.2px;
          margin-left: 0;
        }

        input[type='password'] {
          font-size: 1.1rem;
          letter-spacing: 0.3px;
          word-spacing: 0.2px;
          margin-left: 0;
        }

        .siginInButton {
          padding: 8px 41px;
          letter-spacing: 1px;
          background-color: #e74c3c;
        }

        .siginButtonText {
          font-size: 1.2rem;
        }

        .input-data {
          padding-top: 0;

          &.forgotpassword {
            margin-top: 38px;
          }
        }

        .siginInForm {
          padding-top: 30px;

          input {
            margin-left: 8px;
          }
        }
      }
    }

    span {
      &.arrow-up {
        content: ' ';
        position: absolute;
        top: -17px;
        left: 213px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #e6e6e6;
      }
    }

    .sigininText {
      font-family: 'brandon_regular', 'Helvetica Neue', Arial, sans-serif;
      font-size: 1.2rem;
      text-align: center;
      text-transform: uppercase;
      padding-bottom: 3px;

      p {
        margin: 0;
        font-family: 'brandon_regular', 'Helvetica Neue', Arial, sans-serif;
        font-size: 1.2rem;
        word-spacing: 2.3px;
      }

      h3 {
        font-family: 'brandon_bold', 'Helvetica Neue', Arial, sans-serif;
        font-size: 2.4rem;
        word-spacing: 0.5px;
        margin-top: -5px;
      }
    }
  }
}
