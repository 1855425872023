/* Misc */

@media all and (max-width: 1330px) {
  #hto {
    #hto-header {
      .ctas {
        width: 440px !important;
        margin: 0 0 0 -214px !important;

        p {
          display: inline-block;
          margin: 15px 10px !important;
        }
      }
    }
  }

  #footer {
    padding-bottom: 80px;

    #legal {
      width: 70%;
      padding: 0 15%;

      #country-selector {
        left: 15%;
      }

      .legal {
        bottom: -60px;
      }
    }
  }
}

/* = @media 600 - 724
-------------------------------------------------------------------------------- */

@media (max-height: 723px) {
  #main-navigation-container {
    #header_logo {
      margin-bottom: 40px;
    }

    #bottom-links {
      position: relative;
      z-index: 1;

      .contact-info {
        display: none;
      }
    }

    .push,
    #free-shipping {
      position: relative;
      height: 190px;
    }

    #get-connected-flyout {
      z-index: 2;
    }

    #main-navigation {
      .flyout {
        h2,
        h3 {
          margin-bottom: 8px;
        }

        .links {
          margin-bottom: 20px;

          &.stacked {
            li {
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }
}

/* = @media < 678
-------------------------------------------------------------------------------- */

@media (max-height: 678px) {
  #main-navigation-container {
    #bottom-links {
      padding-top: 10px;

      li {
        &.connected {
          .redesignIcons-email {
            display: none;
          }
        }

        &.store-locations-link {
          margin-top: 18px;
        }
      }
    }

    .push,
    #free-shipping {
      height: 158px;
    }
  }
}
