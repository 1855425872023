/* stylelint-disable */
@import '../mixins/mixins';

#home {
  .row {
    position: relative;
    clear: both;
    float: left;
    width: 100%;
  }

  #pagers {
    position: absolute;
    right: 50px;
    bottom: 50px;
    z-index: 3;
    list-style-type: none;

    li {
      @include rounded(10px);
      float: left;
      width: 10px;
      height: 10px;
      margin: 0 0 0 5px;
      border: 1px solid $dusty-gray-3;
      background: transparent;
      cursor: pointer;

      &:first-child {
        margin: 0;
      }

      &.current {
        background-color: $dark-gray;
        cursor: default;
      }
    }
  }

  .slide {
    width: 100%;

    h2 {
      @include primaryFont();
      font-size: 46px;
      font-weight: bold;
      line-height: 4.2rem;
    }

    p {
      @include primaryFont();
      font-size: 1.4rem;
      font-weight: bold;
      margin: 50px 0;
      line-height: 1.8rem;
    }

    .copy {
      position: relative;
      top: 170px;
      left: 50px;
      width: 362px;
      z-index: 1;
      text-align: center;
    }

    .img {
      position: relative;
      z-index: 0;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .wide-img-copy {
    text-align: center;
    float: right;
    padding: 4% 0 0;

    p {
      @include primaryFont();
      font-weight: bold;
      padding: 0 0 10px;
      line-height: 1.8rem;
    }
  }

  .hover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    + img {
      position: relative;
      z-index: 0;
    }
  }

  #sliderContainer {
    position: relative;
    background-color: $concrete;
    padding: 0;

    .circle {
      @include rounded(290px);
      position: absolute;
      left: 50%;
      width: 290px;
      height: 290px;
      margin: 30% 0 0 -145px;
      background-color: $white;
    }

    .item {
      padding-bottom: 20px;
      min-height: 0;
      width: 100%;

      .catalog-product-rating,
      .new {
        display: none;
      }

      .main-img {
        display: block;
        margin: 30% 0 17%;
        min-height: 260px;
      }

      .details {
        p,
        a,
        .price {
          font: {
            size: 14px;
          }
        }
      }
    }

    .owl-buttons {
      display: none !important;
    }

    .owl-controls {
      position: absolute;
      top: 50px;
      width: 100%;
      text-align: center;
      clear: both;

      div {
        display: inline-block;
        margin: 0 5px 0 0;

        span {
          @include rounded(10px);
          display: block;
          width: 10px;
          height: 10px;
          border: 1px solid $dusty-gray-3;
          text-indent: -9999px;
        }

        &.active {
          span {
            background-color: $dark-gray;
            border-color: $dark-gray;
          }
        }
      }
    }
  }
}

body.home.overflow-hidden.hide-fake-input .sgh-header-top {
  z-index: 0;
}
