@import '../../utils/utils';
sgh-service-carousel {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.82rem;
  padding: 7.2rem 4rem;
  position: relative;
  min-height: 68rem;
  overflow: hidden;
  margin-top: 4rem;
  @screen mdr {
    display: flex;
    flex-direction: row;
    padding: 7.2rem 0;
  }

  .skeleton-carousel {
    position: absolute;
    top: 1.6rem;
    padding-left: 4rem;
    @screen mdr {
      padding-left: 0;
      left: 1.6rem;
    }
    &__title {
      margin-bottom: 2.4rem;
      height: 1.4rem;
      width: 20rem;
      @include skeleton-animation;
    }
  }

  swiper-slide {
    @screen mdr {
      width: 30rem;
    }
    .skeleton {
      &__image {
        width: 100%;
        height: 42.6rem;
        @screen mdr {
          width: 30rem;
          height: 22.4rem;
        }
        @include skeleton-animation;
      }
      &__text {
        width: calc(100% - (100% / 5));
        @screen mdr {
          width: 100%;
          padding-left: 1.6rem;
        }
      }
      &__pretitle {
        margin-top: 1.6rem;
        height: 1.2rem;
        width: 100%;
        @include skeleton-animation;
      }
      &__title {
        width: 100%;
        height: 2.8rem;
        margin-top: 1.3rem;
        @include skeleton-animation;
      }
      &__desc {
        padding: 0.4rem 0;
        height: 1.4rem;
        width: 100%;
        @include skeleton-animation;
      }
      &__cta {
        height: 1.4rem;
        margin-top: 1.6rem;
        width: 30%;
        @include skeleton-animation;
      }
    }
  }
}
