/* stylelint-disable */
@import '../mixins/mixins';

#compare {
  text-align: center;

  h2 {
    @include primaryFont();
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0 0 30px;
    line-height: 3rem;
  }

  #product-container {
    width: 100%;
    margin: 0 auto;

    .item {
      width: 23%;
      margin: 0 0 80px;
      padding: 60px 1% 10px;

      img {
        max-width: 215px;
      }

      .addToCart {
        margin: 0 0 20px;
      }

      .remove {
        .icon {
          display: block;
          margin: 0 auto 5px;
        }
      }
    }

    &.three {
      width: 75%;

      .item {
        width: 31%;
      }
    }

    &.two {
      width: 50%;

      .item {
        width: 46%;
      }
    }

    &.one {
      width: 25%;

      .item {
        width: 100%;
      }
    }
  }

  #no-compare {
    margin: 0 0 60px;

    h2 {
      @include primaryFont();
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
}
