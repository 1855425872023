@import '../fonts';

.reass-container {
  display: flex;
  height: fit-content;
  width: 100%;
  flex-direction: column;

  &__element {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    height: fit-content;
    width: 100%;
    border-bottom: 1px solid #dadada;
    &:first-child {
      border-top: 1px solid #dadada;
    }
    &__icon-small {
      margin-top: 15px;
      margin-right: 11px;
      height: 24px;
      width: 24px;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      flex-grow: 1;
      height: fit-content;
      &__title {
        font-size: 1.4rem;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: fit-content;
        margin-top: 15px;
        margin-bottom: 0px;
      }
      &__desc {
        width: 100%;
        height: fit-content;
        font-size: 1rem;
        color: #555555;
        margin-top: 2px;
        margin-bottom: 15px;
      }
      p {
        @include primaryFont();
      }
    }
    &__btn {
      cursor: pointer;
      margin-top: 19px;
      margin-left: 17px;
      svg {
        height: 12px;
        width: 12px;
      }
    }
  }
}

.reass-popup {
  display: none;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  padding: 24px;
  padding-top: 60px;
  overflow-y: auto;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }

  &__window {
    margin-bottom: 15%;
    z-index: 1;
    padding-top: 12px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    background-color: white;
    position: absolute;
    width: 85%;
    max-width: 725px;

    &__close-btn {
      transform: translateX(12px);
      align-self: flex-end;
      font-weight: 600;
      font-size: 1.8rem;
      cursor: pointer;
      @media (min-width: 1025px) {
        margin-right: 12px;
      }
    }

    .reass-container__element__icon-big {
      margin-top: 12px;
      margin-right: 6px;
      height: 40px;
      width: 40px;
      img {
        height: 100%;
        width: 100%;
      }
    }

    .reass-container__element__text__title {
      font-weight: 400;
      font-size: 18;
    }

    .reass-container__element__text__desc {
      color: #555555;
      letter-spacing: 0.4px;
    }

    &__info {
      margin-top: 18px;
      overflow-y: auto;
      font-size: 1.4rem;
      color: #555555;
    }
  }
}

.reassuranceScrollbar {
  @media (min-width: 1025px) {
    scrollbar-color: #f6f6f6 #f6f6f6f6;
    scrollbar-width: thin;
    padding-right: 16px;
    max-height: 314px;

    &::-webkit-scrollbar {
      width: 7px;
      background-color: #f6f6f6;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #4a4a4a;
      border-radius: 6px;
    }

    ::-webkit-scrollbar-button {
      display: none;
    }
  }
}
