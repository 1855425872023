/* stylelint-disable */
@import '../mixins/mixins';

#cart-body.thanks {
  .product-details {
    a p.brand,
    a p.style {
      float: left;
      width: 100%;
      clear: both;
    }
  }

  .nco_thankyou--create-account {
    margin: 3rem 0;
  }

  .nco_thankyou--printPaymentInstructions {
    border-bottom: 1px solid $alto;
    padding-bottom: 3rem;
    p {
      font-size: 1.6rem;
      color: $emperor;
      margin-bottom: 1.5rem;
    }
    .btn {
      min-width: 150px;
    }
  }

  .cart-engr-title {
    color: $anzac !important;
    @include primaryFont();
    font-size: 1.2rem;
    font-weight: bold;

    img.cart-engr-desk__icon {
      width: auto;
      margin-right: 5px;
    }
  }

  #order_details .product-details .cart-engr-desk {
    padding-top: 0 !important;
  }

  #order_details .delivery-details {
    margin-bottom: 60px;
  }

  .cart-engr-desk__head {
    @include primaryFont();
    color: $dark-gray;
    font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: 3px !important;

    .cart-engr-desk__tit {
      padding-left: 0 !important;
      margin-left: 0 !important;
      @include primaryFont();
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .cart-engr-desk__other-info {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  span.cart-engr-desk__other-info__item__left {
    float: left;
    width: 81px;
    color: $alto-5;
  }

  .cart-engr-desk__policy {
    clear: both;
    text-transform: none;
    color: $dusty-gray;

    a {
      color: $dusty-gray;
      text-decoration: underline;
    }
  }

  .cart-engr-desk__texts__item__left {
    float: left;
    width: 81px;
    color: $alto-5;
  }
}

#ShopCartDisplay .shopping-cart {
  .product-details {
    .cart-engr-title {
      color: $anzac !important;
      @include primaryFont();
      font-size: 1.2rem;
      font-weight: bold;

      img.cart-engr-desk__icon {
        width: auto;
        margin-right: 5px;
      }
    }

    a p.brand,
    a p.style {
      float: left;
      width: 100%;
      clear: both;
    }
  }

  .nco_thankyou--create-account {
    margin-bottom: 33px;
  }

  #order_details .product-details .cart-engr-desk {
    padding-top: 0 !important;
  }

  .cart-engr-desk__head {
    @include primaryFont();
    color: $dark-gray;
    font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: 3px !important;

    .cart-engr-desk__tit {
      padding-left: 0 !important;
      margin-left: 0 !important;
      font-size: 1.2rem;
      @include primaryFont();

      .cart-engr-desk__info {
        color: $dusty-gray;
        text-transform: none;
      }
    }
  }

  .cart-engr-desk__other-info {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  span.cart-engr-desk__other-info__item__left {
    float: left;
    color: $alto-5;
  }
}

@screen lgr {
  .thankYouPage {
    p,
    li {
      margin: 0;
      padding: 0;
      line-height: 2rem;
      text-transform: none;
    }

    #content-footer {
      p,
      li {
        text-transform: uppercase;
      }
    }

    h1 {
      margin: 40px 0 0;
      font-size: 2.5rem;
      font-weight: bold;
      text-align: center;

      &:after {
        content: ' ';
        display: block;
        width: 10px;
        height: 3px;
        margin: 25px auto;
        background: $black;
      }
    }

    .thankYouMessage {
      text-align: center;
      @include bottomBorder();
      padding: 0 20px;
      padding-bottom: 30px;
    }

    #order_info {
      padding: 25px 20px;
      @include bottomBorder();

      p {
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      .OrderNumber_disclaimer {
        color: $dusty-gray-3;
      }

      .orderNo {
        font-weight: bold;

        span {
          color: $cinnabar;
        }
      }

      .htosteps {
        text-align: center;
      }

      .complete {
        color: #090;
        font-weight: bold;
      }

      .step1 {
        width: 48px;
        height: 48px;
        margin: 10% auto;
        background-image: url('../assets/images/buttons_sprites-confirmpage.png');
        background-position: 0 -1461px;
        background-size: 150px auto;
      }

      .step2 {
        width: 48px;
        height: 48px;
        margin: 10% auto;
        background-image: url('../assets/images/buttons_sprites-confirmpage.png');
        background-position: 0 -1514px;
        background-size: 150px auto;
      }

      .step3 {
        width: 48px;
        height: 48px;
        margin: 10% auto;
        background-image: url('../assets/images/buttons_sprites-confirmpage.png');
        background-position: 0 -1145px;
        background-size: 150px auto;
      }

      .step4 {
        width: 48px;
        height: 48px;
        margin: 10% auto;
        background-image: url('../assets/images/buttons_sprites-confirmpage.png');
        background-position: 0 -1407px;
        background-size: 150px auto;
      }

      .note {
        color: #a7a7a7;
        margin-top: 20px;
      }
    }

    p strong {
      text-transform: uppercase;
    }

    .createAccountInfo {
      padding: 25px 20px;
      @include bottomBorder();

      ul {
        padding: 25px 0;
      }
    }

    .promisesInfo {
      padding: 25px 20px;

      ul {
        padding: 25px 0 0;
      }
    }

    #continue_shopping_container {
      margin: 0 0 30px;
      padding: 0 20px;
    }
  }
}
