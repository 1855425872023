/* stylelint-disable */
@import '../mixins/mixins';

.newCheckout {
  #content_wrapper_box {
    margin-top: 0 !important;

    .nco_thankyou {
      padding: 52px 0 16px !important;

      &--privacy-url {
        text-decoration: underline;
        text-transform: none;
      }
    }

    .shopping-recap-details .summary-bar {
      height: 44px;
      padding: 14px 20px 6px;
      background: $dark-gray;
    }

    .summary-bar {
      @include primaryFont();
      font-size: 1.4rem;
      text-transform: none;
    }
  }
}

.cart-engr-conditions__err {
  display: none;
  margin: 1rem 2.3rem 0;
  padding: 1.2rem 1.6rem;
  color: $monza;
  @include primaryFont();
  font-size: 1.6rem;
  line-height: 1.38;
  text-align: left;

  @screen lgr {
    display: none;
    margin: 2.2rem 0 1.6rem;
    padding: 1.2rem 1.6rem;
    border: solid 0.1rem #e74c3c;
    color: #111;
    @include primaryFont();
    font-size: 1.6rem;
    line-height: 1.38;
    text-align: left;
    text-transform: none;
    margin-top: -1.9rem !important;
  }
}

#mobileShippingSelect {
  padding-top: 0;
}