@import 'mixins';

.cms-wall-slider {
  padding: 0.8rem 0.75rem 0.8rem 0.75rem;
  @screen lg {
    padding: 1.6rem 0 0 0 !important;
  }
  .swiper-wrapper {
    row-gap: 3rem;
    @screen lg {
      row-gap: 4rem;
    }
  }

  .swiper-button-next {
    right: 0.2rem;
  }

  .swiper-button-prev {
    left: 0.2rem;
  }

  .swiper-button-next,
  .swiper-button-prev {
    &::after {
      font-size: 2rem !important;
    }
  }

  @include banner-picture;
  margin: 0 auto;

  .picture {
    padding-top: calc(90 / 180) * 100%;
    width: calc(100% - 4rem);
  }

  &--brands {
    .picture__image {
      object-fit: contain;
      filter: grayscale(100%);
      transition: opacity 0.3s ease;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }
  }

  &-cta {
    display: none;
  }

  &__slide {
    cursor: default;
  }

  .cms-wall-slider__slide {
    &:nth-child(even) {
      padding-left: 0.953rem;
    }
    &:nth-child(odd) {
      padding-right: 0.953rem;
    }
    @screen lg {
      padding-left: 1.44rem;
      padding-right: 1.44rem;
      padding-bottom: 0 !important;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  .sgh-cms-product-tile--wall {
    .picture {
      margin-bottom: 0.4rem;
      padding-top: 0;
      width: 100%;
      max-width: 18rem;
      height: auto;
      min-height: 9rem;
      @screen lg {
        margin-bottom: 0.8rem;
      }
      &__wrapper,
      &__image {
        position: relative;
        transform: none;
        left: 0;
        top: 0;
      }

      &__image {
        opacity: 1;
      }

      &__image--hover {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        display: block;
        opacity: 0;
      }
    }

    .brand {
      text-transform: uppercase;
      margin-bottom: 0.2rem;
      white-space: nowrap;
      font-size: 1.2rem;
      line-height: 1.8rem;
    }

    .price {
      line-height: 2.1rem;
      margin-bottom: 0.4rem;
      &__current {
        color: $mine-shaft;
        font-weight: 400;
        @screen lg {
          font-weight: 500;
        }
      }
    }
    .details {
      .cta {
        font-size: 1.4rem;
        padding: 1rem;
        height: 4rem;
        width: 12.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: capitalize;
      }
      .price__original + .price__current {
        color: $red-berry-2;
      }

      a.tertiary-2023 {
        @screen mdr {
          height: auto;
          text-decoration: underline;
          border: none;
        }
      }
    }
  }

  .swiper-pagination-bullet {
    &:first-child:last-child {
      display: none;
    }
  }

  .swiper-pagination {
    @screen lgr {
      margin-top: 4rem;
    }
  }

  @screen sm {
    .swiper-pagination {
      display: block;
    }

    &-cta {
      display: block;
    }
  }

  @screen md {
    .swiper-button-prev,
    .swiper-button-next {
      display: block;
    }

    .swiper-button-disabled {
      display: none;
    }

    .sgh-cms-product-tile--wall {
      .details,
      .picture__image,
      .picture__image--hover {
        transition: opacity 0.3s ease;
      }

      &:hover .picture__image--hover {
        opacity: 1;
      }

      &:hover .picture__image {
        opacity: 0;
      }
    }
  }
}
