@import 'mixins';
@import '../../variables';

/* stylelint-disable no-descending-specificity */
.cms-text-align-center {
  .cms-text-module__wrapper {
    h2,
    p {
      text-align: center;
    }
  }
}

.cms-text-align-right {
  .cms-text-module__wrapper {
    h2,
    p {
      text-align: right;
    }
  }
}

@mixin banner-sized-content {
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: 0;
  z-index: 10;
  padding-top: calc(626 / 960) * 100%;
  .cms-text-module__wrapper {
    position: absolute;
    width: 70%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
}

.cms-row-carousel-board {
  sgh-swiper {
    display: flex;
  }
  .three-slides {
    .swiper-wrapper {
      .banners__slide {
        @screen md {
          min-width: 0;
        }
      }
    }
  }
  &.cms-row-carousel-below {
    color: $mine-shaft;
    width: 100%;
    content-visibility: auto;
    contain-intrinsic-size: auto none;

    .cms-text-module {
      padding: 1.6rem;

      &__title,
      &__text {
        margin-top: 0.8rem;
      }

      &__actions {
        margin-top: 1.6rem;
      }
    }
  }

  width: 100%;

  .banners {
    margin: 0 auto;

    &__content {
      padding-bottom: 2.4rem;
    }

    .cms-text-module__text {
      font-size: theme('fontSize.base');

      @screen sm {
        font-size: theme('fontSize.base');
      }
    }

    &--text-above {
      .picture, .video {
        + .banners__content {
          @include banner-sized-content;
          position: absolute;
          top: 0;
          left: 0;
          max-height: 50%;
        }
      }
    }

    &__item {
      @include banner-all-medias;

      .picture,
      .video {
        aspect-ratio: 4/3;
      }

      &.cms-tap-hold {
        .picture {
          overflow: hidden;
        }

        .picture__wrapper,
        .video__wrapper {
          height: auto;
          width: auto;
        }

        .picture:hover .picture__image {
          transform: translateY(-50%);
        }
      }

      .cms-text-module {
        &__wrapper {
          justify-content: center;
        }

        &__title {
          font-weight: 100;
        }
      }

      &--top-left .cms-text-module__wrapper,
      &--top-center .cms-text-module__wrapper,
      &--top-right .cms-text-module__wrapper {
        justify-content: flex-start;
        padding-top: 2.4rem;
      }

      &--bottom-left .cms-text-module__wrapper,
      &--bottom-center .cms-text-module__wrapper,
      &--bottom-right .cms-text-module__wrapper {
        justify-content: flex-end;
        padding-bottom: 2.4rem;
      }

      &--top-left .cms-text-module__wrapper,
      &--center-left .cms-text-module__wrapper,
      &--bottom-left .cms-text-module__wrapper {
        left: 0;
        right: 30%;
      }

      &--top-center .cms-text-module__wrapper,
      &--center-center .cms-text-module__wrapper,
      &--bottom-center .cms-text-module__wrapper {
        left: 15%;
        right: 15%;
      }

      &--top-right .cms-text-module__wrapper,
      &--center-right .cms-text-module__wrapper,
      &--bottom-right .cms-text-module__wrapper {
        left: 30%;
        right: 0;
      }
    }

    &--text-above .banners__item {
      position: relative;
    }

    .swiper-button-disabled {
      display: none;
    }

    .swiper-button-prev,
    .swiper-button-next {
      padding: 20px;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-size: 2rem;
      color: $mine-shaft;
    }

    .swiper-pagination-bullets {
      bottom: 0;
    }
    &__slide {
      min-width: 100%;
      box-sizing: border-box;
    }
    &--text-above .banners__slide {
      position: relative;
    }

    &__content--no-picture {
      .cms-text-module__text {
        @include secondaryFont();
        font-size: 2.4rem;
        line-height: 1.25;
      }
    }
  }

  .sgh-tile .ratio-a {
    padding-top: calc(626 / 960) * 100%;
  }

  .sgh-tile__badge-container {
    min-height: 0;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }

  @screen md {
    .banners {
      justify-content: space-between;

      .swiper-container .banners__content--no-picture {
        @include banner-sized-content;
        transform: none;
        margin-top: 0;
      }
      .swiper-wrapper {
        gap: 0.8rem;
      }

      &__slide {
        flex: 1 1 calc(1 / 3 * 100%);
        min-width: calc(1 / 3 * 100%);
      }

      &__item {
        .cms-text-module__wrapper {
          justify-content: center;
        }

        &--top-left .cms-text-module__wrapper,
        &--top-center .cms-text-module__wrapper,
        &--top-right .cms-text-module__wrapper {
          justify-content: flex-start;
        }

        &--bottom-left .cms-text-module__wrapper,
        &--bottom-center .cms-text-module__wrapper,
        &--bottom-right .cms-text-module__wrapper {
          justify-content: flex-end;
        }
      }
    }

    .sgh-tile__badge-container {
      min-height: 0;
    }

    &--reversed {
      .swiper-wrapper {
        flex-direction: row-reverse;
      }

      .banners__slide:last-child {
        padding-right: 2rem;
        flex: 1 1 calc(1 / 3 * 100%);
        min-width: calc(1 / 3 * 100%);
      }

      .banners__slide:first-child {
        padding-right: 0;
        flex: 1 1 calc((1 / 3 * 100%) - 2rem);
        min-width: calc((1 / 3 * 100%) - 2rem);
      }
    }
  }
}

.dinamic-plp-container {
  .banners {
    .swiper-container {
      .swiper-wrapper {
        .banners__slide {
          min-width: 0;
          .banners__content--no-picture {
            aspect-ratio: 116/85;
            padding-top: 0;
          }
          .picture {
            aspect-ratio: 116/85;
          }
        }
      }
    }
  }

  .sgh-tile .ratio-a {
    div:not(.editorialImage) {
      padding-top: 0;
      aspect-ratio: 116/85;
    }
  }
}
