@import 'mixins';

.box-2-products,
.box-2-products-reversed {
  @include banner-picture;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__left {
    width: 100%;

    .picture {
      padding-top: calc(537 / 826) * 100%;
    }

    &__bigger-picture {
      width: 100%;
      position: relative;

      &-overlay {
        position: absolute;
        left: 0;
        top: 0;
        background: rgb(0, 0, 0);
        height: 100%;
        opacity: 0;
        transition: opacity 0.5s ease-in;
        z-index: 1;
      }

      &:hover .box-3-products__left__bigger-picture-overlay {
        opacity: 0.4;
      }
    }
  }

  &__right {
    width: 100%;
    padding: 0 (calc(1 / 12) * 100%);
  }

  .sgh-cms-product-tile--box {
    .picture {
      padding-top: calc(74 / 168) * 100%;
    }

    .brand,
    .price,
    .colors {
      font-size: 1.2rem;
    }

    .brand {
      font-weight: 500;
      text-transform: uppercase;
      margin: 2.4rem 0 0.8rem 0;
    }

    .price {
      margin-bottom: 0.5rem;
    }
  }
}

@media screen and (min-width: 769px) {
  .box-2-products,
  .box-2-products-reversed {
    @include banner-picture;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    &__right {
      width: calc(5 / 12) * 100%;
    }

    &__left {
      width: calc(7 / 12) * 100%;
      padding-bottom: 0;
    }
  }

  .box-2-products-reversed {
    @include banner-picture;
    flex-direction: row-reverse;
  }
}
