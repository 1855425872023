/* stylelint-disable */
@import '../mixins/mixins';

/* tipography */
.font-bold {
  font-weight: 700;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'sgh' !important;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alert:before {
  content: '\e900';
}

.icon-arrow-down:before {
  content: '\e901';
  color: $emperor;
}

.icon-arrow-dx:before {
  content: '\e902';
  color: $emperor;
}

.icon-arrow-dx-link:before {
  content: '\e903';
  color: $emperor;
}

.icon-arrow-dx-small:before {
  content: '\e904';
  color: $emperor;
}

.icon-arrow-sx:before {
  content: '\e905';
  color: $emperor;
}

.icon-arrow-sx-small:before {
  content: '\e906';
  color: $emperor;
}

.icon-arrow-up:before {
  content: '\e907';
  color: $emperor;
}

.icon-cart:before {
  content: '\e90d';
}

.icon-cart1:before {
  content: '\e919';
}

.icon-cart-arrow-right:before {
  content: '\e91a';
}

.icon-close:before {
  content: '\e90e';
}

.icon-logo:before {
  content: '\e90f';
}

.icon-logo-50:before {
  content: '\e91b';
}

.icon-mobile-menu:before {
  content: '\e910';
}

.icon-search:before {
  content: '\e911';
}

.icon-user:before {
  content: '\e912';
}

.icon-wishlist:before {
  content: '\e913';
}

/* .icon-multilayer - icons with more levels */
.icon-multilayer {
  display: inline-block;
}

.icon-multilayer:before,
.icon-multilayer:after {
  content: ' ';
  display: table;
  clear: both;
}

.icon-multilayer [class*='path']:before {
  float: left;
}

.icon-filter .path1:before {
  content: '\e914';
  color: rgb(34, 34, 34);
}

.icon-filter .path2:before {
  content: '\e915';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-filter .path3:before {
  content: '\e916';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-filter .path4:before {
  content: '\e917';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-filter .path5:before {
  content: '\e918';
  margin-left: -1em;
  color: rgb(34, 34, 34);
}

.icon-badge-close-engraving .path1:before {
  content: '\e908';
  float: left;
  color: rgb(206, 162, 77);
}

.icon-badge-close-engraving .path2:before {
  content: '\e909';
  color: rgb(255, 255, 255);
  margin-left: -1em;
}

.icon-badge-close-polarised .path1:before {
  content: '\e90a';
  float: left;
  color: none;
}

.icon-badge-close-polarised .path2:before {
  content: '\e90b';
  margin-left: -1em;
  color: rgb(51, 51, 51);
}

.icon-badge-close-polarised .path3:before {
  content: '\e90c';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

div.scrollWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

div.scrollableArea {
  position: relative;
  width: auto;
  height: 100%;
}

/**** BEGIN: STYLEGUIDES ****/
.container-custom {
  display: block;
  max-width: 1160px;
  margin: 0 auto;
}

.container-custom .col-20p {
  float: left;
  width: 20%;
}

.container-custom .col-33p {
  float: left;
  width: 33.333%;
}

@media screen and (max-width: 1160px) {
  .container-custom {
    padding: 0 15px;
  }
}

body {
  color: $emperor;
  @include primaryFont();
  font-size: 1.4rem;
  line-height: 1.36;
  font-weight: normal;
  letter-spacing: 0;
  padding-top: 10rem;
  &.block-body-scroll {
    @media screen and (max-width: 1023px) {
      position: fixed;
    }

    overflow: hidden;
  }

  
}

@media screen and (max-width: 1023px) {
  body {
    padding-top: 10.8rem;
  }
}

h1 {
  margin: 0;
  font-size: 3.2rem;
  line-height: 1.15;
  font-weight: 100;
  letter-spacing: normal;
}

h2 {
  margin: 0;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: normal;
}

h3 {
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.64;
  font-weight: 500;
  letter-spacing: normal;
}

h4,
h5,
h6 {
  margin: 0;
}

.btn {
  padding: 9px 10px;
  box-shadow: none;
  @include primaryFont();
  font-size: 1.2rem;
  line-height: normal;
  font-weight: normal;
  letter-spacing: normal;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  text-align: center;
  border-radius: 22px;
  min-width: 180px;
  text-shadow: none;
}

.btn.cta-primary {
  border: solid 1px $mine-shaft;
  background: $mine-shaft;
  color: $white;

  &:focus {
    color: $white;
  }
}

.btn.cta-secondary {
  border: solid 1px $emperor;
  background: $white;
  color: $emperor;
  .popup-checkout & {
    margin-top: -0.6rem;
  }
}

.btn.cta-images {
  border: solid 1px $white;
  background: rgba(34, 34, 34, 0.7);
  color: $white;
}

.btn.cta-short {
  margin: 0.5rem 0;
  padding: 0.9rem 1.2rem;
  min-width: initial;
  align-self: start;
}

.sgh-ts-content .btn.cta-short {
  margin-top: auto;
}

@media screen and (max-width: 767px) {
  .btn.cta-short {
    padding: 1.2rem;
  }

  .icon-close {
    &:before {
      content: '\e90e';
    }
  }
}
@media screen and (max-width: 375px) {
  .btn.cta-short {
    padding: 12px 15px;
  }
}
@media screen and (width: 320px) {
  .btn.cta-short {
    padding: 10px !important;
  }
}

.btn.cta-shorter {
  min-width: initial;
  padding: 9px 15px;
}

@media screen and (max-width: 767px) {
  .btn.cta-shorter {
    padding: 12px 15px;
  }
}

.btn.cta-text-md {
  font-size: 1.6rem;
}

.btn.cta-gold {
  border: solid 1px $anzac;
  background: $anzac;
  color: $white;
}

.btn.cta-gold:hover,
.btn.cta-gold:active {
  border: solid 1px #c79c3d;
  background: #c79c3d;
  color: $white;
}

.btn:hover,
.btn:active {
  border: solid 1px $emperor;
  background: $emperor;
  color: $white;
}

.btn:hover.cta-images,
.btn:active.cta-images {
  border: solid 1px $white;
}

.link {
  color: $emperor;
  @include primaryFont();
  font-size: 1.4rem;
  line-height: 1.64;
  font-weight: normal;
  letter-spacing: normal;
  -webkit-transition: right 0.4s;
  transition: right 0.4s;
  text-decoration: none;
}

.link:after {
  @include primaryFont();

  content: '\e902';
  position: relative;
  right: -8px;
  display: inline-block;
  width: 16px;
  height: 10px;
  color: $emperor;
  font-size: 0.9rem;
}

.link:hover,
.link:active,
.link:focus {
  color: $emperor;
  text-decoration: none;
}

.link:hover:after,
.link:active:after,
.link:focus:after {
  right: -12px;
}

.v-align {
  display: table;
  height: 100%;
}

.v-align-center {
  vertical-align: middle;
  display: table-cell;
}

/**** END: STYLEGUIDES ****/
/* COMMON STYLES TO BE USED ACROSS THE WEBSITE */
.bold {
  font-weight: bold;
}

.noscroll {
  overflow: hidden !important;
}

.margin0 {
  margin: 0;
}

.sgh-clearfix:before,
.sgh-clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.lazy-img {
  opacity: 0;
}

.lazy-img.lazy-loaded {
  opacity: 1;
  transition: opacity 0.4s ease;
}

.ico-play {
  display: block;
  width: 51px;
  height: 51px;
  border: 1px solid $white;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  background-image: url('../assets/images/arrow-play.png');
  background-position: 53% 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.modal-custom .modal-content {
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.modal-no-animation.modal.fade .modal-dialog,
.modal-no-animation.modal.fade.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.sgh-breadcrumb {
  &__back {
    z-index: 10;
  }
}
.sgh-breadcrumb.sgh-breadcrumb--basic {
  position: sticky;
  position: -webkit-sticky;
  top: 60px;
  border-top: 1px solid $alto;
  border-bottom: 1px solid $alto;
  width: 100%;
  background-color: $white;
  z-index: 10;
}

@media screen and (min-width: 768px) {
  .sgh-breadcrumb.sgh-breadcrumb--basic {
    top: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .sgh-breadcrumb.sgh-breadcrumb--basic {
    top: 95px;
  }
}

.sgh-breadcrumb.sgh-breadcrumb--basic .sgh-breadcrumb__content {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 15px;
  line-height: 6rem;
  font-weight: 300;
}

@media screen and (min-width: 768px) {
  .sgh-breadcrumb.sgh-breadcrumb--basic .sgh-breadcrumb__content {
    padding: 0 30px;
  }
}

.sgh-breadcrumb.sgh-breadcrumb--basic .sgh-breadcrumb__logo {
  display: inline-block;
}

.sgh-breadcrumb.sgh-breadcrumb--basic .sgh-breadcrumb__logo img {
  vertical-align: top;
  height: 60px;
}

.price {
  &_discounted {
    display: inline;
    @include primaryFont();
    text-align: right;
    color: $red-berry;
    font-size: 1.4rem;
  }

  &_list {
    display: inline;
    @include primaryFont();
    text-decoration: line-through;
    color: $emperor;
    font-size: 1.6rem;
  }
}
/**** BEGIN: HEADER ****/
.hide-fake-input {
  & > .sgh-header.isPdp {
    z-index: 10002;
  }
}

.sgh-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
}

.sgh-header a.sgh-logo:hover {
  text-decoration: none;
}

.sgh-header .desktop .sgh-logo {
  color: $mine-shaft;
  font-size: 2.1rem;
  padding: 0.5rem 0.5rem 0.5rem 0;
}

.sgh-header .mobile .sgh-logo {
  color: $mine-shaft;
  font-size: 2rem;
  padding: 0.5rem;
}

.sgh-header + .sgh-hero {
  margin-top: -61px;
}

.sgh-header.isPdp {
  &:has(.active) {
    z-index: 10002;
  }
}

/**** END: HEADER ****/
/**** BEGIN: FOOTER ****/
.sgh-footer {
  position: relative;
  /* on some pages must be put over other components */
  background: $white;
  z-index: 0;
  padding-top: 4.8rem;
  border-top: solid 0.1rem $silver;
  &--checkout {
    .wrapper {
      a {
        padding: 0;
      }
    }
  }
}

.sgh-footer a {
  display: block;
  padding: 0.5rem;
  color: $emperor;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: normal;
  letter-spacing: normal;
  min-height:4.4rem;
  min-width:4.4rem;
  @screen md {
    width: fit-content;
  }
}
.sgh-footer{
  &.sgh-footer--checkout{
    a{
      min-height:fit-content;
      min-width:fit-content;
    }
  }
} 
.sgh-footer a:hover,
.sgh-footer a:active {
  text-decoration: underline;
}

.sgh-footer h4 {
  display: block;
  color: $emperor;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: normal;
  letter-spacing: normal;
  margin-bottom: 19px;
}

.sgh-footer h4.no-space-above {
  margin-top: -14px;
}

.sgh-footer .text-bold {
  font-size: 1.4rem;
  line-height: 1.17;
  font-weight: 500;
  margin-bottom: 19px;
}

.sgh-footer .follow-us-box a {
  display: inline-block;
}

.sgh-footer .lang-selector-box a {
  display: inline-block;
  text-decoration: underline;
}

.sgh-footer .lang-selector-box h4 {
  display: inline-block;
}

.sgh-footer .payment-box a {
  text-decoration: none;
  display: inline-block;
  margin-bottom: 0;
}

.sgh-footer .payment-box a:hover {
  text-decoration: none;
}

.sgh-footer .payment-box span {
  font-size: 3.2rem;
}

.sgh-footer .payment-box img {
  display: inline-block;
  max-width: 32px;
}

.sgh-footer .sgh-after-footer {
  height: auto;
  padding: 1.4rem 0;
  border: none;
  border-top: solid 0.1rem $silver;
  margin-top: 4rem;
}

.sgh-footer .sgh-after-footer span {
  color: $emperor;
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: normal;
  letter-spacing: normal;
}

.sgh-footer .sgh-after-footer a {
  display: inline-block;
  color: $emperor;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: normal;
  letter-spacing: normal;
  margin-bottom: 0;
}

.sgh-footer .container-custom.visible-xs .panel-group {
  overflow: hidden;
  margin: 0 -15px;
}

.sgh-footer .container-custom.visible-xs .panel-group .panel {
  margin-top: 0;
  border-radius: 0;
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .sgh-footer .text-bold {
    font-size: 1.2rem;
  }

  .sgh-footer .container-custom.visible-sm {
    padding: 0 104px;
  }

  .sgh-footer .container-custom.visible-sm .text-right-sm {
    text-align: right;
  }

  .sgh-footer .sgh-after-footer {
    padding: 9px 0;
  }

  .sgh-footer .sgh-after-footer .container-custom {
    padding: 0 104px;
  }

  .sgh-footer .payment-box span {
    font-size: 2.9rem;
  }

  .sgh-footer .payment-box img {
    display: inline-block;
    max-width: 32px;
  }
}
@media screen and (max-width: 767px) {
  .sgh-footer {
    position: static;
    border: none;
    padding-top: 0;
  }

  .sgh-footer .panel-group {
    border-top: 1px solid $alto;
  }

  .sgh-footer .panel {
    border-top: none;
  }

  .sgh-footer .panel .panel-heading {
    padding: 0 10px;
    background: $white;
    border-color: $alto;
  }

  .sgh-footer .panel .panel-title {
    margin-bottom: 0;
  }

  .sgh-footer .panel .panel-heading a {
    color: $fade-gray;
    font-size: 1.6rem;
    line-height: 6.2rem;
    font-weight: normal;
    letter-spacing: normal;
    margin-bottom: 0;
    text-transform: none;
  }

  .sgh-footer .panel .panel-heading a .icon-arrow-dx {
    float: right;
    font-size: 1.3rem;
    line-height: 6.2rem;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .sgh-footer .panel .panel-heading a.collapsed .icon-arrow-dx {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .sgh-footer .panel .panel-heading a:hover,
  .sgh-footer .panel .panel-heading a:focus,
  .sgh-footer .panel .panel-heading a:active {
    text-decoration: none;
  }

  .sgh-footer .panel .panel-collapse .panel-body {
    padding: 0;
  }

  .sgh-footer .panel .panel-collapse .panel-body a {
    margin: 0;
    padding: 0 9px;
    line-height: 6.2rem;
    border-bottom: 1px solid $alto;
  }

  .sgh-footer .panel .panel-collapse .panel-body a:last-child {
    border-bottom: none;
  }

  .sgh-footer .panel .panel-collapse .panel-body a.no-border {
    border-bottom: none;
  }

  .sgh-footer .panel .panel-collapse .panel-body h4 {
    padding: 0 9px;
    line-height: 6.2rem;
    border-bottom: 1px solid $alto;
    margin-bottom: 0;
  }

  .sgh-footer .panel .panel-collapse .panel-body h4.no-border {
    border-bottom: none;
    line-height: 1.2rem;
  }

  .sgh-footer .panel .panel-collapse .panel-body .payment-box {
    text-align: center;
    padding: 25px;
  }

  .sgh-footer .panel .panel-collapse .panel-body .payment-box img {
    display: inline-block;
    max-width: 32px;
  }

  .sgh-footer .follow-us-box {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
  }

  .sgh-footer .follow-us-box a {
    margin: 0;
    color: $emperor;
    font-size: 1.6rem;
    line-height: 1.2rem;
    font-weight: normal;
    letter-spacing: normal;
  }

  .sgh-footer .sgh-after-footer {
    margin: 0;
    border: none;
    text-align: center;
  }

  .sgh-footer .sgh-after-footer .col-copyright span {
    color: $fade-gray;
    font-size: 1rem;
    line-height: 1.9rem;
    font-weight: normal;
    letter-spacing: normal;
  }

  .sgh-footer .sgh-after-footer .col-tos {
    text-align: center;
    margin: 20px 0;
  }

  .sgh-footer .sgh-after-footer .col-tos a {
    margin: 4px 0;
    color: $fade-gray;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: normal;
    letter-spacing: normal;
  }
}

/* BEGIN: SGH BRANDS FOOTER BLOCK */
.sgh-footer .sgh-after-footer .collapse-brands-footer {
  color: $emperor;
  font-size: 1rem;
  line-height: 1.4rem;
  text-decoration: underline;
  @screen mdr {
    font-size: 1.2rem;
  }
}

.sgh-brands-footer {
  padding: 14px 0;
  border-top: 1px solid $silver;
  background-color: $alto;
  @media screen and (max-width: 1023px) and (min-width: 768px) {
    .container-custom {
      padding: 0 104px;
    }
  }
  @screen mdr {
    padding: 39px 0 9px;
  }

  .brands-list-holder {
    h4 {
      margin-bottom: 19px;
      font-size: 1.4rem;
      line-height: 1.17;
      font-weight: 500;
      @screen mdr {
        font-size: 1.6rem;
      }
    }
    .nav-pills{
      li{
        a{
          min-width:4.4rem;
          min-height: 4.4rem;
        }
      }
    }
  }

  a {
    margin: 0;
  }

  a.close {
    position: absolute;
    top: 0;
    right: 5px;
    float: none;
    width: 15px;
    height: 15px;
    margin: 0;
    font-size: 0;
    line-height: 0;
    opacity: 1;
    @screen mdr {
      top: -40px;
      right: -19px;
      width: 40px;
      height: 40px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15px;
      height: 15px;
      background: url('../assets/images/redesign-ph3/pdp-zoom-image/close-modale.svg');
      transform: translate(-50%, -50%);
      background-repeat: no-repeat;
      background-position: 49% 49%;
      background-size: inherit;
      text-decoration: none;
    }
  }

  .brands-list {
    position: relative;

    li {
      margin: 0 23px 12px 0;

      &:last-child {
        margin: 0 0 12px;
      }
      @screen mdr {
        float: none;
        margin: 0;
      }
    }

    a {
      margin: 0;
      padding: 0;
      border-radius: 0;
      text-decoration: underline;

      &:hover,
      &:focus {
        background: none;
        text-decoration: none;
      }
      @screen mdr {
        padding: 25px 0;
      }
    }
  }
}

/* END: SGH BRANDS FOOTER BLOCK */

/**** END: FOOTER ****/
/**** BEGIN: HERO ****/
.sgh-hero__media {
  position: relative;
}

.sgh-hero__media img {
  width: 100%;
}

.sgh-hero__content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 60px;
  text-align: center;
  z-index: 2;
}

.sgh-hero__content .title {
  padding: 0 6%;
  color: $white;
  @include primaryFont();
  font-size: 9rem;
  line-height: 10rem;
  font-weight: 100;
  margin-bottom: 20px;
}

.sgh-hero__content .btn {
  margin-left: 15px;
  margin-right: 15px;
}

.sgh-hero__body {
  margin: 40px 0;
}

.sgh-hero__body .tag {
  display: block;
  color: $emperor;
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 9px;
}

.sgh-hero__body .title {
  color: $emperor;
  font-size: 3.2rem;
  line-height: 3.4rem;
  font-weight: 300;
  margin-bottom: 20px;
}

.sgh-hero__body .text {
  display: block;
  max-width: 680px;
  color: $emperor;
  font-size: 1.4rem;
  line-height: normal;
  font-weight: normal;
  letter-spacing: 0;
  margin-bottom: 17px;
  margin-left: auto;
  margin-right: auto;
}

.sgh-hero__body .btn + .btn {
  margin-left: 30px;
}

.sgh-video__player {
  position: relative;
  padding: 56.25% 0 0;
}

.sgh-video__player iframe,
.sgh-video__player video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sgh-hero__image {
  background-color: $alabaster-solid;
}

.sgh-video {
  position: relative;
  overflow: hidden;
  background-color: $alabaster-solid;
}

.sgh-video .video-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $alabaster-solid;
  z-index: 1;
}

.sgh-video .ico-play {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  transition: opacity 0.275s ease;
  z-index: 2;
  cursor: pointer;
}

.sgh-video .ico-play:hover,
.sgh-video .ico-play:focus {
  opacity: 0.8;
}

@media screen and (max-width: 1023px) {
  .sgh-hero__content {
    padding-bottom: 40px;
  }

  .sgh-hero__content .title {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 767px) {
  .sgh-hero__video .sgh-video__player {
    padding: 100vh 0 0;
  }

  .sgh-hero__video .sgh-video__player iframe,
  .sgh-hero__video .sgh-video__player video {
    left: 50%;
    width: calc(100vh * 1.78);
    height: 100%;
    transform: translateX(-50%);
  }

  .sgh-hero__content .title {
    font-size: 40px;
    line-height: 5rem;
  }

  .sgh-hero__content .btn {
    margin-left: 7.5px;
    margin-right: 7.5px;
  }

  .sgh-hero__body {
    padding: 0 15px;
  }
}

/**** END: HERO ****/
/**** BEGIN: HERO STACKED (modifier) ***/
.sgh-hero--stacked.horizontal-stack .sgh-hero__media {
  float: left;
  width: 50%;
}

.sgh-hero--stacked.horizontal-stack:before,
.sgh-hero--stacked.horizontal-stack:after {
  content: '';
  display: table;
  clear: both;
}

.sgh-hero--stacked .sgh-hero__image {
  background-color: $white;
  position: relative;
}

.sgh-hero--stacked .sgh-hero__image .sgh-hero__overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.sgh-hero--stacked .sgh-hero__media img {
  transition: opacity 0.3s ease;
}

.sgh-hero--stacked .sgh-hero__media:hover .sgh-hero__image .sgh-hero__overlay {
  display: block;
}

@media not all and (pointer: coarse) {
  .sgh-hero--stacked .sgh-hero__media:hover .sgh-hero__image .sgh-hero__overlay {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .sgh-hero--stacked .sgh-hero__media img {
    opacity: 1;
  }
}

/**** END: HERO STACKED (modifier) ***/
/*** BEGIN:
    HERO CROPPED (modifier) ***/

.sgh-hero--cropped {
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .sgh-hero--cropped .sgh-hero__media {
    padding-left: 25%;
  }

  .sgh-hero--cropped .sgh-hero__image,
  .sgh-hero--cropped .sgh-hero__video {
    padding-top: 65%;
    position: relative;
    overflow: hidden;
  }

  .sgh-hero--cropped .sgh-hero__image img,
  .sgh-hero--cropped .sgh-hero__video img,
  .sgh-hero--cropped .sgh-hero__image .sgh-video__player,
  .sgh-hero--cropped .sgh-hero__video .sgh-video__player {
    position: absolute;
    top: 0;
    left: -12.5vw;
    width: 100vw;
    height: auto;
  }

  .sgh-hero--cropped .sgh-hero__image .sgh-video__player,
  .sgh-hero--cropped .sgh-hero__video .sgh-video__player {
    height: 100%;
  }

  .sgh-hero--cropped .sgh-hero__content {
    text-align: left;
    padding-left: 6%;
    padding-bottom: 4%;
  }
}
@media screen and (min-width: 768px) {
  .sgh-hero--cropped.sgh-hero--inverted .sgh-hero__media {
    padding-left: 0;
    padding-right: 25%;
  }

  .sgh-hero--cropped.sgh-hero--inverted .sgh-hero__content {
    right: 0;
    left: auto;
    padding-right: 6%;
    padding-left: 0;
    width: auto;
  }
}

/*** END:
    HERO CROPPED (modifier) ***/
/*** BEGIN: PRODUCT GRID ****/
.sgh-grid {
  padding: 70px 30px 45px;
  background: $white;
}

.sgh-grid__header p {
  display: inline-block;
}

.sgh-grid__header .link {
  float: right;
}

.sgh-grid__title {
  font-size: 2.4rem;
  font-weight: 300;
}

.sgh-grid__list {
  padding: 40px 0 0;
}

.sgh-grid__list .row {
  margin-left: -30px;
  margin-right: -30px;
}

.sgh-grid__list .grid-list-item {
  text-align: center;
}

.sgh-grid__list .grid-list-item > a {
  display: block;
  padding: 10px 0;
}

.sgh-grid__list .grid-list-item .item-image {
  position: relative;
}

.sgh-grid__list .grid-list-item .item-image img {
  width: 100%;
  max-width: 180px;
  margin: 0 auto;
  transition: opacity 0.3s ease;
}

.sgh-grid__list .grid-list-item .item-image .img-hovered {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  max-width: 180px;
  margin: 0 auto;
  opacity: 0;
}

.sgh-grid__list .grid-list-item .item-details {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.sgh-grid__list .grid-list-item .item-details .brand,
.sgh-grid__list .grid-list-item .item-details .price {
  color: $emperor;
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.sgh-grid__list .grid-list-item .item-details .price--disabled {
  position: relative;
  display: inline-block;
  color: $emperor;
  font-size: 1.2rem;
  line-height: 2.2rem;
  font-weight: normal;
  text-decoration: line-through;
}

.sgh-grid__list .grid-list-item .item-details .price--disabled + .price {
  display: inline-block;
  padding: 0 6px;
  color: $red-berry;
  font-weight: 500;
}

.sgh-grid__list .grid-list-item .item-details .price--disabled + .price + .btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.sgh-grid__list .grid-list-item .item-details .btn {
  margin-top: 7px;
}

.sgh-grid__list .grid-list-item .item-details .brand {
  text-transform: uppercase;
}

.sgh-grid__list .grid-list-item > a:hover {
  text-decoration: none;
}

.sgh-grid__list .grid-list-item > a:hover .item-image img {
  opacity: 0;
}

.sgh-grid__list .grid-list-item > a:hover .item-image .img-hovered {
  opacity: 1;
}

.sgh-grid__list .grid-list-item > a:hover .item-details {
  opacity: 1;
}

.sgh-grid__list--static .grid-list-item {
  min-height: 188px;
  text-align: center;
}

.sgh-grid__list--static .grid-list-item .item-image {
  margin-bottom: 12px;
}

.sgh-grid__list--static .grid-list-item > a:hover .item-image img {
  opacity: 1;
}

.sgh-grid__list--static .grid-list-item .item-details {
  opacity: 1;
}

.sgh-grid--brands .sgh-grid__list .grid-list-item > a .item-image img {
  width: 100%;
  max-width: 180px;
  opacity: 0.6;
}

.sgh-grid--brands .sgh-grid__list .grid-list-item > a:hover .item-image img {
  opacity: 1;
}

@media screen and (max-width: 1023px) {
  .sgh-grid__list .row {
    display: none;
  }

  .sgh-grid__list .grid-list-item .item-details {
    opacity: 1;
  }

  .sgh-grid__list .grid-list-item .item-details .btn {
    display: none;
  }

  .sgh-grid__list--static .row {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .sgh-grid__header .link {
    display: none;
  }

  .sgh-grid--brands .sgh-grid__header p {
    font-size: 1.8rem;
  }
}

/*** END: PRODUCT GRID ****/
/* COMMON (fasce 7 - 9) */
.grid-list-slider__wrapper {
  position: relative;
  display: none;
}

.grid-list-slider__wrapper .link {
  display: none;
}

.grid-list-slider:before,
.grid-list-slider:after {
  content: '';
  display: table;
  clear: both;
}

.grid-list-slider .slick-list {
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.grid-list-slider .slick-slide {
  height: auto;
}

.grid-list-slider .slick-slide img {
  display: inline-block;
}

.grid-list-slider .slick-dots {
  bottom: 0;
}

.grid-list-slider .slick-dots li button:before {
  content: '';
  right: 0;
  width: 10px;
  height: 10px;
  margin: 0 auto;
  border: 1px solid $emperor;
  border-radius: 50%;
}

.grid-list-slider .slick-dots li.slick-active button:before {
  background: $emperor;
}

@media screen and (max-width: 1023px) {
  .sgh-grid__list .grid-list-slider__wrapper {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .grid-list-slider__wrapper .link {
    position: absolute;
    right: 30px;
    bottom: 0;
    display: inline-block;
  }
}
/*** BEGIN: SEARCH OVERLAY ****/
body.bodyPlp.header-scroll-up .sgh-search-overlay {
  top: 6rem;
  height: calc(100dvh - 4.8rem);
  @screen mdr {
    height: calc(100dvh - 9.6rem);
  }
}

.fake-input-active {
  .sgh-search-common {
    opacity: 1;
  }
}
/*** END: SEARCH OVERLAY ****/
/* BEGIN: SUGGESTION BLOCK */
.item-suggestion {
  position: absolute;
  top: 50%;
  right: calc(290px + 10px);
  width: 450px;
  max-width: 100%;
  margin: 0 25px 0 0;
  padding: 24px 17px;
  background: $white;
  transform: translate(0, -50%);
  z-index: 4;
  @media screen and (max-width: 767px) {
    position: relative;
    top: 0;
    right: 0;
    margin: 0 0 -11px;
    padding: 20px 0 16px;
    transform: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    width: 0;
    height: 0;
    transform: translate(0, -50%);
    z-index: 4;
    border-style: solid;
    border-width: 20px 0 20px 20px;
    border-color: transparent transparent transparent $white;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -40px;
    bottom: 0;
    left: -40px;
    display: none;
    background: $wild-sand;
    z-index: -1;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  .item-suggestion__main {
    margin: 0 0 24px;
    padding: 0 0 10px;
    border-bottom: 1px solid $alto-2;
  }

  h3 {
    margin: 0 0 20px;
    color: $dark-gray;
    font-size: 1.8rem;
    line-height: 1;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      margin: 0 0 11px;
      font-size: 1.6rem;
    }
  }

  .item-image {
    margin: 0 0 10px;
    text-align: center;

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }

  .item-details {
    padding: 0 0 5px;

    .item-price {
      text-align: right;
    }
  }

  .item-brand {
    font-size: 1.4rem;
    text-transform: uppercase;
  }

  .item-name {
    overflow: hidden;
    font-size: 1.2rem;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }

  .item-suggestion__footer {
    text-align: center;
  }

  .btn {
    width: 210px;
    @media screen and (max-width: 767px) {
      width: auto;
      border: 1px solid $emperor;
      background: none;
      color: $emperor;
      min-width: 101px;
      word-wrap: break-word;
    }
  }
}

/* MARKETING CONTENT BLOCK */
.item-suggestion__marketing-content {
  display: flex;
  justify-content: center;
  align-items: center;
  @screen lg {
    background-color: $mine-shaft;
  }

  &:before {
    @screen lg {
      border-color: transparent transparent transparent $mine-shaft;
    }
  }

  .marketing-content__title {
    font-weight: normal;
    @screen lg {
      @include primaryFont();
      margin-top: 26px;
      color: $white;
      font-size: 2.2rem;
      font-weight: 300;
    }
  }

  .marketing-content__description {
    font-size: 1.2rem;
    text-align: center;
    @screen lg {
      @include primaryFont();
      margin: 16px 0 36px;
      color: $white;
      font-size: 1.4rem;
      font-weight: 300;
    }
  }

  .marketing-content_controllers {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .marketing-content_btn {
    margin: 2px;
    white-space: pre-wrap;
    word-wrap: break-word;
    @screen lg {
      width: 140px;
      margin: 0 4px;
      border-color: $white;
      color: $white;
    }
  }
}

/* END: SUGGESTION BLOCK */
/* BEGIN: TEMPORARY CSS */
a {
  color: $mine-shaft;
}

a:hover {
  color: $mine-shaft;
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

.footer-promise h4 a {
  color: $mine-shaft !important;
}

.left-nav .facet a {
  font-size: 1.4rem;
}

.item p.badge {
  padding: 0;
  line-height: 3.3rem;
}

.item p.badge .icon {
  top: auto;
  vertical-align: middle;
}

.left-nav .facet .icon {
  opacity: 1;
}

.icon-engraving-hoverable {
  @include primaryFont();
}

#panelEngraving .fontsList label {
  vertical-align: super;
  display: inline-block;
  margin: 0;
}

#panelEngraving .fontsList li {
  height: 18px;
}

#panelEngraving .fontsList .unchecked {
  overflow: visible;
}

@media screen and (max-width: 767px) {
  .carousel ul.nav li a {
    padding: 0;
  }
}

#panelEngraving .panelInner .img h3.SGHNTX_ArtelyInks {
  line-height: 1;
}

@media screen and (min-width: 1270px) {
  #plp .item:hover .img-container .cart,
  #trends .item:hover .img-container .cart,
  #plp .item .img-container .cart,
  #trends .item .img-container .cart {
    height: auto;
    text-transform: uppercase;
  }
}

.main-catalog .slick-list .slick-slide {
  width: 100%;
  max-width: 300px;
}

#myAccount.dashboard .item .addToCart .icon {
  top: 0;
}

.main-catalog .slick-list .slick-slide .delete-link .icon {
  position: relative;
  top: 3px;
  right: 2px;
}

.main-catalog .item .container {
  width: 100%;
}

#myAccount .item .addToCart .icon {
  top: 0;
}

.main-catalog .delete-link .icon {
  position: relative;
  top: 3px;
  right: 2px;
  vertical-align: bottom;
  display: inline-block;
  margin-right: 3px;
}

#right-nav .item-display {
  left: 0;
}

#sgh_header_bottom #right-nav {
  z-index: 10;
}

#sgh_header_bottom #right-nav .item-display {
  margin-top: 8px !important;
}

#wishlist-widget-wrapper {
  padding: 10px 0;
  background-color: $concrete;
}

#right-nav {
  right: -100%;
}

#logon-form #logonErrorMessage_copy4 {
  float: left;
  display: block !important;
  width: 50%;
  margin-bottom: 10px;
}

.sgh-footer .container-custom.visible-xs > .row {
  overflow: visible;
}

#register.container {
  padding: 0 15px;
}

.colorsList .radio {
  position: relative;
  top: -3px;
  display: inline-block;
  font-size: 1rem;
  text-transform: uppercase;
}

#search-box .find-form {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

#search-box input[type='text'] {
  min-height: 36px !important;
}

#personalInformation p.label {
  display: block;
  color: $emperor;
  text-align: left;
}

.product_options * {
  @include primaryFont();
}

.sgh-gifts-suggestions .slick-dots,
.sgh-grid .slick-dots {
  right: auto;
}

.sgh-gifts-suggestions .slick-dots li,
.sgh-grid .slick-dots li {
  float: none;
  border: none;
}

.sgh-gifts-suggestions .slick-dots li.slick-active,
.sgh-grid .slick-dots li.slick-active {
  background: transparent;
}

#masthead.shopCartHeader {
  top: 0;
}

#order_payment_method #payment_method_selection .heading > label {
  font-weight: 500;
}

@media screen and (min-width: 1025px) {
  .IE11 #page-wrapper .frame-lens-combo .neutral {
    width: 15px !important;
    height: 15px !important;
    border-radius: 15px;
    font-size: 0.9rem !important;
    line-height: 0.7rem !important;
    text-align: center;
  }

  .IE11 .icon.redesignIcons-heart-face,
  .IE11 .icon.redesignIcons-oval-face,
  .IE11 .icon.redesignIcons-round-face,
  .IE11 .icon.redesignIcons-square-face {
    background: initial;
  }

  .IE11 #plp .view-switcher {
    margin-top: 14px !important;
  }
}

a.tcl-btn--fillred:hover {
  color: $white;
}

a.tcl-btn--fillblack:hover,
a.tcl-btn--fillblack:active,
a.tcl-btn--fillblack:visited {
  color: $white;
}

#pageContainer {
  overflow: inherit;
}

.pagination {
  width: 100%;
}

#checkout_cart .product_info_container .price {
  margin-top: 0;
}

#breadcrumb.empty-cart.cart-head h1 {
  color: $dark-gray !important;
  font-size: 1.6rem !important;
  font-weight: bold !important;
  border-bottom: none !important;
  text-transform: uppercase !important;
  margin-bottom: 0 !important;
}

@media screen and (max-width: 767px) {
  .productDisplayPage .container {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}

body.fix-scroll {
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .productImage .catalog-item .itemDetails {
    height: 42px;
  }

  .productImage {
    margin-bottom: 1px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.addToCart {
  height: auto;
  padding: 10px !important;
}

.addToCart .addToCartText {
  display: inline-block;
  width: auto;
  text-transform: uppercase;
  text-align: center;
  margin-left: 15px;
}

.categoryDisplay #breadcrumb,
.productList #breadcrumb {
  padding: 0;
  background: none;
  margin-bottom: 0;
  margin-top: 0;
}

ul.list-unstyled.color-list .img-circle {
  width: 16px;
  height: 16px;
}

ul.list-unstyled.color-list .img-circle.space {
  border: 1px solid grey;
}

#plpFiltersOverlayMobile.modal-custom .modal-dialog .modal-content {
  margin: 0;
  padding: 0;
}

/* END: TEMPORARY CSS */

/* START: Fix LayoutCheckout Page after include this file */
.checkout-page-wrapper #cart-body #cart-left label {
  font-weight: 400;
}

.checkout-page-wrapper #total-breakdown .box .box__content .label {
  padding: 0;
  font-weight: 400;
}

.checkout-page-wrapper #total-breakdown .box .box__content .label--green {
  text-align: left;
}

.shopping-cart * {
  box-sizing: border-box;
}

/* END: Fix LayoutCheckout Page after include this file */
@media screen and (max-width: 767px) {
  .sgh-breadcrumb__text {
    display: inline-block;
    overflow: hidden;
    width: 214px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.sgh-footer.sgh-footer--checkout {
  padding-top: 0;
  background-color: $dark-gray;
  color: $white;
  @include primaryFont();

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 960px;
    margin: 0 auto;
    padding: 21px 0;
    font-size: 1.4rem;
  }

  .icon-cart {
    font-size: 2.8rem;
  }

  .navigation {
    width: 100%;
    max-width: 243px;

    &-list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      list-style: none;

      &__item {
        text-transform: uppercase;

        a {
          margin-bottom: 0;
          color: $white;

          &:hover {
            color: inherit;
          }
        }
      }

      &__link {
        color: $white;
      }
    }
  }

  .guarantee {
    display: flex;
    margin-left: 5px;
    align-items: center;

    &__title {
      position: relative;
      display: inherit;
      align-items: center;
      margin: 0 13px 0 0;
      color: $white;
      font-size: 1.2rem;
    }

    &__text {
      margin-left: 10px;
    }
  }

  .payment-list {
    display: flex;
    margin: 0;
    list-style: none;

    &__item {
      width: 41px;
      margin-left: 9px;

      &:first-child {
        margin-left: 0;
      }
    }

    &__link {
      display: block;
      margin: 0;
      background-color: $concrete;

      img {
        width: 100%;
      }
    }
  }
  @screen lgr {
    .wrapper {
      padding: 21px 5px;
    }
  }
  @screen mdr {
    .wrapper {
      justify-content: center;
      flex-wrap: wrap;
    }

    .navigation {
      margin-bottom: 10px;
    }

    .guarantee {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

// new redesign
.new-icon {
  vertical-align: bottom;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: contain;

  &-engraving {
    background-image: url('../assets/images/redesign-ph4/pen.svg');
  }

  &-gift-wrap {
    background-image: url('../assets/images/redesign-ph4/gift.svg');
  }

  &-add-gift-wrap {
    background-image: url('../assets/images/redesign-ph4/plus.svg');
    margin-top: 8px;
    @screen lgr {
      margin: 0;
    }
  }

  &-remove-gift-wrap {
    vertical-align: middle;
    height: 24px;
    background-image: url('../assets/images/redesign-ph4/remove.svg');
    background-size: 24px 24px;
    margin-top: -2px;
  }

  &-edit-gift-message {
    background-image: url('../assets/images/redesign-ph4/edit.svg');
    position: absolute;
    top: 20px;
    right: 0;
    @screen lgr {
      right: 17px;
    }
  }
}

.gray-box {
  display: none;
  height: 30px;
  background-color: $wild-sand;
  @screen lgr {
    display: block;
  }
}

.hide-in-mobie {
  @screen lgr {
    display: none;
  }
}

.gift-message-wrapp {
  position: relative;
  padding: 24px 0;
  border-bottom: 1px solid $alto;
  @screen lgr {
    padding: 24px 30px 24px 15px;
    border-bottom: none;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 92%;
      border-top: 1px solid $alto;
    }
  }

  .gift-message-wrapp--title {
    margin-bottom: 24px;
  }
}

.show-in-mobile {
  display: none;
  @screen lgr {
    display: inline-block;
    font-size: 1.6rem;
    font-weight: bold;
    margin-right: 5px;
  }
}

.fix-wrapp-for-ios {
  display: flex;
  flex: 1 0 auto;
}

.cm-flex,
.cm-flex-hstretch {
  display: block;
  @screen xl {
    display: flex;
  }
}

.offer-list-section {
  overflow: visible !important;
}

@font-face {
  font-family: 'sgh';
  src: url('../assets/images/icon-fonts/sgh2.eot?cnq2wi');
  src: url('../assets/images/icon-fonts/sgh2.eot?cnq2wi#iefix') format('embedded-opentype'),
    url('../assets/images/icon-fonts/sgh2.ttf?cnq2wi') format('truetype'),
    url('../assets/images/icon-fonts/sgh2.woff?cnq2wi') format('woff'),
    url('../assets/images/icon-fonts/sgh2.svg?cnq2wi#sgh') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*** BEGIN: NEWSLETTER ****/
.sgh-newsletter.border {
  border-top: 1px solid $alto-4;
  border-bottom: 1px solid $alto-4;
}

.sgh-newsletter .bg .newsletter-content {
  display: block;
  max-width: 385px;
  height: 100%;
  margin: 0 auto;
}

.sgh-newsletter .newsletter-title {
  font-size: 2.4rem;
  font-weight: 300;
  letter-spacing: normal;
  margin-bottom: 18px;
}

.sgh-newsletter .newsletter-text {
  font-size: 1.4rem;
  line-height: 1.14;
  font-weight: 300;
  letter-spacing: normal;
  margin-bottom: 25px;
}

.sgh-newsletter .newsletter-discalimer {
  color: $tundora;
  font-size: 1rem;
  line-height: normal;
  font-weight: 300;
  letter-spacing: normal;
  opacity: 0;
  margin-bottom: 0;
  margin-top: 18px;
}

.sgh-newsletter .newsletter-discalimer a {
  color: $tundora;
  text-decoration: underline;
}

.sgh-newsletter .newsletter-form {
  border-bottom: solid 1px $dusty-gray-2;
  position: relative;
  margin-top: 10px;
}

.sgh-newsletter .newsletter-form input[type='text'] {
  width: 100%;
  height: 44px;
  border: none;
  background: transparent;
  color: $black;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 300;
  letter-spacing: normal;
  padding-right: 95px;
}

.sgh-newsletter .newsletter-form .newsletter-form-error {
  display: none;
  color: $black;
  color: $emperor;
  font-size: 1.4rem;
  line-height: 4.4rem;
  font-weight: 300;
  letter-spacing: normal;
  min-height: 44px;
}

.sgh-newsletter .newsletter-form .newsletter-form-error span {
  vertical-align: middle;
  font-size: 1.2rem;
  margin-right: 0;
}

.sgh-newsletter .newsletter-form.error .newsletter-form-error {
  display: block;
  width: 75%;
  text-transform: lowercase;
}

.sgh-newsletter .newsletter-form.error input[type='text'] {
  display: none;
}

.sgh-newsletter .newsletter-form .btn {
  position: absolute;
  top: 5px;
  right: 0;
  display: none;
  padding: 9px 15px;
  background-color: transparent;
  border-color: $dusty-gray-2;
}

.sgh-newsletter .newsletter-form .btn:hover {
  background: $emperor;
}

.sgh-newsletter .newsletter-form span.required {
  display: block;
  width: auto;
  margin: 5px 0;
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .sgh-newsletter .bg .newsletter-content {
    max-width: 552px;
  }

  .sgh-newsletter .newsletter-form {
    max-width: 385px;
  }
}
@media screen and (max-width: 767px) {
  .sgh-newsletter .v-align {
    height: auto;
    padding: 70px 30px;
  }

  .sgh-newsletter .newsletter-title {
    font-size: 3.2rem;
    font-weight: 100;
  }

  .sgh-newsletter .newsletter-text {
    font-size: 1.5rem;
    font-weight: normal;
  }

  .sgh-newsletter .newsletter-form input[type='text'] {
    font-size: 1.6rem;
  }
}

.sgh-newsletter--banner .newsletter-content {
  padding: 55px 10% 22px;
}

.sgh-newsletter--banner .newsletter-form,
.sgh-newsletter--banner .newsletter-discalimer {
  max-width: 75%;
}

@media screen and (max-width: 1023px) {
  .sgh-newsletter--banner .newsletter-content {
    padding: 40px 5%;
  }

  .sgh-newsletter--banner .newsletter-form,
  .sgh-newsletter--banner .newsletter-discalimer {
    max-width: none;
  }
}
@media screen and (max-width: 767px) {
  .sgh-newsletter--banner .newsletter-content {
    padding: 0;
  }
}
/*** END: NEWSLETTER ****/

/*** Hide old minicart ****/
.sgh-minicart {
  display: none;
}
.scroll-hidden{
  overflow: hidden;
}
