@import 'mixins';

.cms-related-board-banner {
  @include banner-picture;

  .picture {
    padding-top: calc(537 / 826) * 100%;
  }

  .right-col {
    .cms-text-module__pretitle {
      font-size: 1.4rem;
      text-align: right;
    }

    .cms-text-module__text {
      font-size: 1.4rem;
      display: none;
    }

    .picture__image {
      object-fit: contain;
      object-position: top;
    }
  }
}

@media screen and (min-width: 769px) {
  .cms-related-board-banner .right-col .title {
    display: block;
  }

  .cms-related-board-banner .right-col .cms-text-module__pretitle {
    text-align: left;
  }

  .cms-related-board-banner .right-col .cms-text-module__text {
    display: block;
  }
}
