@import '../../utils/utils';
sgh-service-module {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 3.8rem;
  margin: 6rem 0;
  width: 100%;
  min-height: 40rem;
  position: relative;
  @screen mdr {
    flex-direction: column;
    padding: 0 1.6rem;
  }
  .title-module {
    position: absolute !important;
    width: 10rem;
    height: 2rem;
    top: 0;
    @include skeleton-animation;
    @screen lgr {
      top: -3.6rem;
    }
  }
  sgh-service-item {
    max-width: 25%;
    flex: 0 0 25%;
    @screen mdr {
      max-width: 100%;
      flex: 1;
    }
    ul {
      width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 4.2rem;
      @screen lgr {
        position: relative;
        display: block;
        margin-top: 0;
        margin-bottom: 4rem;
      }

      li {
        list-style: none;
        &.icon {
          width: 6rem;
          height: 6rem;
          @include skeleton-animation;
          @screen lgr {
            margin-right: 2rem;
            float: left;
          }
        }
        &.title {
          width: 90%;
          max-width: 26rem;
          height: 2rem;
          margin-bottom: 0.4rem;
          margin-top: 2.4rem;
          @include skeleton-animation;
          @screen lgr {
            margin-top: 0;
          }
        }
        &.text {
          width: 90%;
          max-width: 26rem;
          min-height: 6.6rem;
          @include skeleton-animation;
          @screen lgr {
            margin-bottom: 0;
          }
        }
        &.cta {
          width: 20rem;
          height: 2.5rem;
          margin-top: 1.6rem;
          @include skeleton-animation;
          @screen lgr {
            margin-top: 1.2rem;
            margin-left: 8rem;
          }
        }
      }
    }
    &[cta-url=''] {
      li.cta {
        display: none;
      }
    }
    &:last-child {
      @screen lgr {
        ul {
          margin-bottom: 0;
        }
      }
    }
  }
}
