/* stylelint-disable */
@import '../mixins/mixins';

#trends {
  .espot-1 {
    padding: 30px;
  }

  .espot-2 {
    margin: 0 0 20px;
  }

  .espot-3 {
    text-align: center;
    width: 350px;
    margin: 0 auto 50px;

    h2 {
      @include primaryFont();
      font-size: 1.6rem;
      font-weight: bold;
      margin: 0 0 30px;
    }

    p {
      @include primaryFont();
      font-size: 1.1rem;
      text-transform: none;
      margin: 0 0 30px;
    }
  }

  .container-left {
    float: left;
    width: 44%;
  }

  .clear {
    + .container-left {
      float: right;
    }
  }

  .layout-f {
    &.big-image {
      .item {
        min-height: 553px;

        .img-container {
          min-height: 552px;
        }
      }
    }
  }

  .item {
    &.small-image {
      min-height: 300px;
      width: 48%;

      .img-container {
        min-height: 300px !important;
      }
    }

    &.big-image {
      width: 56%;
      min-height: 670px;

      .img-container {
        min-height: 669px !important;
      }
    }

    &.plp {
      .img-container {
        position: absolute;
        width: 100%;
        margin: -70px 0 0;
        padding: 70px 0 0;
        min-height: 379px;
      }
    }
  }

  .row {
    float: left;
    width: 100%;
    clear: both;

    &.big-image {
      .item {
        width: 98%;
        min-height: 553px;

        .main-img {
          img {
            margin: 0;
          }
        }
      }
    }
  }

  .thumbnailWrap {
    color: $mine-shaft;
    @screen xl {
      font-size: 1.2rem;
    }

    .style,
    .brand {
      margin-bottom: 0;
    }

    .favorites {
      display: none;
    }
  }
}

/** Collection template **/
.container {
  .collection-espot {
    width: 100%;
    height: auto;
  }

  .espot-product-dropdowns {
    width: 100%;
    margin: 2px auto;
    text-align: center;

    .compare-button {
      border-width: 3px;
      @include primaryFont();
      font-size: 1.4rem;
      font-weight: bold;
      text-decoration: none;
    }
  }

  .colletcion-espot-product {
    div#left-content {
      flex: 0 0 auto;
    }

    div#right-content {
      flex: 0 0 auto;
    }
    text-align: center;
    display: flex;
    min-height: 524px;
    overflow: hidden;

    div.espot-product-left {
      flex: auto;
    }

    > div.espot-product-left {
      float: left;
      width: auto;
    }

    .espot-product-left:nth-child(2) {
      float: left;
      width: auto;
      max-width: 848px;
    }

    .espot-product-left {
      .details {
        p {
          @include primaryFont();
          font-size: 1.3rem;
          font-weight: bold;

          &.style {
            @include primaryFont();
            font-size: 1.3rem;
          }
        }
      }

      .item {
        width: auto;
      }

      &.item {
        .redesignIcons-bag {
          margin-top: 0;
          position: absolute;
          top: 12px;
          left: 15px;
        }

        .redesignIcons-engraving {
          background: $di-serria;
        }

        &:hover {
          .img-container {
            .cart {
              width: 152px;
              visibility: visible;
              transition: width 0.3s ease-out 0s, text-indent 0.4s ease-out 0s,
                visibility 0s ease 0s;
              text-indent: 40px;
            }
          }
        }

        .img-container {
          .cart {
            @include primaryFont();
            font-size: 1.4rem;
            font-weight: bold;
            display: inline-block !important;
            overflow: hidden;
            width: 50px;
            height: 25px;
            padding: 10px 0;
            color: $white;
            line-height: 2.5rem;
            white-space: nowrap;
            visibility: hidden;
            transition: width 0s ease-out 0s, text-indent 0s ease-out 0s, visibility 0s ease 0s;
            text-indent: 50px;
          }
        }
      }
    }

    &.product-details {
      display: block;

      .espot-product-left {
        float: left;
        display: block;
        width: 33.33%;
      }
    }

    &.rightleft {
      > div.espot-product-left {
        float: left;
        width: auto;
        max-width: 848px;
      }

      .espot-product-left:nth-child(2) {
        float: left;
        width: auto;
      }

      div.espot-product-left {
        &.product-item {
          max-width: 100%;
        }
      }
    }
  }
}
