@mixin swiper-arrows() {
  .swiper-button-prev,
  .swiper-button-next {
    color: #9b9b9b;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 3rem;
  }
}

@mixin swiper-pagination() {
  @screen sm {
    .swiper-pagination {
      display: block;
    }
  }
}

@mixin banner-media($media-type) {
  $inner-selector: 'image';

  @if ($media-type == 'video') {
    $inner-selector: 'player';
  }

  .#{$media-type} {
    width: 100%;
    position: relative;

    &__wrapper {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    &__#{$inner-selector} {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

@mixin banner-picture {
  @include banner-media('picture');

  .picture {
    &__wrapper {
      &--hover {
        opacity: 0;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .cms-tap-hold {
    .picture {
      overflow: hidden;
    }

    .picture__wrapper {
      height: auto;
      width: auto;
    }

    .picture:hover .picture__image {
      transform: translateY(-50%);
    }
  }
}

@mixin banner-video {
  @include banner-media('video');
}

@mixin banner-all-medias {
  @include banner-picture;
  @include banner-video;
}
