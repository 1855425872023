a.skip-nav {
  position: fixed;
  top: -40px;
  left: 0;
  padding: 6px;
  background: $dark-gray;
  color: $white;
  transition: top 1s ease-out;
  border-right: 1px solid $white;
  border-bottom: 1px solid $white;
  border-bottom-right-radius: 8px;

  &:focus {
    position: fixed;
    top: 0;
    color: $white;
    transition: top 0.1s ease-in;
    z-index: 10010;
    outline-color: transparent;
  }
}
