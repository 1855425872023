@import '../variables';

$colors: (
  'alabaster': $alabaster,
  'alabaster-solid': $alabaster-solid,
  'alizarin-crimson': $alizarin-crimson,
  'alto': $alto,
  'alto-2': $alto-2,
  'alto-3': $alto-3,
  'alto-4': $alto-4,
  'alto-5': $alto-5,
  'alto-6': $alto-6,
  'alto-light': $alto-light,
  'anakiwa': $anakiwa,
  'anzac': $anzac,
  'apricot': $apricot,
  'athens-gray': $athens-gray,
  'ligth-gray': $ligth-gray,
  'black': $black,
  'black-light': $black-light,
  'black-trans': $black-trans,
  'black-trans-4': $black-trans-4,
  'boulder': $boulder,
  'bright-red': $bright-red,
  'burnt-sienna': $burnt-sienna,
  'camarone': $camarone,
  'carissma': $carissma,
  'cinnabar': $cinnabar,
  'cobalt': $cobalt,
  'concrete': $concrete,
  'curious-blue': $curious-blue,
  'dark-burgundy': $dark-burgundy,
  'dark-gray': $dark-gray,
  'dark-green': $dark-green,
  'di-serria': $di-serria,
  'dove-gray': $dove-gray,
  'downy': $downy,
  'dusty-gray': $dusty-gray,
  'dusty-gray-2': $dusty-gray-2,
  'dusty-gray-3': $dusty-gray-3,
  'dusty-gray-4': $dusty-gray-4,
  'emperor': $emperor,
  'emperor-opaque': $emperor-opaque,
  'emperor-2': $emperor-2,
  'emperor-3': $emperor-3,
  'fade-gray': $fade-gray,
  'gallery': $gallery,
  'gray': $gray,
  'gray-2': $gray-2,
  'green': $green,
  'green-leaf': $green-leaf,
  'guardsman-red': $guardsman-red,
  'hawkes-blue': $hawkes-blue,
  'green-success': $green-success,
  'mandy': $mandy,
  'medium-red-violet': $medium-red-violet,
  'mercury': $mercury,
  'mercury-2': $mercury-2,
  'mercury-3': $mercury-3,
  'mine-shaft': $mine-shaft,
  'mine-shaft-01': $mine-shaft-01,
  'mine-shaft-33': $mine-shaft-33,
  'mine-shaft-6': $mine-shaft-6,
  'mine-shaft-2': $mine-shaft-2,
  'mine-shaft-3': $mine-shaft-3,
  'monza': $monza,
  'mulberry': $mulberry,
  'ocean-green': $ocean-green,
  'overlay-grey': $overlay-grey,
  'pumpkin': $pumpkin,
  'red-light': $red-light,
  'red-berry': $red-berry,
  'red-berry-2': $red-berry-2,
  'red-rejected': $red-rejected,
  'royal-blue': $royal-blue,
  'sangria': $sangria,
  'seablue': $seablue,
  'silver': $silver,
  'silver-2': $silver-2,
  'silver-3': $silver-3,
  'silver-chalice': $silver-chalice,
  'silver-chalice-2': $silver-chalice-2,
  'tamarillo': $tamarillo,
  'tundora': $tundora,
  'transparent': $transparent,
  'valencia': $valencia,
  'white': $white,
  'wild-sand': $wild-sand,
  'pale-pink': $pale-pink,
  'gray-error': $gray-error,
  'transparent-gray': $transparent-gray,
  'transparent-white': $transparent-white,
  'btn-gray': $btn-gray,
  'yellow': $yellow,
  'gray-48': $gray-48,
  'white-smoke': $white-smoke,
);

@each $key, $value in $colors {
  .text-#{$key} {
    color: $value;
  }

  .btn.bg-#{$key},
  .bg-#{$key} {
    background-color: $value;
  }

  .border-#{$key} {
    border-color: $value;
  }

  .hover\:text-#{$key}:hover {
    color: $value;
  }
  
  .hover\:bg-#{$key}:hover {
    background-color: $value;
  }

  @media (min-width: 640px) {
    .sm\:text-#{$key} {
      color: $value;
    }

    .btn.sm\:bg-#{$key},
    .sm\:bg-#{$key} {
      background-color: $value;
    }

    .sm\:border-#{$key} {
      border-color: $value;
    }
  }

  @media (min-width: 768px) {
    .md\:text-#{$key} {
      color: $value;
    }

    .btn.md\:bg-#{$key},
    .md\:bg-#{$key} {
      background-color: $value;
    }

    .md\:border-#{$key} {
      border-color: $value;
    }
  }

  @media (min-width: 1024px) {
    .lg\:text-#{$key} {
      color: $value;
    }

    .btn.lg\:bg-#{$key},
    .lg\:bg-#{$key} {
      background-color: $value;
    }

    .lg\:border-#{$key} {
      border-color: $value;
    }
  }

  @media (min-width: 1280px) {
    .xl\:text-#{$key} {
      color: $value;
    }

    .btn.xl\:bg-#{$key},
    .xl\:bg-#{$key} {
      background-color: $value;
    }

    .xl\:border-#{$key} {
      border-color: $value;
    }
  }

  @media (min-width: 1800px) {
    .xxl\:text-#{$key} {
      color: $value;
    }

    .btn.xxl\:bg-#{$key},
    .xxl\:bg-#{$key} {
      background-color: $value;
    }

    .xxl\:border-#{$key} {
      border-color: $value;
    }
  }

  @media (max-width: 1279px) {
    .xlr\:text-#{$key} {
      color: $value;
    }

    .btn.xlr\:bg-#{$key},
    .xlr\:bg-#{$key} {
      background-color: $value;
    }

    .xlr\:border-#{$key} {
      border-color: $value;
    }
  }

  @media (max-width: 1023px) {
    .lgr\:text-#{$key} {
      color: $value;
    }

    .btn.lgr\:bg-#{$key},
    .lgr\:bg-#{$key} {
      background-color: $value;
    }

    .lgr\:border-#{$key} {
      border-color: $value;
    }
  }

  @media (max-width: 767px) {
    .mdr\:text-#{$key} {
      color: $value;
    }

    .btn.mdr\:bg-#{$key},
    .mdr\:bg-#{$key} {
      background-color: $value;
    }

    .mdr\:border-#{$key} {
      border-color: $value;
    }
  }

  @media (max-width: 639px) {
    .smr\:text-#{$key} {
      color: $value;
    }

    .btn.smr\:bg-#{$key},
    .smr\:bg-#{$key} {
      background-color: $value;
    }

    .smr\:border-#{$key} {
      border-color: $value;
    }
  }
}
