/* stylelint-disable */
@import '../mixins/mixins';

#error {
  background: $gallery;

  .sorry-wrap {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding-top: 115px;

    .sorry-circle {
      width: 230px;
      height: 230px;
      margin: 0 auto;
      background: $white;
      border-radius: 120px;
    }

    .sorry-text {
      margin-top: -197px;

      h1 {
        @include primaryFont();
        font-size: 52px;
        font-weight: bold;
        line-height: 3.6rem;
        letter-spacing: 10px;
      }

      p {
        @include primaryFont();
        font-size: 1.4rem;
        font-weight: bold;
        width: 53%;
        margin: 0 auto;
        line-height: 3.8rem;
        letter-spacing: 3px;
        margin-top: 20px;
      }
    }
  }

  .navigation-menu {
    text-align: center;
    padding: 150px 0;

    ul {
      li {
        letter-spacing: 3px;
        list-style: none;

        a {
          line-height: 3.5rem;
          @include primaryFont();
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
    }
  }
}
