/* stylelint-disable */
@import '../../mixins/mixins';

#main-navigation-container {
  position: fixed;
  height: 100%;
  z-index: 10000;
  text-align: center;
  background-color: $white;

  .wrapper {
    height: auto !important;
    margin: 0 0 -223px;
    min-height: 100%;
  }

  .push,
  #free-shipping {
    height: 223px;
  }

  .do-close {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
    cursor: pointer;
  }

  #collapsed-navigation {
    @include transition(left, 0.5s);
    position: absolute;
    left: 0;
    width: 64px;
    height: 100%;
    z-index: 0;
    border-right: 1px solid $gallery;
    background-color: $white;
    cursor: pointer;

    p {
      @include primaryFont();
      font-size: 1.1rem;
      font-weight: bold;
      margin: 22px 0;
    }

    span {
      margin: 0 auto;
    }
  }

  #main-navigation-wrapper {
    @include transition(left, 0.5s);
    position: absolute;
    left: -256px;
    width: 255px;
    height: 100%;
    z-index: 1;
    border-right: 1px solid $gallery;
    background-color: $white;
  }

  &.active,
  &.open {
    #main-navigation-wrapper {
      left: 0;
    }
  }

  &.open {
    @include animation(fullMenu, 0.5s);

    .do-close {
      display: block;
    }
  }

  #header_logo {
    margin: 0 0 45px;
    padding: 50px 0 0;
  }

  #main-navigation {
    ul {
      list-style-type: none;

      li {
        line-height: 3.3rem;

        &.no-flyout {
          &:hover {
            text-decoration: underline;
          }
        }

        &.nav-flyout {
          a {
            &.has-flyout {
              &.active {
                text-decoration: underline;
              }
            }
          }
        }
      }

      a {
        @include primaryFont();
        font-size: 1.3rem;
        font-weight: bold;
        display: block;
        color: $mine-shaft;

        &.active {
          + .flyout {
            display: block;
            -moz-animation: flyout 1s forwards;
            -webkit-animation: flyout 1s forwards;
            animation: flyout 1s forwards;
          }
        }

        &.open {
          + .flyout {
            display: block;
            width: 464px;
          }
        }
      }
    }

    .flyout {
      position: absolute;
      top: 0;
      left: --256px - 1px;
      display: none;
      overflow: hidden;
      width: 0;
      height: 100%;
      z-index: 9999;
      background-color: $gallery;
      text-align: left;

      .wrapper {
        position: relative;
        width: 464px - 100;
        padding: 0 50px;
      }

      .redesignIcons-close-black {
        position: absolute;
        top: -15px;
        right: 20px;
        text-indent: -9999px;
        cursor: pointer;
      }

      h2 {
        @include primaryFont();
        font-size: 2rem;
        font-weight: bold;
        margin: 35px 0;
        text-align: center;
      }

      h3 {
        @include primaryFont();
        font-size: 1.1rem;
        font-weight: bold;
        margin: 0 0 10px;
        border-bottom: 1px solid $alto-2;
      }

      .links {
        float: left;
        width: 100%;
        margin: 0 0 30px;
        clear: both;
        font: {
          size: 11px;
        }

        ul {
          float: left;
          width: 33%;
        }

        li {
          line-height: 2.4rem;
        }

        a {
          @include primaryFont();
          font-size: 1.1rem;
          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }

        &.images {
          margin: 0 0 10px;

          ul {
            width: 100%;
          }

          li {
            float: left;
            width: 33%;
            margin: 0 0 20px;
            line-height: 0;
          }
        }

        &.brands {
          h3 {
            margin: 0;
          }

          .sort {
            width: 100%;
            margin: 0 0 10px;

            li {
              float: left;
            }

            a {
              @include primaryFont();
              font-size: 1.4rem;
              font-weight: bold;
              display: inline-block;
              padding: 13px 12px;
              text-align: center;

              &:hover,
              &:focus,
              &.selected {
                color: $white;
                background-color: $dark-gray;
              }
            }
          }

          .filters {
            width: 100%;
            clear: both;

            li {
              width: 50%;

              &.right {
                float: none;
                margin-left: 180px;
              }

              &.first {
                margin-top: -180px;
              }
            }
          }
        }

        &.frames {
          ul {
            width: 100%;
          }

          li {
            float: left;
            width: 33%;
            margin: 0 0 20px;
            text-align: center;
            min-height: 74px;

            span {
              margin: 0 auto 5px;
            }
          }
        }

        &.stacked {
          ul {
            width: 100%;
          }

          li {
            width: 100%;
            margin: 0 0 30px;
            text-align: center;
          }

          span {
            margin: 0 auto 5px;
          }

          a {
            @include primaryFont();
            font-size: 1.4rem;
            font-weight: bold;
          }
        }

        &.side {
          ul {
            width: 100%;
          }

          li {
            float: left;
            width: 340px;
            margin: 0 0 10px 30px;
            clear: both;
          }

          span {
            float: left;
            margin: 0 10px 0 0;
          }

          a {
            @include primaryFont();
            line-height: 5rem;
            font-size: 1.4rem;
            font-weight: bold;
          }
        }
      }

      .collections {
        clear: both;

        p {
          line-height: 1.5rem;
          font: {
            size: 11px;
          }
        }

        a {
          text-decoration: underline;
          font: {
            size: 10px;
          }

          &:hover,
          &:focus {
            text-decoration: none;
          }
        }

        &.one {
          .img {
            float: left;
            margin: 0 20px 0 0;
          }

          .info {
            float: left;
          }
        }

        &.two {
          li {
            float: left;
            margin: 0 0 0 5px;
            text-align: center;
            font: {
              size: 11px;
            }

            &:first-child {
              margin: 0;
            }

            img {
              margin: 0 0 10px;
            }
          }
        }

        &.three {
          img {
            float: left;
            margin: 0 0 2px;
          }
        }
      }
    }
  }

  #bottom-links {
    padding: 38px 0 0;

    ul {
      list-style-type: none;
    }

    li {
      margin: 10px 0 0;
      font: {
        size: 10px;
      }

      &:first-child {
        margin: 0;
      }

      &.connected {
        .redesignIcons-email {
          display: block;
          margin: 0 auto 6px;
        }
      }

      &.contact-info {
        span {
          color: $boulder;
        }
      }
    }
  }

  #get-connected-flyout {
    position: absolute;
    bottom: 0;
    display: none;
    width: 215px;
    padding: 30px 20px 0;
    color: $white;
    min-height: 448px;
    background-color: $dark-gray;

    .redesignIcons-close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    h3 {
      @include primaryFont();
      text-decoration: uppercase;
      font-size: 1.4rem;
      font-weight: bold;
    }

    p {
      margin: 25px 0;
      line-height: 1.7rem;
      font: {
        size: 9px;
      }
    }

    input[type='text'] {
      width: 160px;
    }

    .fe {
      margin: 0 0 25px;

      p {
        margin: 8px 0;
      }

      .link {
        color: $white;
        text-decoration: underline;
        font: {
          size: 10px;
        }

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }

      label {
        text-transform: none;
        font: {
          size: 11px;
        }
      }
    }

    span {
      &.required {
        font: {
          size: 10px;
        }
      }
    }

    .social {
      position: absolute;
      bottom: 24px;
      left: 50%;
      width: 130px;
      margin: 0 0 0 -65px;
    }

    #emailSignupResponse {
      display: none;

      h3 {
        margin: 0 0 120px;
      }
    }
  }
}

/* Animations */

@keyframes flyout {
  0% {
    width: 0;
  }

  100% {
    width: 464px;
  }
}

@-moz-keyframes flyout {
  0% {
    width: 0;
  }

  100% {
    width: 464px;
  }
}

@-webkit-keyframes flyout {
  0% {
    width: 0;
  }

  100% {
    width: 464px;
  }
}

@keyframes fullMenu {
  0% {
    left: -256px;
  }

  100% {
    left: 0;
  }
}

@-moz-keyframes fullMenu {
  0% {
    left: -256px;
  }

  100% {
    left: 0;
  }
}

@-webkit-keyframes fullMenu {
  0% {
    left: -256px;
  }

  100% {
    left: 0;
  }
}
