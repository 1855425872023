@import '../mixins/mixins';

.hto-terms {
  width: 768px;
  margin: 0 auto;

  & > img {
    display: block;
    margin: -30px auto 20px;
  }

  h1 {
    @include primaryFont();
    font-size: 2rem;
    font-weight: bold;
    padding: 30px 0;
    letter-spacing: 10px;
    text-align: center;
  }

  p {
    @include primaryFont();
    font-size: 1.2rem;
    line-height: 2.1rem;
    text-transform: none;
    font-weight: normal;
    margin-bottom: 20px;
  }
}
