// SGH POPUP NEWSLETTER
// popup managed by cms
@import '../mixins/mixins';
@import '../variables';

.sgh-popup-newsletter {
  &__open-button {
    width: 18rem;
    height: 3.5rem;
    border-radius: 1.75rem;
    border: 0.1rem solid $dusty-gray;
    background: transparent;
    outline: none;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    &:focus {
      outline: 0.5rem auto -webkit-focus-ring-color;
      outline-offset: -0.2rem;
    }
  }

  &__container {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    padding: 7rem 2rem 2rem 2rem;
    background: $white;
    box-shadow: -0.2rem 0 0.2rem 0 rgba(0, 0, 0, 0.2);
    transition: all 0.3s linear;
    transition-delay: 0.3s;

    @media (min-width: 769px) {
      width: 49.6rem;
      padding: 8.5rem 3.5rem 3.5rem 3.5rem;
    }
  }

  &__banner {
    padding: 2.4rem 0 0;

    img {
      width: 100%;
    }
  }

  &__wrapper {
    content-visibility: auto;
    contain-intrinsic-size: auto none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s linear;
    z-index: 99998;
    background-color: rgba(0, 0, 0, 0.3);

    img {
      display: none;
    }

    &.opened {
      visibility: visible;
      opacity: 1;

      .sgh-popup-newsletter__container {
        transform: translateX(-100%);
      }

      img {
        display: block;
      }
    }
  }
  &__title h3 {
    @include actaHeadlineFontTitle();
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 2.4rem;
    color: $mine-shaft;
  }
  &__close-button {
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: flex;
    padding: 0;
    border: none;
    color: $mine-shaft;
    font-size: 2.6rem;
    text-decoration: none;
    background-color: transparent;
  }

  &__title {
    @include actaHeadlineFontTitle();
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 2.4rem;
    color: $mine-shaft;
  }
 
  &__subtitle {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    letter-spacing: 0.02rem;
    margin-top: 0.8rem;
    color: $mine-shaft;
    a {
      text-decoration: underline;
    }
  }

  &__form {
    border-bottom: 0.1rem solid $skeleton-gray-dark;
    padding-bottom: 1.8rem;
    margin-bottom: 1.5rem;
    position: relative;

    @media (min-width: 769px) {
      padding-bottom: 3.15rem;
      margin-bottom: 3.8rem;
    }

    &-input {
      label {
        font-size: 1.2rem;
        font-weight: normal;
        padding-left: 0.4rem;
      }

      input {
        display: block;
        width: 100%;
        background-color: $white;
        border-radius: 0.1rem;
        border: 0.1rem solid $alto;
        height: 3.9rem;
        outline: none;
        font-size: 1.4rem;
        font-weight: 500;
        padding: 1.2rem 1.7rem;
      }
    }

    &-error,
    &-success {
      display: none;
      position: absolute;
      margin-top: 0.5rem;
      font-size: 1rem;
    }

    &-error {
      color: $monza;
    }

    &-success {
      color: $mine-shaft;
      line-height: 1;
    }

    &-submit {
      width: 100%;
      background-color: $mine-shaft;
      border: none;
      border-radius: 2rem;
      color: $white;
      height: 3.9rem;
      font-size: 1.6rem;
      outline: none;
      margin-top: 3rem;

      @media (min-width: 769px) {
        margin-top: 3.2rem;
      }
    }

    &.error .sgh-popup-newsletter__form {
      &-email {
        border-color: $monza;
      }

      &-error {
        display: block;
      }
    }

    &.success .sgh-popup-newsletter__form-success {
      display: block;
    }
  }

  &__benefits {
    h4 {
      font-size: 2rem;
      font-weight: 400;
      line-height: 3rem;
      text-align: center;
    }

    p {
      font-size: 1.1rem;
      line-height: 1.65rem;
    }

    &-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 3.2rem 0;

      @media (min-width: 769px) {
        justify-content: space-evenly;
      }
    }
  }

  &__benefit {
    width: 30%;
    text-align: center;
    padding: 0 0.3rem;

    @media (min-width: 769px) {
      padding: 0 0.6rem;
    }

    .icon {
      width: 3rem;
      height: 3.2rem;
      display: inline-block;

      img {
        width: 100%;
      }
    }

    .text {
      display: block;
      padding-top: 1.6rem;
      font-size: 1.1rem;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 1.65rem;
    }
  }
}

.sgh-popup-newsletter__mini {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 7.4rem;
  z-index: 9999;
  padding: 0 2.4rem;

  @media (min-width: 769px) {
    width: 48rem;
    height: 6rem;
    flex-direction: row;
    align-items: center;
    padding: 0 2.5rem;
  }

  &-title {
    font-size: 1.8rem;
    font-weight: 300;
    margin-bottom: 0.3rem;

    @media (min-width: 769px) {
      flex: 1;
      margin: 0;
    }
  }

  &-openbtn {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.04rem;
    text-decoration: underline;

    @media (min-width: 769px) {
      margin-right: 2.5rem;
    }
  }

  &-closebtn {
    font-size: 1.8rem;
    position: absolute;
    right: 1.6rem;

    @media (min-width: 769px) {
      position: relative;
      right: 0;
    }

    span {
      vertical-align: middle;
    }
  }

  button {
    border: none;
    background: none;
    outline: none;
    padding: 0;
  }
}
