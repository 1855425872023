/* stylelint-disable */
@import '../mixins/mixins';

.shopping-cart,
#cart-body,
#checkout_cart,
.page--shopping-cart,
.checkout {
  .tcl-btn.tcl-btn--block.tcl-btn--fillred.checkoutbutton,
  .black-button,
  .tcl-btn.tcl-btn--sm.tcl-btn--fillblack,
  .ajaxAddToCart.form-button.primary-button.addToCart,
  #logon-form .red-button,
  #myAccount.dashboard .item .addToCart,
  #myAccount #personalInformation .custom-check .row .red-button,
  #myAccount #personalInformation #LoginInfo .row .red-button,
  #addressBookWrap .red-button.primary-button {
    box-sizing: border-box;
    max-height: 34px;
    border: solid 1px $mine-shaft;
    background: $mine-shaft;
    color: $white;
    box-shadow: none;
    @include primaryFont();
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: normal;
    letter-spacing: normal;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    text-align: center;
    border-radius: 22px;
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    text-shadow: none;

    &.disabled {
      opacity: 0.6;
      pointer-events: none;
      cursor: default;
    }
  }
}

@media screen and (min-width: 768px) {
  .shopping-cart,
  #cart-body,
  #checkout_cart,
  .page--shopping-cart,
  .checkout {
    .tcl-btn.tcl-btn--block.tcl-btn--fillred.checkoutbutton:hover,
    .black-button:hover,
    .tcl-btn.tcl-btn--sm.tcl-btn--fillblack:hover,
    .ajaxAddToCart.form-button.primary-button.addToCart:hover,
    #logon-form .red-button:hover,
    #myAccount.dashboard .item .addToCart:hover,
    #myAccount #personalInformation .custom-check .row .red-button:hover,
    #myAccount #personalInformation #LoginInfo .row .red-button:hover,
    #addressBookWrap .red-button.primary-button:hover,
    .tcl-btn.tcl-btn--block.tcl-btn--fillred.checkoutbutton:active,
    .black-button:active,
    .tcl-btn.tcl-btn--sm.tcl-btn--fillblack:active,
    .ajaxAddToCart.form-button.primary-button.addToCart:active,
    #logon-form .red-button:active,
    #myAccount.dashboard .item .addToCart:active,
    #myAccount #personalInformation .custom-check .row .red-button:active,
    #myAccount #personalInformation #LoginInfo .row .red-button:active,
    #addressBookWrap .red-button.primary-button:active {
      border: solid 1px $emperor;
      background: $emperor;
      color: $white;
    }

    #myAccount #personalInformation .custom-check .row .cancel,
    #myAccount #personalInformation #LoginInfo .row .cancel {
      box-sizing: border-box;
      max-height: 34px;
      border: solid 1px $emperor;
      background: $white;
      color: $emperor;
      box-shadow: none;
      @include primaryFont();
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: normal;
      letter-spacing: normal;
      -webkit-transition: all 0.6s;
      transition: all 0.6s;
      text-align: center;
      border-radius: 22px;
      padding-top: 9px !important;
      padding-bottom: 9px !important;
      text-shadow: none;
    }

    #myAccount #personalInformation .custom-check .row .cancel:hover,
    #myAccount #personalInformation #LoginInfo .row .cancel:hover,
    #myAccount #personalInformation .custom-check .row .cancel:active,
    #myAccount #personalInformation #LoginInfo .row .cancel:active {
      border: solid 1px $emperor;
      background: $emperor;
      color: $white;
    }

    .addToCart .addToCartText {
      line-height: inherit !important;
    }

    #myAccount .item .addToCart .icon {
      top: -5px !important;
    }

    #WC_CachedItemDisplay_div_3 .icon.redesignIcons-engraving {
      position: relative;
      top: -9px;
      left: 10px;
    }

    #pdp .cart-button .redesignIcons-bag {
      top: -4px !important;
    }
  }
}
@media screen and (max-width: 767px) {
  .shopping-cart,
  #cart-body,
  #checkout_cart,
  .page--shopping-cart,
  .checkout,
  .minicart,
  box {
    @include primaryFont();

    .accountPage #sign_in_button,
    .accountPage #register_button,
    .tcl-btn.tcl-btn--block.tcl-btn--fillred,
    .proceed-checkout-btn.tcl-btn.tcl-btn--block.tcl-btn--fillred,
    .tcl-btn.tcl-btn--block.tcl-btn--fillblack,
    .details .login-info .black_btn,
    .details .personal-info .black_btn,
    .details .address-info .black_btn,
    .red.button.addToCart,
    #place_order.red.button {
      box-sizing: border-box;
      max-height: 34px;
      border: solid 1px $mine-shaft;
      background: $mine-shaft;
      color: $white;
      box-shadow: none;
      @include primaryFont();
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: normal;
      letter-spacing: normal;
      -webkit-transition: all 0.6s;
      transition: all 0.6s;
      text-align: center;
      border-radius: 22px !important;
      padding-top: 9px !important;
      padding-bottom: 9px !important;
      text-shadow: none;
    }

    .accountPage #sign_in_button:hover,
    .accountPage #register_button:hover,
    .tcl-btn.tcl-btn--block.tcl-btn--fillred:hover,
    .proceed-checkout-btn.tcl-btn.tcl-btn--block.tcl-btn--fillred:hover,
    .tcl-btn.tcl-btn--block.tcl-btn--fillblack:hover,
    .details .login-info .black_btn:hover,
    .details .personal-info .black_btn:hover,
    .details .address-info .black_btn:hover,
    .red.button.addToCart:hover,
    #place_order.red.button:hover .accountPage #sign_in_button:active,
    .accountPage #register_button:active,
    .tcl-btn.tcl-btn--block.tcl-btn--fillred:active,
    .proceed-checkout-btn.tcl-btn.tcl-btn--block.tcl-btn--fillred:active,
    .tcl-btn.tcl-btn--block.tcl-btn--fillblack:active,
    .details .login-info .black_btn:active,
    .details .personal-info .black_btn:active,
    .details .address-info .black_btn:active,
    .red.button.addToCart:active,
    #place_order.red.button:active {
      border: solid 1px $emperor;
      background: $emperor;
      color: $white;
    }

    .tcl-btn.tcl-btn--image-only {
      background-color: transparent !important;
      border: none !important;
    }

    .continueButton .tcl-btn.tcl-btn--block.tcl-btn--fillblack {
      width: 90%;
      margin-left: auto;
      margin-right: auto;

      &.disabled {
        opacity: 0.6;
        pointer-events: none;
        cursor: default;
      }
    }

    .red.button.addToCart,
    #place_order.red.button {
      max-width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }

    .red.button.addToCart #cart_button_text,
    #place_order.red.button #cart_button_text {
      top: -12px !important;
    }

    a.addToCart:before {
      top: -9px;
    }

    .tcl-btn.tcl-btn--block.tcl-btn--outblack {
      @include primaryFont();
      font-size: 1.2rem;
      box-sizing: border-box;
      max-height: 34px;
      border: solid 1px $mine-shaft;
      box-shadow: none;
      line-height: 1.6rem;
      letter-spacing: normal;
      -webkit-transition: all 0.6s;
      transition: all 0.6s;
      text-align: center;
      border-radius: 22px !important;
      padding-top: 9px !important;
      padding-bottom: 9px !important;
      text-shadow: none;
    }

    .ff-lato-bold,
    .ff-brandon-bold,
    span.error strong,
    p.error strong {
      @include primaryFont();
    }
  }
}
