/* stylelint-disable no-descending-specificity */
.cms-text-module {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 20px;
  max-width: 93rem;
  box-sizing: border-box;
  width: 100%;

  &__pretitle,
  &__title,
  &__text {
    color: #555555;
    text-align: center;
    line-height: normal;
  }

  &__pretitle {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 400;
  }

  &__title {
    font-size: 3.2rem;
    font-weight: 300;
  }

  &__text {
    font-size: 1.6rem;
  }

  &__actions {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 2.4rem;
  }

  &__cta {
    display: inline-block;
    flex-basis: auto;
  }

  .cms-text-align-left & {
    justify-content: flex-start;

    &__pretitle,
    &__title,
    &__text {
      text-align: left;
    }

    &__actions {
      justify-content: flex-start;
    }
  }
  h2.cms-text-module__title {
    @screen mdr{
      line-height:3.8rem;
    }
  }
  .cms-text-align-center & {
    justify-content: center;

    &__pretitle,
    &__title,
    &__text {
      text-align: center;
    }

    &__actions {
      justify-content: center;
    }
  }

  .cms-text-align-right & {
    justify-content: flex-end;

    &__pretitle,
    &__title,
    &__text {
      text-align: right;
    }

    &__actions {
      justify-content: flex-end;
    }
  }

  .cms-text-position--top-left &,
  .cms-text-position--top-center &,
  .cms-text-position--top-right &,
  .cms-text-position--center-left &,
  .cms-text-position--center-center &,
  .cms-text-position--center-right &,
  .cms-text-position--bottom-left &,
  .cms-text-position--bottom-center &,
  .cms-text-position--bottom-right & {
    position: absolute;
  }

  .cms-text-position--top-left & {
    top: 0;
    left: 0;
    transform: none;
  }

  .cms-text-position--top-center & {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .cms-text-position--top-right & {
    top: 0;
    left: auto;
    right: 0;
    transform: none;
  }

  .cms-text-position--center-left & {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .cms-text-position--center-center & {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .cms-text-position--center-right & {
    top: 50%;
    left: auto;
    right: 0;
    transform: translateY(-50%);
  }

  .cms-text-position--bottom-left & {
    top: auto;
    bottom: 0;
    left: 0;
    transform: none;
  }

  .cms-text-position--bottom-center & {
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .cms-text-position--bottom-right & {
    top: auto;
    bottom: 0;
    left: auto;
    right: 0;
    transform: none;
  }
}
.cms-text-landscape {
  margin-bottom: 1.6rem;
  .cms-text-module {
    padding: 0;
    margin: 0;
    &__text {
      margin-top: 0.8rem;
    }
    &__actions {
      margin-top: 1.6rem;
    }
    &__wrapper {
      padding: 0 1.6rem;
      @screen sm {
        padding: 0 2.5rem;
      }
    }
    &__title {
      margin-top: 0.8rem;
    }
  }
}
.page-type-subscription {
  .loaded {
    .cms-fw-banner {
      margin: 0;
      padding-top: 0;
      padding-bottom: 4rem;
    }

    .cms-text-align-center {
      .cms-text-module {
        padding-top: 0;
        padding-bottom: 2.4rem;
      }
    }

    .earlyAccessLandingForm {
      margin: 0;
      padding-bottom: 2.4rem;
    }
  }
}

@media screen and (min-width: 768px) {
  .cms-text-module__cta.btn.cta-short {
    margin: 5px 1.5rem;
  }

  .cms-text-module__cta.btn.cta-short:last-child {
    margin-right: 0;
  }

  .cms-text-module__cta.btn.cta-short:first-child {
    margin-left: 0;
  }
}
