@import '../../utils/utils';
@import '../../variables';





sgh-full-width {
 &[image-size='s'],
 &[image-size='default']
  {
    .skeleton {
      &--image {
        height: auto;
        aspect-ratio: 39/25;

        @screen lg {
          aspect-ratio: 9/2;
        }
      }
    }
  }

  &[image-size='m'] {
    .skeleton {
      &--image {
        height: auto;
        aspect-ratio: 117/100;

        @screen lg {
          aspect-ratio: 72/23;
        }
      }
    }
  }

  &[image-size='l'] {
    .skeleton {
      &--image {
        height: auto;
        aspect-ratio: 47/50;

        @screen lg {
          aspect-ratio: 144/59
        }
      }
    }
  }

  &[text-align='left'] {
    .skeleton {
      &--text-module {
        align-items: flex-start;
      }
    }
  }

  &[text-align='right'] {
    .skeleton {
      &--text-module {
        align-items: flex-end;
      }
    }
  }

 
}



.skeleton {
  list-style: none;
  position: relative;

  &--image {
    background-color: $white-smoke;
    width: 100%;
    aspect-ratio: 10/3;

   
    @media (min-width: 1920px) {
      aspect-ratio: 9/2;
    }
  }
  .common__icon {
    &-skeleton{
      background-color: $white-smoke;
      border-radius: 50%;
      @include skeleton-animation;
    } 
   
  }
  
  &--text-module {
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 1.6rem 4rem;

    @screen lgr {
      padding: 1.6rem 1.6rem 2.4rem;
    }

    .skeleton--text {
      @include skeleton-animation;
      height: 1rem;
      width: 48rem;
      margin-bottom: 1.6rem;

      @screen lgr {
        width: 90%;
      }
    }

    .skeleton--h2 {
      @include skeleton-animation;
      height: 3.5rem;
      width: 40rem;
      margin-bottom: 1.6rem;

      @screen lgr {
        width: 20rem;
      }
    }

    .skeleton--button {
      @include skeleton-animation;
      height: 1.5rem;
      width: 13.4rem;
      margin: 0;
    }
  }

  &--buttons-video {
    list-style: none;
    display: flex;
    position: absolute;
    top: calc(100% - 14.3rem);
    transform: translateY(-100%);
    gap: 1.6rem;
    transition: linear 0.2;
    padding: 0;
    margin: 0;

    .skeleton--button-video {
      width: 4rem;
      height: 4rem;
      border-radius: 100%;
      @include skeleton-animation($dark: true);
    }

    &.left {
      left: 4rem;
    }

    &.right {
      right: 4rem;
    }

    @screen lgr {
      top: calc(100% - 15.3rem);

      &.left {
        left: 1.6rem;
      }

      &.right {
        right: 1.6rem;
      }
    }
  }
}



.full-width--text-skeleton {
  
  &.text-left{
   
    .skeleton--text{
      &-module{
        align-items: left;
        justify-content: left;
      }
    }
  }
  &.text-right{
    .skeleton--text{
      &-module{
        align-items: right;
        justify-content: right;
      }
    }
  }
  &.text-center{
    .skeleton--text{
      &-module{
        align-items: center;
        justify-content: center;
      }
    }
  }

  .full-width{
  &--text{
    &-title,
    &-subtitle,
    &-cta{
      @include skeleton-animation;
    }

  }
   
  }

  
}


