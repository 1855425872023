.sgh-title-page {
  padding: 19px 19px 19px 24px;
  border: solid 1px #f7f7f7;
}
h1 {
  color: #1b1b1b;
  font-size: 2.4rem;
  line-height: 2.7rem;
}
.container-custom {
  position: relative;
}
.container-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  max-width: 1160px;
  margin: 0 auto;
  &:after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 0.4px;
    background: #dadada;
    margin: 0px 10px;
    bottom: 0px;
  }
  .row {
    width: 100%;
    &:before {
      display: table;
      content: ' ';
    }
    &:after {
      display: table;
      content: ' ';
    }
  }
  h3 {
    font-size: 1.4rem;
    line-height: 1.2rem;
    @include primaryFont();
    font-weight: 500;
    padding-bottom: 25px;
    color: #555;
    margin-top: 40px;
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
    border-bottom: solid 0.4px #dadada;
    padding-bottom: 40px;
    li {
      flex: 0 0 20%;
      max-width: 20%;
      margin-bottom: 20px;
      padding: 5px;
      font-size: 1.2rem;
      @include primaryFont();
      font-weight: 300;
      line-height: 1.2rem;
    }
  }
  &:last-child {
    .flex {
      border-bottom: none;
    }
  }
}
@media only screen and (max-width: 480px) {
  .container-list {
    .flex {
      li {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}
