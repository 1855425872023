/* stylelint-disable */
@import '../mixins/mixins';

.modal-window {
  &__container {
    position: relative;
    width: 100%;
    max-width: 960px;
    padding: 24px 13px 0;
    background: $white;
    min-height: 238px;
    @screen mdr {
      height: 100%;
    }
    @screen lg {
      padding: 40px 50px 60px;
    }
  }

  &__content {
    @media all and (min-width: 768px) and (max-height: 767px) {
      overflow: hidden auto;
      max-height: 400px;
      padding-right: 20px;
      margin-right: -40px;
    }

    & > .mCustomScrollBox {
      @media all and (min-width: 768px) and (max-height: 767px) {
        padding-right: 20px;
      }
    }
  }

  &__close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    border: none;
    background: none;
    color: transparent;
    @screen lg {
      top: 21px;
      right: 25px;
      width: 24px;
      height: 24px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 1px;
      transform-origin: center;
      background-color: $black;
      @screen lg {
        width: 20px;
      }

      &:hover {
        background-color: $mine-shaft;
      }
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
